import React, {FC} from 'react';
import {useFormik} from 'formik';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import * as Yup from 'yup';
import {connect} from '../../../store/reducers/Binance/BinanceActionCreator';
import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';

interface ConnectBinanceModalProps {
  showModal: boolean;
  onHide: any;
}

const ConnectBinanceModal: FC<ConnectBinanceModalProps> = ({
                                                             onHide,
                                                             showModal,
                                                           }) => {
  const dispatch = useAppDispatch();
  const {
          stateError,
          isLoading,
        }        = useAppSelector(
    (state) => state.binanceReducer,
  );

  const VALIDATION_SCHEMA = Yup.object().shape({
    key   : Yup.string().required('Обязательно для заполнения'),
    secret: Yup.string().required('Обязательно для заполнения'),
  });

  const initialValues = {
    key   : '',
    secret: '',
  };

  const {
          values,
          handleChange,
          errors,
          touched,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : connectHadler,
  });

  function connectHadler() {
    dispatch(
      connect({
        key   : values.key,
        secret: values.secret,
      }),
    ).then((result: any) => {
      if (!result?.error?.message) {
        onHide();
      }
    });
  }

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавление Binance аккаунта
          {isLoading && <p className="input-muted">Загрузка...</p>}
          { !isLoading && stateError && (
            <div className="input-feedback">{stateError}</div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p className="warning">
              *Если вы уже подкючали Binance аккаунт к нашему сервису, то ваш
              текущий аккаунт будет заменен на новый.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          {errors.key && touched.key && (
            <div className="input-feedback">{errors.key}</div>
          )}
          <Col>
            <p className="me-3 my-1">Публичный ключ: </p>
          </Col>
          <Col>
            <InputGroup className="mb-9">
              <Form.Control
                aria-describedby="key"
                value={values.key}
                onChange={handleChange('key')}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          {errors.secret && touched.secret && (
            <div className="input-feedback">{errors.secret}</div>
          )}
          <Col>
            <p className="me-3 my-1">Секретный ключ: </p>
          </Col>
          <Col>
            <InputGroup className="me-9 my-1">
              <Form.Control
                aria-describedby="secret"
                value={values.secret}
                onChange={handleChange('secret')}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col className="mb-2">
          <Button disabled={isLoading} onClick={connectHadler}>
            Подключить
          </Button>
        </Col>
        <Col>
        </Col>
        <Col className="mb-2 float-end">
          <Button className="float-end" variant="secondary" onClick={onHide}>
            Закрыть
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectBinanceModal;
