import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import * as Yup from "yup";

import { Modal, Button, Row, Col, InputGroup, Form } from "react-bootstrap";
import {
  connect,
  sendCode,
} from "../../../store/reducers/Telegram/TelegramActionCreator";

import './ConnectTelegramModal.css';

interface ConnectTelegramModalProps {
  showModal: boolean;
  onHide: any;
}

const ConnectTelegramModal: FC<ConnectTelegramModalProps> = ({
  onHide,
  showModal,
}) => {
  const dispatch = useAppDispatch();
  const { isConnection, telegramError, isLoading } = useAppSelector(
    (state) => state.telegramReducer
  );

  let [sendCodeFinished, setSendCodeFinished] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && telegramError === '') {
      onHide();
    }
  }, [isLoading, onHide, telegramError, sendCodeFinished]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const VALIDATION_SCHEMA = Yup.object().shape({
    apiId: Yup.string().required("Обязательно для заполнения"),
    apiHash: Yup.string().required("Обязательно для заполнения"),
    phoneNumber: Yup.string()
      .required("Обязательно для заполнения")
      .matches(phoneRegExp, "Номер телефона неверный"),
  });

  const initialValues = {
    apiId: "",
    apiHash: "",
    phoneNumber: "",
    phoneCode: "",
  };

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    setFieldError
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: connectHadler,
  });

  function connectHadler() {
    if (values.phoneNumber.includes(' ')) {
      setFieldError('phoneNumber', 'Номер телефона должен быть без пробелов');
    }

    dispatch(
      connect({
        apiId: values.apiId,
        apiHash: values.apiHash,
        phoneNumber: values.phoneNumber,
      })
    );
  }

  function sendAuthCodeHandler() {
    setSendCodeFinished(false);

    dispatch(
      sendCode({
        apiId: values.apiId,
        apiHash: values.apiHash,
        phoneNumber: values.phoneNumber,
        authCode: values.phoneCode,
      })
    ).then(() => {
      setSendCodeFinished(true);
    });
  }

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавление Телеграм аккаунта
          {isLoading && <p className="input-muted">Загрузка...</p>}
          {!isLoading && telegramError && (
            <div className="input-feedback">{telegramError}</div>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <p>Для подключения Telegram аккаунта: </p>

            <ul>
              <li><a className="telegram-link" href="https://my.telegram.org/auth">Войдите в ваш Телеграм аккаунт</a> с помощью номера телефона.</li>
              <li>Нажмите на "API development tools".</li>
              <li>У вас появится окно создания приложения. Вам необходимо заполнить только поля: App title, Short name.</li>
              <li>В конце нажимаете "Create application". Далее необходимую информацию вставляете в поля ниже.</li>
            </ul>

            <p className="warning">*Если вы уже подкючали Telegram аккаунт к нашему сервису, то ваш текущий аккаунт будет заменен на новый.</p>
          </Col>
        </Row>

        <hr />

        <Row>
          {errors.apiId && touched.apiId && (
            <div className="input-feedback">{errors.apiId}</div>
          )}

          <Col>
            <p className="me-4 my-1">API Id: </p>
          </Col>

          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="api-id"
                value={values.apiId}
                onChange={handleChange("apiId")}
              />
            </InputGroup>
          </Col>

          <Col></Col>
        </Row>

        <Row>
          {errors.apiHash && touched.apiHash && (
            <div className="input-feedback">{errors.apiHash}</div>
          )}

          <Col>
            <p className="me-4 my-1">API Hash: </p>
          </Col>

          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="api-hash"
                value={values.apiHash}
                onChange={handleChange("apiHash")}
              />
            </InputGroup>
          </Col>

          <Col></Col>
        </Row>

        <Row>
          {errors.phoneNumber && touched.phoneNumber && (
            <div className="input-feedback">{errors.phoneNumber}</div>
          )}

          <Col>
            <p className="me-4 my-1">Номер телефона: </p>
          </Col>

          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text id="phone-number">+</InputGroup.Text>
              <Form.Control
                aria-describedby="phone-number"
                value={values.phoneNumber}
                onChange={handleChange("phoneNumber")}
              />
            </InputGroup>
          </Col>

          <Col>
            <Button onClick={() => handleSubmit()}>Начать подключение</Button>
          </Col>
        </Row>

        <Row>
          {isConnection && (
            <div className="input-success">
              Проверьте телеграм для получения кода
            </div>
          )}

          <Col>
            <p className="me-4 my-1">Код: </p>
          </Col>

          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                disabled={!isConnection}
                aria-describedby="telegram-auth-code"
                value={values.phoneCode}
                onChange={handleChange("phoneCode")}
              />
            </InputGroup>
          </Col>

          <Col>
            <Button disabled={!isConnection} onClick={sendAuthCodeHandler}>
              Отправить код
            </Button>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectTelegramModal;
