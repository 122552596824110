import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {Link} from 'react-router-dom';
import {logout} from '../../../store/reducers/User/UserActionCreator';
import {setMode} from '../../../store/reducers/Binance/BinanceActionCreator';
import {Button, Col, Nav, Navbar} from 'react-bootstrap';

import './AuthMenu.css';

const AuthMenu: FC = () => {

  const dispatch              = useAppDispatch();
  const {user}                = useAppSelector((state) => state.userReducer);
  let {dashboard}             = useAppSelector((state) => state.binanceReducer);
  let [modeShow, setModeShow] = useState<any>(false);
  let [readOnly, setReadOnly] = useState<any>();
  let [testMode, setTestMode] = useState<any>();

  document.addEventListener('keyup', function (event) {
    if (+event.keyCode === 115) {
      setModeShow( !modeShow);
    }
    if (+event.keyCode === 27) {
      setModeShow(false);
    }
  });

  const onSetMode = () => {
    dispatch(setMode()).then(r => {
      let data = r?.payload?.data || r;
      if (data?._id) {
        setReadOnly(data.readOnly);
      }
    });
  };

  useEffect(() => {

    setTestMode(document.location?.href?.indexOf('test.') > -1 || document.location?.href?.indexOf('localhost:') > -1 ? 1 : 0);

    if (dashboard['user']) {
      setReadOnly(dashboard['user']['readOnly']);
    }

  }, [dispatch, dashboard]);

  return (
    <Navbar
      className="dashboard_menu"
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      title={'VERSION API: ' +dashboard?.version_api}
    >
      <Navbar.Brand className="brand">
        <Link to="" hidden={testMode === 1} className="testmode">CMATIC.PRO</Link>
        <Link to="" hidden={testMode === 0} className="testmode">TEST.CMATIC.PRO</Link>
        <Button hidden={modeShow === false || !dashboard?.user} onClick={onSetMode}
                variant={readOnly === true ? 'danger' : 'success'}
                title=""
                size="sm"
                className={'p-1 mx-3 m-1'}
        >
          <span>{readOnly === true ? 'READ ONLY' : 'NORMAL'}</span>
        </Button>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-end"
        id="responsive-navbar-nav"
      >
        <span>{document.location?.href?.indexOf('test.') > -1 || document.location?.href?.indexOf('localhost:') > -1}</span>
        <Nav>
          <Nav.Item>
            <Link to="">Главная</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="dashboard">Dashboard</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="telegram">Telegram торговля</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/statistics/today">Статистика</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="connect">Подключение API</Link>
          </Nav.Item>
          <Nav.Item>
            <Col className="menu-info">
              <p className="info-email">{user.email}</p>
            </Col>
          </Nav.Item>
          <Nav.Item>
            <Button
              onClick={() => dispatch(logout())}
              className="logout-btn"
              variant="secondary"
            >
              Выйти
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
)
};

export default AuthMenu;
