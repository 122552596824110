
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import userReducer from './reducers/User/UserSlice';
import telegramReducer from './reducers/Telegram/TelegramSlice';
import binanceReducer from './reducers/Binance/BinanceSlice';
import bybitReducer from './reducers/Bybit/BybitSlice';

const rootReducer = combineReducers({
    userReducer,
    telegramReducer,
    binanceReducer,
    bybitReducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];