import React, { FC } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";

interface TelegramSettingsProps {
  values: any; //formik values
  titleOnChange: any;
  signalWordsLongOnChange: any;
  signalWordsShortOnChange: any;
  closeWordsOnChange: any;
  workingTypeOnChange: any;
  errors: any;
  touched: any;
  editingMode?: boolean;
}

const TelegramSettings: FC<TelegramSettingsProps> = ({
  values,
  titleOnChange,
  signalWordsLongOnChange,
  signalWordsShortOnChange,
  closeWordsOnChange,
  workingTypeOnChange,
  errors,
  touched,
  editingMode,
}) => {
  return (
    <div className="telegram-settigns">
      <Row>
        <Col>
          <h4>Настройка Telegram</h4>
          <p>Добавьте канал для торговли по его сигналам на бирже Binance</p>
          <p>
            Робот ищет сигнальные слова в сообщении канала для
            покупки/продажи(Например, LONG ✳️, SHORT 🛑). Монету определяет сам
            исходя из представленного списка на Binance Futures.
          </p>
          <p>
            Для перечисления нескольких вариантов сигнальных слов используйте ;
            без пробелов
          </p>
        </Col>
      </Row>

      <hr className="hr-double" />

      <Row>
        {errors.telegramChannelTitle && (
          <div className="input-feedback">{errors.telegramChannelTitle}</div>
        )}

        <Col>
          <p>Название канала: </p>
        </Col>

        <Col>
          <Row>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="telegramChannelTitle"
                value={values.telegramChannelTitle}
                onChange={titleOnChange}
                disabled={editingMode ? editingMode : false}
              />
            </InputGroup>
          </Row>
        </Col>
      </Row>

      <Row>
        {errors.signalWordsLong && (
          <div className="input-feedback">{errors.signalWordsLong}</div>
        )}

        <Col>
          <p>Тип цены: </p>
        </Col>

        <Col>
          <Row>
            <InputGroup className="mb-3">
              <Form.Select onChange={workingTypeOnChange} value={values?.telegramSettings?.workingType || 'MARK_PRICE'}>
                <option>MARK_PRICE</option>
                <option>CONTRACT_PRICE</option>
              </Form.Select>
            </InputGroup>
          </Row>
        </Col>
      </Row>

      <Row>
        {errors.signalWordsLong && (
          <div className="input-feedback">{errors.signalWordsLong}</div>
        )}

        <Col>
          <p>Сигнальные слова лонг: </p>
        </Col>

        <Col>
          <Row>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="signalWordsLong"
                value={values.signalWordsLong}
                onChange={signalWordsLongOnChange}
              />
            </InputGroup>
          </Row>
        </Col>
      </Row>

      <Row>
        {errors.signalWordsShort && (
          <div className="input-feedback">{errors.signalWordsShort}</div>
        )}

        <Col>
          <p>Сигнальные слова шорт: </p>
        </Col>

        <Col>
          <Row>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="signalWordsShort"
                value={values.signalWordsShort}
                onChange={signalWordsShortOnChange}
              />
            </InputGroup>
          </Row>
        </Col>
      </Row>

      <Row>
        {errors.closeWords && (
          <div className="input-feedback">{errors.closeWords}</div>
        )}

        <Col>
          <p>Сигнальные слова закрытия позиции: </p>
        </Col>

        <Col>
          <Row>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="closeWords"
                value={values.closeWords}
                onChange={closeWordsOnChange}
              />
            </InputGroup>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TelegramSettings;
