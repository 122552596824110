import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {ITelegramAccount} from '../../../types/ITelegramAccount';
import {IChannel} from '../../../types/IChannel';
import {RejectedWithValueAction} from '../../../types/responses/RejectedWithValueAction';
import {TelegramConnectionResponse} from '../../../types/responses/TelegramConnectionResponse';

import {
  addChannel,
  connect,
  deleteChannel,
  editChannel,
  getAccount,
  getChannels,
  getTradeHistory,
  sendCode,
} from './TelegramActionCreator';

interface TelegramState {
  telegramAccount: ITelegramAccount;
  tradeHistory: any[];
  channels: IChannel[];
  isLoading: boolean;
  isConnection: boolean;
  telegramError: string;
  editChannel: any;
}

const initialState: TelegramState = {
  telegramAccount: {} as ITelegramAccount,
  channels       : [] as IChannel[],
  tradeHistory   : [],
  isLoading      : false,
  isConnection   : false,
  telegramError  : '',
  editChannel    : {
    status          : '',
    telegramSettings: {
      channelName     : '',
      signalWordsLong : '',
      signalWordsShort: '',
      closeWords      : '',
      workingType     : 'MARK_PRICE',
    },
    binanceSettings : {
      leverage    : 1,
      leverageSpot: 1,
      hedgeSpot   : false,
      whiteList   : '',
      blockList   : '',
      positionSize: 10,
      fundingMin  : -5,
      fundingMax  : 5,
      testMaxQty  : null,
      tps         : [
        {
          amount   : 100,
          breakeven: false,
          offset   : 1,
        },
      ],
      sl          : {
        type  : 'USUAL',
        amount: 100,
        offset: 1,
      },
    },
    bybitSettings   : {
      leverage    : 1,
      leverageSpot: 1,
      hedgeSpot   : false,
      whiteList   : '',
      blockList   : '',
      positionSize: 10,
      fundingMin  : -5,
      fundingMax  : 5,
      testMaxQty  : null,
      tps         : [
        {
          amount   : 100,
          breakeven: false,
          offset   : 1,
        },
      ],
      sl          : {
        type  : 'USUAL',
        amount: 100,
        offset: 1,
      },
    },
  },
};

export const telegramSlice = createSlice({
  name         : 'telegram',
  initialState,
  reducers     : {
    setEditingChannel(state, action: PayloadAction<any>) {
      state.editChannel = action.payload;
    },
  },
  extraReducers: {
    //Telegram connection 1 stage - sending data for getting auth code
    [connect.fulfilled.type]: (
      state,
      action: PayloadAction<{ status: string }>,
    ) => {
      state.isConnection  = true;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [connect.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [connect.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isConnection  = false;
      state.isLoading     = false;
    },

    //Telegram connection 2 stage - sending auth code for finishing connection
    [sendCode.fulfilled.type]: (
      state,
      action: PayloadAction<TelegramConnectionResponse>,
    ) => {
      state.telegramAccount = action.payload;
      state.isConnection    = false;
      state.telegramError   = '';
      state.isLoading       = false;
    },
    [sendCode.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [sendCode.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isConnection  = false;
      state.isLoading     = false;
    },

    //Getting connected telegram account
    [getAccount.fulfilled.type]: (
      state,
      action: PayloadAction<ITelegramAccount>,
    ) => {
      state.telegramAccount = action.payload;
      state.telegramError   = '';
      state.isLoading       = false;
    },
    [getAccount.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getAccount.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },

    //Get channels
    [getChannels.fulfilled.type]: (
      state,
      action: PayloadAction<IChannel[]>,
    ) => {
      state.channels      = action.payload;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [getChannels.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getChannels.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },

    //Add channel
    [addChannel.fulfilled.type]: (state, action: PayloadAction<IChannel[]>) => {
      state.channels      = action.payload;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [addChannel.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [addChannel.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },

    //Delete channel
    [deleteChannel.fulfilled.type]: (
      state,
      action: PayloadAction<IChannel[]>,
    ) => {
      state.channels      = action.payload;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [deleteChannel.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [deleteChannel.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },

    //Edit channel
    [editChannel.fulfilled.type]: (
      state,
      action: PayloadAction<IChannel[]>,
    ) => {
      state.channels      = action.payload;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [editChannel.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [editChannel.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },

    //Trade history getTradeHistory
    [getTradeHistory.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.tradeHistory  = action.payload;
      state.telegramError = '';
      state.isLoading     = false;
    },
    [getTradeHistory.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getTradeHistory.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.telegramError = action.payload;
      state.isLoading     = false;
    },
  },
});

export const {setEditingChannel} = telegramSlice.actions;

export default telegramSlice.reducer;
