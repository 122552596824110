import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import DashboardTotals from '../modules/components/Dashboard/DashboardTotals/DashboardTotals';
import DashboardOrders from '../modules/components/Dashboard/DashboardOrders/DashboardOrders';
import {useAppDispatch, useAppSelector} from '../hooks/redux';
import {getDashboard} from '../store/reducers/Binance/BinanceActionCreator';

const Dashboard = () => {

  const dispatch  = useAppDispatch();
  let {dashboard} = useAppSelector((state) => state.binanceReducer);

  useEffect(() => {

    //dashboard = {success: true};
    const getData = setTimeout(() => {
      try {
        dispatch(getDashboard(0)).then(r => {
          return r;
        });
      } catch (e) {
        console.error(e);
      }
    }, 7000);

    return () => {
      clearTimeout(getData);
    };

  }, [dispatch, dashboard]);

  return (
    <Container className="" fluid>
      <DashboardTotals />
      <DashboardOrders />
    </Container>
  );
};

export default Dashboard;
