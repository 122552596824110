import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IExchangeOrderNew} from '../../../types/IExchangeOrder';
import {RejectedWithValueAction} from '../../../types/responses/RejectedWithValueAction';
import {IExchangeAccount} from '../../../types/IExchangeAccount';
import {connect, getAccount, getDashboard, getTemplate, getSymbols, createOrder} from './BybitActionCreator';

interface BybitState {
  bybitAccount: IExchangeAccount;
  bybitOrder: IExchangeOrderNew;
  isLoading: boolean;
  stateError: string;
  dashboard: any;
  symbols: any;
  data?: any;
  template?: any;
}

const initialState: BybitState = {
  bybitAccount: {} as IExchangeAccount,
  bybitOrder  : {} as IExchangeOrderNew,
  isLoading     : false,
  stateError  : '',
  dashboard     : {},
  data          : {},
  symbols       : [],
  template      : {},
};

export const bybitSlice = createSlice({
  name         : 'bybit',
  initialState,
  reducers     : {},
  extraReducers: {
    //Bybit account connect
    [connect.fulfilled.type]: (state, action: PayloadAction<IExchangeAccount>) => {
      state.bybitAccount = action.payload;
      state.stateError   = '';
      state.isLoading      = false;
    },
    [connect.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [connect.rejected.type] : (state, action: RejectedWithValueAction<string>) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

    //Get Bybit account
    [getAccount.fulfilled.type]: (state, action: PayloadAction<IExchangeAccount>) => {
      state.bybitAccount = action.payload;
      state.stateError   = '';
      state.isLoading      = false;
    },
    [getAccount.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getAccount.rejected.type] : (state, action: RejectedWithValueAction<string>) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

    //Create order
    [createOrder.fulfilled.type]: (
      state,
      action: PayloadAction<IExchangeOrderNew>,
    ) => {
      state.stateError = '';
      state.isLoading    = false;
    },
    [createOrder.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [createOrder.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

    [getSymbols.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.symbols      = action.payload;
      state.stateError = '';
      state.isLoading    = false;
    },
    [getSymbols.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getSymbols.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

    [getDashboard.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.dashboard    = action.payload;
      state.stateError = '';
      state.isLoading    = false;
    },
    [getDashboard.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getDashboard.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

    [getTemplate.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.data         = action.payload;
      state.stateError = '';
      state.isLoading    = false;
    },
    [getTemplate.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getTemplate.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading    = false;
    },

  },
});

export default bybitSlice.reducer;