import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Modal, Button, Col, Container, Form, InputGroup, Row, Table, Tab, Tabs} from 'react-bootstrap';
import * as Yup from 'yup';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {
  closeOrder,
  savePosition,
  getDashboard,
  closeGroup,
  closeCurrentOrders,
} from '../../../../store/reducers/Binance/BinanceActionCreator';
import DashboardNotificationsTab from './DashboardNotificationsTab';
import DashboardAutoTab from './DashboardAutoTab';
import DashboardAutoParamsTab from './DashboardAutoParamsTab';
import DashboardInfoTab from './DashboardInfoTab';
import CreateOrderModal from '../../../../components/modals/CreateOrderModal/CreateOrderModal';
import './DashboardOrders.css';

const DashboardOrders = () => {

  const dispatch = useAppDispatch();

  let {dashboard}                               = useAppSelector((state) => state.binanceReducer);
  // const [showCreateOrder, setShowCreateOrder] = useState<boolean>(false);
  let [orderNew, setOrderNew]                   = useState<any>(undefined);
  let [orderCurrent, setOrderCurrent]           = useState<any>(undefined);
  let [groupCurrent, setGroupCurrent]           = useState<any>(undefined);
  let [positionEdit, setPositionEdit]           = useState<any>(undefined);
  let [currentTab, setCurrentTab]               = useState<any>('_futures');
  // let [autoValues, setAutoValues]               = useState<any>(undefined);
  let [currentPositionId, setCurrentPositionId] = useState<any>(undefined);
  let [disabled, setDisabled]                   = useState<boolean>(false);

  const VALIDATION_SCHEMA = Yup.object().shape({});

  const initialValues: any = {
    data: {},
    auto: {},
  };

  const initPositionEdit = (item: any) => {

    let result = JSON.parse(JSON.stringify(item));

    values['data'] = {
      'newGroup': {
        'newOrderType': '',
        'priceNew'    : '', //item?.price,
      },
    };


    result.groups?.forEach((g: any) => {

      values['data'][g._id] = {};

      let foundStop = false, tpQuantity = Math.abs(+g.quantity);

      g.orders.forEach((o: any) => {
        o['isNew'] = 0;
        if ( !foundStop && o.type === 'STOP_MARKET' && o.status === 'NEW' && o.orderId) {
          foundStop = true;
        }
        if (o.type === 'TAKE_PROFIT_MARKET' && ((o.status === 'NEW' && o.orderId) || o.status === 'WAITING')) {
          tpQuantity -= Math.abs(+o.quantity);
        }
      });

      if ( !foundStop) {
        let _o                      = {
          isNew            : -1,
          id               : -1,
          _id              : '-1',
          positionId       : g.positionId,
          groupId          : g._id,
          mainOrder        : g._id,
          mainClientOrderId: g._id,
          symbol           : g.symbol,
          side             : g.side,
          positionSide     : g.positionSide,
          derivative       : g.derivative,
          status           : 'CREATE',
          type             : 'STOP_MARKET',
          typeNew          : '+ Создать новый SL',
          quantity         : Math.abs(g.quantity),
        };
        values['data'][g._id]['-1'] = _o;
        g.orders.push(_o);
      }

      if (tpQuantity > 0) {
        let _o = {
          isNew            : 1,
          id               : 1,
          _id              : '1',
          positionId       : g.positionId,
          groupId          : g._id,
          mainOrder        : g._id,
          mainClientOrderId: g._id,
          symbol           : g.symbol,
          side             : g.side,
          positionSide     : g.positionSide,
          derivative       : g.derivative,
          status           : 'CREATE',
          type             : g.derivative === 'FUTURES' ? 'TAKE_PROFIT_MARKET' : 'OCO',
          typeNew          : '+ Создать новый TP',
          quantity         : tpQuantity,
        };
        g.orders.push(_o);
        values['data'][g._id]['1'] = _o;
      }

      g.orders = g.orders?.sort((_o1: any, _o2: any) => item.positionSide === 'SHORT' ? _o1?.isNew - _o2?.isNew : _o2?.isNew - _o1?.isNew);

    });

    if (item?.derivative === 'FUTURES' && !item?.openOrders) {
      values['data']['newGroup']['quantity'] = item.positionAmt;
      values['data']['newGroup']['profit']   = +Number((1 + (item?.positionSide === 'LONG' ? 0.05 : -0.05)) * +item.markPrice).toFixed(5);
      values['data']['newGroup']['sl']       = +Number((1 + (item?.positionSide === 'LONG' ? -0.05 : 0.05)) * +item.markPrice).toFixed(5);
    }

    setValues(values);

    console.info('values', values);
    console.info('result', result);

    return result;
  };

  const groupCloseCancel = () => {
    setGroupCurrent(undefined);
    setDisabled(false);
  };

  const groupCloseStart = (item: any) => {
    setDisabled(true);
    setGroupCurrent(undefined);
    dispatch(closeGroup(item)).then(r => {
      setDisabled(false);
      return dispatch(getDashboard(1));
    });
  };

  // const doStartOrder = (item: any) => {
  //   setDisabled(true);
  //   dispatch(startOrder(item)).then(r => {
  //     setDisabled(false);
  //   });
  // };

  const orderCloseCancel = () => {
    setOrderCurrent(undefined);
    setDisabled(false);
  };

  const orderClose = (item: any) => {
    setDisabled(true);
    dispatch(closeOrder(item)).then(r => {
      setOrderCurrent(undefined);
      setDisabled(false);
      return dispatch(getDashboard(1));
    });
  };

  const doCloseCurrentOrders = (item: any) => {
    if (window.confirm('Cancel all current orders for ' + item.symbol + ' (without closing position)') === true) {
      dispatch(closeCurrentOrders(item));
    }
  };

  // const orderCreate = (item: any) => {
  //   setDisabled(true);
  //   dispatch(createOrder(item)).then(r => {
  //     setOrderNew(undefined);
  //     setDisabled(false);
  //     return dispatch(getDashboard(1));
  //   });
  // };

  const onSavePosition = (values: any, position: any) => {
    setDisabled(true);
    values['position'] = {
      _id         : position._id,
      positionId  : position._id,
      positionSide: position.positionSide,
      symbol      : position.symbol,
      derivative  : position.derivative,
    };
    if (values['newGroup'] && values?.newGroup?.newOrderType === 'MARKET') {
      values['newGroup']['priceNew'] = undefined;
    }
    dispatch(savePosition(values)).then(r => {
      setPositionEdit(undefined);
      setDisabled(false);
      dispatch(getDashboard(1)).then(r => {
        setValues({});
      });
    });
  };

  const {
          values,
          // handleSubmit,
          handleChange,
          setFieldValue,
          // errors,
          // touched,
          setValues,
          // setFieldError,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : onSavePosition,
  });

  const fin = (x: any, defaultValue: any = 0, digits: any = 4, currency: string = '') => {
    let r = +x ? +Number.parseFloat(x).toFixed(digits) : (defaultValue ? +Number.parseFloat(defaultValue).toFixed(digits) : '');
    if (+r && currency) {
      r = r + ' ' + currency;
    }
    return r;
  };

  // const dd = (p = -1) => {
  //   return new Date(+new Date() - Math.random() * 15000000 * (!p ? 1 : -1));
  // };

  useEffect(() => {

    // if (dashboard?.AUTO && dashboard?.AUTO?.data) {
    //   for (const o of dashboard?.AUTO?.data) {
    //     if ( !values?.auto[o.symbol]) {
    //       setFieldValue('auto[' + o.symbol + '].enable', o?.enable);
    //       setFieldValue('auto[' + o.symbol + '].isMarkPrice', o?.isMarkPrice);
    //       setFieldValue('auto[' + o.symbol + '].sl', o?.sl);
    //       setFieldValue('auto[' + o.symbol + '].tp', o?.tp);
    //       setFieldValue('auto[' + o.symbol + '].pricePercent', o?.pricePercent);
    //       setFieldValue('auto[' + o.symbol + '].leverage', o?.leverage);
    //     }
    //   }
    // }

  }, [dispatch, dashboard, disabled, values, setFieldValue, setValues]);

  return (

    <Container hidden={ !dashboard?.success}
               className={'bg-light rounded-2 p-2 m-2'}
               fluid>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <Table striped bordered hover>*/}
      {/*      <thead>*/}
      {/*      <tr>*/}
      {/*        <th>Торговая пара</th>*/}
      {/*        <th>Текущее направление</th>*/}
      {/*        <th>Начало</th>*/}
      {/*        <th>Прогнозируемое окончание</th>*/}
      {/*        <th>Уровень по теням</th>*/}
      {/*        <th>Уровень по объёмам</th>*/}
      {/*      </tr>*/}
      {/*      </thead>*/}
      {/*      <tr>*/}
      {/*        <td>*/}
      {/*          /!*{JSON.stringify(dashboard?.symbols)}*!/*/}
      {/*          /!*{dashboard?.symbols}*!/*/}
      {/*        </td>*/}
      {/*      </tr>*/}
      {/*      /!*{dashboard.symbols.map((s:any) => {*!/*/}
      {/*      /!*  <tr>*!/*/}
      {/*      /!*    <td>{s}</td>*!/*/}
      {/*      /!*  </tr>*!/*/}
      {/*      /!*})}*!/*/}
      {/*      <tr className="long">*/}
      {/*        <td>BTCUSDT</td>*/}
      {/*        <td>LONG</td>*/}
      {/*        <th>{dd().toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{dd(+1).toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{35000 + Math.random()*5000}</th>*/}
      {/*        <th>{35000 + Math.random()*5000}</th>*/}
      {/*      </tr>*/}
      {/*      <tr className="short">*/}
      {/*        <td>ETHUSDT</td>*/}
      {/*        <td>SHORT</td>*/}
      {/*        <th>{dd().toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{dd(+1).toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{2000 + Math.random() * 1000}</th>*/}
      {/*        <th>{2000 + Math.random() * 1000}</th>*/}
      {/*      </tr>*/}
      {/*      <tr className="long">*/}
      {/*        <td>BNBUSDT</td>*/}
      {/*        <td>LONG</td>*/}
      {/*        <th>{dd().toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{dd(+1).toLocaleDateString()} {dd().toLocaleTimeString()}</th>*/}
      {/*        <th>{300 + Math.random() * 100}</th>*/}
      {/*        <th>{300 + Math.random() * 100}</th>*/}
      {/*      </tr>*/}
      {/*    </Table>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col>
          <Tabs id="tabs" defaultActiveKey="_futures" justify variant="pills" onSelect={setCurrentTab}>
            <Tab title="FUTURES" id="_futures" eventKey="_futures">
              <div hidden={currentTab !== '_futures'}>
                <Row>
                  <Col lg={3}>
                    <Button onClick={() => {
                      setOrderNew({
                        exchange  : 'BINANCE',
                        derivative: 'FUTURES',
                        hedgeSpot : false,
                      });

                    }}
                            variant="success"
                            title="Открыть ордер FUTURES"
                            size="sm"
                            className={'btn-left p-2 mx-3 m-2'}
                            disabled={disabled}
                    >
                      <div className="btn-symbol" dangerouslySetInnerHTML={{__html: '&oplus;'}}></div>
                      <span>Открыть ордер FUTURES</span>
                    </Button>
                  </Col>
                  <Col lg={6}>
                  </Col>
                  <Col lg={3}>
                    <Button
                      onClick={() => {
                        setOrderCurrent({
                          all       : true,
                          derivative: 'FUTURES',
                        });
                      }}
                      variant="danger"
                      title="Закрыть всё позиции FUTURES"
                      size="sm"
                      className={dashboard?.positions?.length || dashboard?.groups?.length || dashboard?.openOrders?.length ? 'btn-right p-2 m-2 mx-3' : 'hidden'}
                      disabled={disabled}
                    >
                      <div className="btn-symbol" dangerouslySetInnerHTML={{__html: '&otimes;'}}></div>
                      Закрыть все позиции FUTURES
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped bordered hover className={dashboard?.positions?.length ? '' : 'hidden'}>
                      <thead>
                      <tr>
                        <th colSpan={14} className="text-center text-bg-primary opacity-50 text-bg-dark">Позиции</th>
                      </tr>
                      <tr>
                        <th>#</th>
                        {/*<th>Дата</th>*/}
                        <th>Торговая пара <sup>(плечо)</sup></th>
                        <th>Направление</th>
                        {/*<th>Кредитное плечо</th>*/}
                        <th>Размер позиции</th>
                        {/*<th>Стоимость сделки</th>*/}
                        <th>Маржа</th>
                        <th>Безубыточная цена</th>
                        {/*<th>Цена средняя</th>*/}
                        <th>Цена входа</th>
                        <th>Цена маркировки</th>
                        <th>Interest Rate, %</th>
                        <th>Ставка (Last Funding Rate), %</th>
                        <th>PNL (ROE, %)</th>
                        <th colSpan={2}>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {[...dashboard?.positions || []].map((item, index) => {

                        let hiddenIfDataUnknown = +item?.pnl !== 0 || +item?.unrealizedProfit !== 0 || +item?.positionAmt !== 0 ? '' : 'hidden';
                        let showIfDataUnknown   = hiddenIfDataUnknown ? '' : 'hidden';

                        return (
                          <>
                            <tr key={index}>
                              <td title={item?._id || '?'}>{index + 1}</td>
                              {/*<td>{new Date(item.datetime).toLocaleDateString()} {new Date(item.datetime).toLocaleTimeString()}</td>*/}
                              <td className={item.auto ? 'td-auto' : ''}>
                                {item.symbol} <sup className={hiddenIfDataUnknown}>({item.leverage})</sup>
                                <sub hidden={ !item.hedgeSpot}>[hedge]</sub>
                                <span hidden={ !item.auto} className="span-auto float-end p-1">AUTO</span>
                              </td>
                              <td className={item?.positionSide === 'LONG' ? 'c-long' : 'c-short'}>{item.positionSide}</td>
                              {/*<td>{item.leverage}</td>*/}
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {fin(Math.abs(item?.positionAmt || item?.quantity))} {item.positionAsset}
                                  &nbsp;
                                  {'(' + fin(Math.abs(item.notional || item?.quantity * item?.entryPrice)) + ' ' + item.notionalAsset + (item?.notionalAsset !== 'USDT' ? ' / $' + fin(item?.notionalUSDT) : '') + ')'}
                                </span>
                              </td>
                              {/*<td>{item.notional +' USDT'}</td>*/}
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  <span hidden={ !item?.notional}>
                                    {fin(item.isolatedWallet, 0, 4)} {item.notionalAsset} {item.marginType ? '(' + item.marginType + ')' : ''}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {item.breakEvenPrice}
                                </span>
                              </td>
                              {/*<td>{item.avgPrice}</td>*/}
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {item.entryPrice}
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {item.markPrice}
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown} hidden={ !(+item?.unRealizedProfit)}>
                                  {item?.rate?.interestRate} %
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown + ' funding-rate'} hidden={ !(+item?.unRealizedProfit)}>
                                  {item?.rate?.lastFundingRate} %
                                </span>
                              </td>
                              <td className={item?.notional ? (+item?.unRealizedProfit < 0 ? 'pnl-minus' : 'pnl-plus') : ''}>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown} hidden={ !(+item?.unRealizedProfit)}>
                                  {fin(item?.unRealizedProfit, '0', 5)} {item.notionalAsset}
                                  {/*{fin(item?.pnl, '0', 3)} */}
                                  {item.roi ? ' (' + fin(item.roi, '', 2) + '%)' : ''}
                                </span>
                                <span hidden={item.notional}>
                                  WAITING
                                </span>
                              </td>
                              <td className="width-100">
                                <Button onClick={() => {
                                  return setPositionEdit(initPositionEdit(item));
                                }}
                                        variant="outline-primary"
                                        title="Редактировать позицию"
                                        size="sm"
                                        className={'px-2 py-0 btn-center ' + hiddenIfDataUnknown}
                                        hidden={item?._id === undefined}
                                >
                                  Edit
                                </Button>
                              </td>
                              <td className="width-100">
                                <Button onClick={() => setOrderCurrent({...item, ...{derivative: 'FUTURES'}})}
                                        variant="outline-danger"
                                        title="Закрыть позицию"
                                        size="sm"
                                        className="px-2 py-0 btn-center"
                                >
                                  Close
                                </Button>
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <td colSpan={12}>*/}
                            {/*    {*/}
                            {/*      JSON.stringify(Object.values(values))*/}
                            {/*    }*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                          </>
                        );
                      })}
                      </tbody>
                    </Table>
                    {[...dashboard?.positions || []].map((p, index) => {

                      const activationPrice: any = {
                        'SHORT': {
                          'STOP_MARKET'       : '>=',
                          'TAKE_PROFIT_MARKET': '<=',
                        },
                        'LONG' : {
                          'STOP_MARKET'       : '<=',
                          'TAKE_PROFIT_MARKET': '>=',
                        },
                      };

                      return (
                        <Table striped bordered hover className={p?.groups?.length ? '' : 'hidden'}>
                          <thead>
                          <tr>
                            <th colSpan={12} className="text-center text-bg-primary opacity-50 text-bg-dark">
                              Ордера {p.symbol} {p.positionSide} (открыто на бирже {p?.openOrders || 0}
                              <a href="#/" hidden={ !+p?.openOrders} onClick={() => doCloseCurrentOrders(p)}
                                 title={'Close current orders without closing position'}> [X]
                              </a>
                              )
                            </th>
                          </tr>
                          <tr>
                            <th>#</th>
                            <th>Дата</th>
                            <th>ID</th>
                            <th>Торговая пара</th>
                            <th>Side / Тип</th>
                            <th>Объём</th>
                            <th>Цена</th>
                            <th>PNL (ROE)</th>
                            <th>Б/У</th>
                            <th colSpan={1}>Статус</th>
                          </tr>
                          </thead>
                          <tbody>
                          {[...p?.groups || []].map((group, index) => {

                            return (
                              <>
                                <tr>
                                  <th colSpan={9} className="text-center text-bg-primary text-bg-light m-0 p-0" title={group?._id}>
                                    GROUP #{group.index || index} <sup>({group?.workingType} {group?.timeInForce} {group?.entryPrice})</sup>
                                    <a href="#/"
                                       onClick={() => doCloseCurrentOrders(group)}
                                       title={'Close current orders without closing position'}> ({group?.openOrders || 'hide empty group'} [X])
                                    </a>
                                  </th>
                                  {/*<th className="width-65 p-0">*/}
                                  {/*  <Button onClick={() => setGroupCurrent(group)}*/}
                                  {/*          variant="outline-primary"*/}
                                  {/*          title="Добавить STOP LOSS"*/}
                                  {/*          size="sm"*/}
                                  {/*          className="p-0 m-0 btn-center"*/}
                                  {/*          disabled={true}*/}
                                  {/*  >*/}
                                  {/*    + SL*/}
                                  {/*  </Button>*/}
                                  {/*</th>*/}
                                  {/*<th className="width-65 p-0">*/}
                                  {/*  <Button onClick={() => setGroupCurrent(group)}*/}
                                  {/*          variant="outline-primary"*/}
                                  {/*          title="Добавить TAKE PROFIT"*/}
                                  {/*          size="sm"*/}
                                  {/*          className="p-0 m-0 btn-center"*/}
                                  {/*          disabled={true}*/}
                                  {/*  >*/}
                                  {/*    + TP*/}
                                  {/*  </Button>*/}
                                  {/*</th>*/}
                                  <th className="width-65 p-0">
                                    <Button onClick={() => setGroupCurrent(group)}
                                            variant="outline-danger"
                                            title="Закрыть группу"
                                            size="sm"
                                            className="p-0 m-0 btn-center"
                                            disabled={disabled}
                                    >
                                      Close
                                    </Button>
                                  </th>
                                </tr>
                                {[...group?.orders || []].map((item, index) => {
                                  return (

                                    <tr key={index}>
                                      <td title={item?._id || '?'}>{index + 1}</td>
                                      <td>{new Date(item.created).toLocaleDateString()} {new Date(item.created).toLocaleTimeString()}</td>
                                      <td className={item?.orderId ? 'fw-bold' : ''}>{item.orderId}</td>
                                      <td>{item.symbol}</td>
                                      <td className={item?.orderId ? 'fw-bold' : ''}>{item.positionSide} / {item.side} : {item.type}</td>
                                      <td className={(item?.orderId ? 'fw-bold' : '') + ' ' + (item.isVolumeError === true ? 'td-order-volume-error' : '')}>
                                        {item.quantity}
                                      </td>
                                      <td className={item?.orderId ? 'fw-bold' : ''}>
                                        {item.type === 'LIMIT' ? item.price : item.stopPrice ? activationPrice[item.positionSide][item.type] || '' : ''}
                                        {item.stopPrice || ''}
                                      </td>
                                      <td className={item?.orderId ? 'fw-bold' : ''}>{fin(item?.pnl, '', 4)}</td>
                                      <td>
                                        <span className={item?.breakeven ? '' : 'hidden'}>
                                          <center>&#x2714;</center>
                                        </span>
                                      </td>
                                      <td colSpan={1}
                                          className={(item?.orderId ? 'fw-bold' : '') + ' ' + (item?.status === 'UNKNOWN' && item?.type !== 'LIMIT' ? 'td-order-status-unknown' : '')}>
                                        {item.type === 'LIMIT' && item.orderId ? 'NEW' : (item?.status === 'UNKNOWN' ? 'WAITING' : item.status)}
                                      </td>
                                      {/*<td className="width-100">*/}
                                      {/*<Button onClick={() => orderMoveStart(item)}*/}
                                      {/*        variant="secondary"*/}
                                      {/*        title={'Передвинуть ' + item.type}*/}
                                      {/*        size="sm"*/}
                                      {/*        className="px-2 py-0 btn-center"*/}
                                      {/*        disabled={disabled}*/}
                                      {/*>*/}
                                      {/*  Move*/}
                                      {/*</Button>*/}
                                      {/*</td>*/}
                                    </tr>
                                  );
                                })}
                              </>
                            );
                          })}
                          </tbody>
                        </Table>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab title="SPOT" id="_spot" eventKey="_spot">
              <div hidden={currentTab !== '_spot'}>
                <Row>
                  <Col lg={3}>
                    <Button
                      onClick={() => {
                        setOrderNew({
                          exchange    : 'BINANCE',
                          derivative  : 'SPOT',
                          positionSide: 'LONG',
                          hedgeSpot   : false,
                        });
                      }}
                      variant="success"
                      title="Открыть ордер SPOT"
                      size="sm"
                      className={'btn-left p-2 mx-3 m-2'}
                      disabled={disabled}
                    >
                      <div className="btn-symbol" dangerouslySetInnerHTML={{__html: '&oplus;'}}></div>
                      <span>Открыть ордер SPOT</span>
                    </Button>
                  </Col>
                  <Col lg={6}>
                  </Col>
                  <Col lg={3}>
                    <Button
                      onClick={() => {
                        setOrderCurrent({
                          all       : true,
                          derivative: 'SPOT',
                        });
                      }}
                      variant="danger"
                      title="Закрыть всё позиции SPOT"
                      size="sm"
                      className={dashboard?.SPOT?.positions?.length || dashboard?.SPOT?.openOrders?.length ? 'btn-right p-2 m-2 mx-3' : 'hidden'}
                      disabled={disabled}
                    >
                      <div className="btn-symbol" dangerouslySetInnerHTML={{__html: '&otimes;'}}></div>
                      Закрыть все позиции SPOT
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped bordered hover className={dashboard?.SPOT?.positions?.length ? '' : 'hidden'}>
                      <thead>
                      <tr>
                        <th colSpan={11} className="text-center text-bg-primary opacity-50 text-bg-dark">
                          <a href="#/" onClick={() => {
                            setCurrentPositionId(currentPositionId || currentPositionId === 'all' ? '' : 'all');
                          }}>
                            Позиции
                          </a>
                        </th>
                      </tr>
                      <tr>
                        <th>#</th>
                        {/*<th>Дата</th>*/}
                        <th>Торговая пара</th>
                        <th>Направление</th>
                        <th>Размер позиции</th>
                        <th>Стоимость сделки (текущая)</th>
                        {/*<th>Маржа</th>*/}
                        {/*<th>Безубыточная цена</th>*/}
                        {/*<th>Цена средняя</th>*/}
                        <th>Цена входа</th>
                        <th>Текущая цена</th>
                        {/*<th>Цена маркировки</th>*/}
                        {/*<th>Interest Rate, %</th>*/}
                        {/*<th>Ставка (Last Funding Rate), %</th>*/}
                        <th>Открыто ордеров</th>
                        <th>PNL (ROE)</th>
                        <th colSpan={2}>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {[...dashboard?.SPOT?.positions || []].map((spot, index) => {

                        let nOrders             = +spot.openOrders;
                        let hiddenIfDataUnknown = +nOrders || +spot?.pnl !== 0 || +spot?.unrealizedProfit !== 0 ? '' : 'hidden';
                        let showIfDataUnknown   = hiddenIfDataUnknown ? '' : 'hidden';
                        // let hiddenIfDataUnknown = '';
                        // let showIfDataUnknown   = 'hidden';

                        return (
                          <>
                            <tr key={index}>
                              <td>
                                <Button onClick={() => {
                                  return setCurrentPositionId(currentPositionId === spot?._id ? '' : spot?._id);
                                }}
                                        variant="outline-light"
                                        title="Открыть ордера"
                                        size="sm"
                                        className={'px-0 py-0 btn-center '}
                                >
                                  {index + 1}
                                </Button>
                              </td>
                              {/*<td>{new Date(item.datetime).toLocaleDateString()} {new Date(item.datetime).toLocaleTimeString()}</td>*/}
                              <td>
                                {spot.symbol} <sub hidden={ !spot.hedgeSpot}>[hedge]</sub>
                              </td>
                              <td className={spot?.positionSide === 'LONG' ? 'c-long' : 'c-short'}>{spot.positionSide}</td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {+spot?.origQty || 0} {spot.symbol.replace('USDT', '')}
                                  <span hidden={+spot?.quantity === +spot?.origQty}>
                                    &nbsp;/&nbsp;
                                    {+spot?.quantity} {spot.symbol.replace('USDT', '')}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {+fin(+spot?.quantity * +spot?.entryPrice, '', 8)} USDT
                                  {+spot?.unrealizedProfit ? '( ' + (+fin(+spot?.quantity * +spot?.price, '', 8)) + ' USDT )' : ''}
                                </span>
                              </td>
                              {/*<td>{item.notional +' USDT'}</td>*/}
                              {/*<td>*/}
                              {/*  <span className={'c-short ' + showIfDataUnknown}>*/}
                              {/*    UNKNOWN*/}
                              {/*  </span>*/}
                              {/*  <span className={hiddenIfDataUnknown}>*/}
                              {/*    {fin(item.isolatedWallet, 0, 2)} USDT {item.marginType ? '(' + item.marginType + ')' : ''}*/}
                              {/*  </span>*/}
                              {/*</td>*/}
                              {/*<td>*/}
                              {/*  <span className={'c-short ' + showIfDataUnknown}>*/}
                              {/*    UNKNOWN*/}
                              {/*  </span>*/}
                              {/*  <span className={hiddenIfDataUnknown}>*/}
                              {/*    {item.breakEvenPrice}*/}
                              {/*  </span>*/}
                              {/*</td>*/}
                              {/*/!*<td>{item.avgPrice}</td>*!/*/}
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown} hidden={ !+spot?.entryPrice}>
                                  {spot.entryPrice}
                                  <span hidden={ !+spot?.priceAvg || +spot?.priceAvg === +spot.entryPrice}>( {spot?.priceAvg} )</span>
                                </span>
                              </td>
                              <td>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown}>
                                  {spot.price}
                                </span>
                              </td>
                              {/*<td>*/}
                              {/*  <span className={'c-short ' + showIfDataUnknown}>*/}
                              {/*    UNKNOWN*/}
                              {/*  </span>*/}
                              {/*  <span className={hiddenIfDataUnknown}>*/}
                              {/*    {item.markPrice}*/}
                              {/*  </span>*/}
                              {/*</td>*/}
                              {/*<td>*/}
                              {/*  <span className={'c-short ' + showIfDataUnknown}>*/}
                              {/*    UNKNOWN*/}
                              {/*  </span>*/}
                              {/*  <span className={hiddenIfDataUnknown}>*/}
                              {/*    {item?.rate?.interestRate} %*/}
                              {/*  </span>*/}
                              {/*</td>*/}
                              {/*<td>*/}
                              {/*  <span className={'c-short ' + showIfDataUnknown}>*/}
                              {/*    UNKNOWN*/}
                              {/*  </span>*/}
                              {/*  <span className={hiddenIfDataUnknown}>*/}
                              {/*    {item?.rate?.lastFundingRate} %*/}
                              {/*  </span>*/}
                              {/*</td>*/}
                              <td>
                                <span className={nOrders ? '' : 'c-short'}>
                                  {+nOrders || 0}
                                </span>
                              </td>
                              <td className={+spot?.unrealizedProfit < 0 ? 'pnl-minus' : 'pnl-plus'}>
                                <span className={'c-short ' + showIfDataUnknown}>
                                  UNKNOWN
                                </span>
                                <span className={hiddenIfDataUnknown} hidden={ !+spot?.unrealizedProfit}>
                                  {+fin(spot?.unrealizedProfit, '', 3)} USDT {spot.roe ? '(' + spot.roe + '%)' : ''}
                                </span>
                                {+spot?.unrealizedProfit ? '' : 'WAITING'}
                              </td>
                              <td className="width-100">
                                <Button onClick={() => {
                                  return setPositionEdit(initPositionEdit(spot));
                                }}
                                        variant="outline-primary"
                                        title="Редактировать позицию"
                                        size="sm"
                                        className={'px-2 py-0 btn-center '}
                                  // className={'px-2 py-0 btn-center ' + hiddenIfDataUnknown}
                                >
                                  Edit
                                </Button>
                              </td>
                              <td className="width-100">
                                <Button onClick={() => setOrderCurrent({...spot, ...{derivative: 'SPOT'}})}
                                        variant="outline-danger"
                                        title="Закрыть позицию"
                                        size="sm"
                                        className="px-2 py-0 btn-center"
                                >
                                  Close
                                </Button>
                              </td>
                            </tr>
                            <tr hidden={ !(currentPositionId && (currentPositionId === spot?._id || currentPositionId === 'all'))}>
                              <td colSpan={11}>
                                <Table striped bordered hover className={spot?.groups?.length || spot?.openOrders ? '' : 'hidden'}>
                                  <thead>
                                  <tr>
                                    <th colSpan={11} className="text-center text-bg-primary opacity-50 text-bg-dark">
                                      Ордера {spot.symbol} {spot.positionSide} (открыто на бирже {spot?.openOrders || 0}
                                      <a href="#/" hidden={ !+spot?.openOrders} onClick={() => doCloseCurrentOrders(spot)}
                                         title={'Close current orders without closing position'}> [X]
                                      </a>
                                      )
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>#</th>
                                    <th>Дата</th>
                                    <th>ID</th>
                                    <th>Торговая пара</th>
                                    <th>Side / Тип</th>
                                    <th>Объём</th>
                                    <th>Цена входа</th>
                                    <th>Цена</th>
                                    <th>PNL (ROE)</th>
                                    {/*<th>Б/У</th>*/}
                                    <th colSpan={1}>Статус</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {[...spot?.groups || []].map((group, index) => {

                                    return (
                                      <>
                                        <tr>
                                          <th colSpan={9} className="text-center text-bg-primary text-bg-light m-0 p-0" title={group?._id}>
                                            GROUP #{group.index || index}
                                            <sup>({group?.workingType} {group?.timeInForce} {group?.entryPrice})</sup>
                                          </th>
                                          {/*<th className="width-65 p-0">*/}
                                          {/*  <Button onClick={() => setGroupCurrent(group)}*/}
                                          {/*          variant="outline-primary"*/}
                                          {/*          title="Добавить STOP LOSS"*/}
                                          {/*          size="sm"*/}
                                          {/*          className="p-0 m-0 btn-center"*/}
                                          {/*          disabled={true}*/}
                                          {/*  >*/}
                                          {/*    + SL*/}
                                          {/*  </Button>*/}
                                          {/*</th>*/}
                                          {/*<th className="width-65 p-0">*/}
                                          {/*  <Button onClick={() => setGroupCurrent(group)}*/}
                                          {/*          variant="outline-primary"*/}
                                          {/*          title="Добавить TAKE PROFIT"*/}
                                          {/*          size="sm"*/}
                                          {/*          className="p-0 m-0 btn-center"*/}
                                          {/*          disabled={true}*/}
                                          {/*  >*/}
                                          {/*    + TP*/}
                                          {/*  </Button>*/}
                                          {/*</th>*/}
                                          <th className="width-65 p-0">
                                            <Button onClick={() => setGroupCurrent(group)}
                                                    variant="outline-danger"
                                                    title="Закрыть группу"
                                                    size="sm"
                                                    className="p-0 m-0 btn-center"
                                                    disabled={disabled}
                                            >
                                              Close
                                            </Button>
                                          </th>
                                        </tr>
                                        {[...group?.orders || []].map((item, index) => {
                                          return (

                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{new Date(item.created).toLocaleDateString()} {new Date(item.created).toLocaleTimeString()}</td>
                                              <td className={item?.orderId ? 'fw-bold' : ''}>
                                                {item?.orderId} {item?.orderId2 ? ', ' + item?.orderId2 : ''}
                                              </td>
                                              <td>{item.symbol}</td>
                                              <td className={item?.orderId ? 'fw-bold' : ''}>{item.positionSide} / {item.side} : {item.type}</td>
                                              <td className={(item?.orderId ? 'fw-bold' : '') + ' ' + (item.isVolumeError === true ? 'td-order-volume-error' : '')}>
                                                {item.quantity}
                                              </td>
                                              <td className={(item?.orderId ? 'fw-bold' : '') + ' ' + (item.isVolumeError === true ? 'td-order-volume-error' : '')}>
                                                <span>{group?.entryPrice}</span>
                                              </td>
                                              <td className={item?.orderId ? 'fw-bold' : ''}>
                                                <Row>
                                                  <Col>{item.orderType === 'OCO' || item.orderType.indexOf('TAKE') > -1 ? 'TP = ' : 'SL = '} {item.price ? item.price : ''}</Col>
                                                  <Col>{item.stopPrice ? 'TRIGGER ' + (item.orderType === 'OCO' || item?.orderType?.indexOf('STOP') > -1 ? ' <= ' : ' >= ') + item.stopPrice : ''}</Col>
                                                  <Col hidden={item.orderType !== 'OCO'}>SL = {item.stopLimitPrice || ''}</Col>
                                                </Row>
                                              </td>
                                              <td className={(+group?.pnl >= 0 ? 'pnl-plus' : 'pnl-minus')}>
                                                {fin(group?.pnl, '', 4)}
                                              </td>
                                              {/*<td>*/}
                                              {/*  <span className={item?.breakeven ? '' : 'hidden'}>*/}
                                              {/*    <center>&#x2714;</center>*/}
                                              {/*  </span>*/}
                                              {/*</td>*/}
                                              <td colSpan={1}
                                                  className={(item?.orderId ? 'fw-bold' : '') + ' ' + (item?.status === 'UNKNOWN' ? 'td-order-status-unknown' : '')}>
                                                {item.status}
                                              </td>
                                              {/*<td>*/}
                                              {/*<Button hidden={item.status !== 'WAITING'} onClick={() => doStartOrder(item)}*/}
                                              {/*        variant="outline-light"*/}
                                              {/*        title="Открыть ордер"*/}
                                              {/*        size="sm"*/}
                                              {/*        className="p-0 m-0 btn-center"*/}
                                              {/*        disabled={disabled}*/}
                                              {/*>*/}
                                              {/*  Open*/}
                                              {/*</Button>*/}
                                              {/*</td>*/}
                                              {/*<td className="width-100">*/}
                                              {/*<Button onClick={() => orderMoveStart(item)}*/}
                                              {/*        variant="secondary"*/}
                                              {/*        title={'Передвинуть ' + item.type}*/}
                                              {/*        size="sm"*/}
                                              {/*        className="px-2 py-0 btn-center"*/}
                                              {/*        disabled={disabled}*/}
                                              {/*>*/}
                                              {/*  Move*/}
                                              {/*</Button>*/}
                                              {/*</td>*/}
                                            </tr>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      </tbody>
                    </Table>
                    {/*{[...dashboard?.SPOT?.positions || []].map((item, index) => {*/}
                    {/*  return (*/}
                    {/*    */}
                    {/*  );*/}
                    {/*})}*/}
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab title="NOTIFICATIONS" id="_notifications" eventKey="_notifications">
              <Row hidden={currentTab !== '_notifications'}>
                <Col>
                  <DashboardNotificationsTab dispatch={dispatch} active={currentTab === '_notifications'} />
                </Col>
              </Row>
            </Tab>
            <Tab title="AUTO" id="_auto" eventKey="_auto">
              <Row hidden={currentTab !== '_auto'}>
                <Col>
                  <DashboardAutoTab dashboard={dashboard} dispatch={dispatch} active={currentTab === '_auto'} />
                </Col>
              </Row>
            </Tab>
            <Tab title="INFO" id="_info" eventKey="_info">
              <Row hidden={currentTab !== '_info'}>
                <Col>
                  <DashboardInfoTab dashboard={dashboard} dispatch={dispatch} active={currentTab === '_info'} />
                </Col>
              </Row>
            </Tab>
            <Tab title="AUTOBOT" id="_autoparams" eventKey="_autoparams">
              <Row hidden={currentTab !== '_autoparams'}>
                <Col>
                  <DashboardAutoParamsTab user={dashboard?.user} dispatch={dispatch} active={currentTab === '_autoparams'} />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Modal show={positionEdit !== undefined}
             position={positionEdit}
        /*setValues={setValues}*/
             onHide={() => { setPositionEdit(undefined); }}
             size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{'Изменение позиции'} : {positionEdit?.derivative}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <strong>{(positionEdit?.symbol + ' ' + positionEdit?.positionSide)}</strong>
          </center>
          <Container className="bg-light rounded-2 p-2 m-2" fluid>
            <Table striped bordered hover>
              <tbody className="position-edit-form">
              {/*<tr>*/}
              {/*  <th colSpan={1} className="text-center text-bg-primary text-bg-dark m-0 p-0">*/}
              {/*    <center>*/}
              {/*      */}
              {/*    </center>*/}
              {/*  </th>*/}
              {/*</tr>*/}
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <th colSpan={8}>
                  <center> ADD SPOT POSITION: ORDER + OCO</center>
                </th>
              </tr>
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <td rowSpan={4} colSpan={1} className="pt-5">
                  <h1 className="pt-3">+</h1>
                </td>
                <td colSpan={4}>
                  <a target="_blank"
                     rel="noreferrer"
                     title="OCO information link"
                     href="https://www.binance.com/ru/support/faq/360032605831"
                     className="link-info">*
                  </a>
                  OCO: TAKE PROFIT
                </td>
                <td className="centered">
                  <InputGroup className="mb-0 position-edit-value" size="sm">
                    <Form.Control className="m-0"
                                  disabled={disabled}
                                  inputMode="decimal"
                                  type="number"
                                  name="newGroup.priceNewProfit"
                                  required={true}
                                  onChange={handleChange('data.newGroup.priceNewProfit')}
                    />
                  </InputGroup>
                </td>
                <td className="centered align-middle" rowSpan={4}>
                  <InputGroup className="centered align-middle position-edit-value" size="sm">
                    <Form.Control className=""
                                  disabled={disabled}
                                  inputMode="decimal"
                                  type="number"
                                  required={true}
                                  onChange={handleChange('data.newGroup.quantityNew')}
                    />
                  </InputGroup>
                </td>
                <td rowSpan={4}></td>
              </tr>
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <td colSpan={4} className="">
                  <center>
                    ORDER:
                    <InputGroup className="">
                      <Form.Check
                        className="mx-4"
                        type="radio"
                        radioGroup="newOrderType"
                        name="newOrderTypeMarket"
                      >
                        <Form.Check.Input
                          className=""
                          radioGroup="newOrderType"
                          name="newGroup.newOrderType"
                          value="MARKET"
                          key="MARKET"
                          type="radio"
                          checked={values?.data?.newGroup?.newOrderType === 'MARKET'}
                          onChange={handleChange('data.newGroup.newOrderType')}
                        />
                        <Form.Check.Label>
                          MARKET
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check
                        className="mx-1"
                        type="radio"
                        radioGroup="newOrderType"
                        name="newOrderTypeLimit"
                      >
                        <Form.Check.Input
                          className=""
                          radioGroup="newOrderType"
                          name="newGroup.newOrderType"
                          value="LIMIT"
                          key="LIMIT"
                          type="radio"
                          checked={values?.data?.newGroup?.newOrderType === 'LIMIT'}
                          onChange={handleChange('data.newGroup.newOrderType')}
                        />
                        <Form.Check.Label>
                          LIMIT
                        </Form.Check.Label>
                      </Form.Check>
                    </InputGroup>
                  </center>
                </td>
                <td className="centered">
                  <InputGroup className="mb-0 position-edit-value" size="sm" hidden={values?.data?.newGroup?.newOrderType === 'MARKET'}>
                    <Form.Control className="m-0"
                                  disabled={disabled}
                                  inputMode="decimal"
                                  type="number"
                                  name="newGroup.priceNew"
                                  defaultValue={values?.data?.newGroup?.priceNew}
                                  onChange={handleChange('data.newGroup.priceNew')}
                    />
                  </InputGroup>
                </td>
              </tr>
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <td colSpan={4}>OCO: STOP ( activation stop loss )</td>
                <td className="centered">
                  <InputGroup className="mb-0 position-edit-value" size="sm">
                    <Form.Control className="m-0"
                                  disabled={disabled}
                                  inputMode="decimal"
                                  type="number"
                                  required={true}
                                  onChange={handleChange('data.newGroup.stopPriceNew')}
                    />
                  </InputGroup>
                </td>
              </tr>
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <td colSpan={4}>OCO: LIMIT ( stop loss )</td>
                <td className="centered">
                  <InputGroup className="mb-0 position-edit-value" size="sm">
                    <Form.Control className="m-0"
                                  disabled={disabled}
                                  inputMode="decimal"
                                  type="number"
                                  required={true}
                                  onChange={handleChange('data.newGroup.stopLimitPriceNew')}
                    />
                  </InputGroup>
                </td>
              </tr>
              <tr hidden={positionEdit?.derivative === 'FUTURES'}>
                <td colSpan={8}>
                  <hr />
                </td>
              </tr>
              {/*<tr>*/}
              {/*  <th colSpan={7} className="text-center text-bg-primary opacity-50 text-bg-dark">*/}
              {/*Открытые ордера: {p.symbol} {p.positionSide}*/}
              {/*</th>*/}
              {/*</tr>*/}
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Тип</th>
                <th>Статус</th>
                <th>Б/У</th>
                <th>Цена</th>
                <th>Объём ({positionEdit?.symbol?.replace('USDT', '')})</th>
                <th>Action</th>
                {/*<th hidden={positionEdit?.derivative === 'SPOT'}>Удалить</th>*/}
              </tr>
              <tr hidden={positionEdit?.derivative === 'SPOT'}
                  className={'enable-checkbox'}>
                <td colSpan={5} className="centered">
                  <span className="float-end py-2">
                    + TAKE PROFIT
                    {/*<br />*/}
                    {/*<br />*/}
                    {/*+ LIMIT*/}
                    <br />
                    <br />
                    + STOP LOSS
                  </span>
                </td>
                <td className="position-edit-value">
                  {/*<Col xs={4} md={4} lg={4}>*/}
                  <center>
                    <Form.Control className="m-0 centered" size="sm" type="number"
                                  onChange={handleChange('data.newGroup.profit')}
                                  step={0.01}
                                  defaultValue={values?.data?.newGroup?.profit}
                                  disabled={disabled}
                    />
                    {/*<br />*/}
                    {/*<Form.Control className="m-0 centered" size="sm" type="number"*/}
                    {/*              onChange={handleChange('data.newGroup.limit')}*/}
                    {/*              step={0.01}*/}
                    {/*              defaultValue={values?.data?.newGroup?.limit}*/}
                    {/*              disabled={disabled}*/}
                    {/*/>*/}
                    <br />
                    <Form.Control className="m-0 centered" size="sm" type="number"
                                  onChange={handleChange('data.newGroup.sl')}
                                  step={0.01}
                                  defaultValue={values?.data?.newGroup?.sl}
                                  disabled={disabled}
                    />
                  </center>
                  {/*</Col>*/}
                </td>
                <td className="py-4">
                  <Col xs={12} md={12} lg={12}>
                    <center>
                      <Form.Control className="m-0 centered" size="sm" type="number"
                                    onChange={handleChange('data.newGroup.quantity')}
                                    step={0.001}
                                    defaultValue={values?.data?.newGroup?.quantity}
                                    disabled={disabled}
                      />
                    </center>
                  </Col>
                  {/*    <Col xs={4} md={4} lg={4}>*/}
                  {/*      <center>*/}
                  {/*        <Form.Control className="m-0 centered"*/}
                  {/*                      size="sm"*/}
                  {/*                      type="number"*/}
                  {/*                      onChange={handleChange('data[' + group._id + '][\'oco\'].stopPrice')}*/}
                  {/*                      defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.stopPrice : ''}*/}
                  {/*                      disabled={ocoDisabled}*/}
                  {/*        />*/}
                  {/*        <span className="span-small">Stop</span>*/}
                  {/*      </center>*/}
                  {/*    </Col>*/}
                  {/*    <Col className="" xs={4} md={4} lg={4}>*/}
                  {/*      <center>*/}
                  {/*        <Form.Control className="m-0 centered"*/}
                  {/*                      size="sm"*/}
                  {/*                      type="number"*/}
                  {/*                      onChange={handleChange('data[' + group._id + '][\'oco\'].stopLimitPrice')}*/}
                  {/*                      defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.stopLimitPrice : ''}*/}
                  {/*                      disabled={ocoDisabled}*/}
                  {/*        />*/}
                  {/*        <span className="span-small">Limit</span>*/}
                  {/*      </center>*/}
                  {/*    </Col>*/}
                </td>
                <td>
                  <Form.Check
                    className={'p-0 py-4'}
                    type="switch"
                    id={'data_new_enable'}
                    name={'data_new_enable'}
                  >
                    <Form.Check.Input
                      className="border-success btn-success mx-3 p-2"
                      name={'data_new_enable'}
                      value="true"
                      type="checkbox"
                      defaultChecked={false}
                      onChange={(e) => {
                        setFieldValue('data.newGroup.enable', e.target.checked);
                      }}
                    />
                  </Form.Check>
                </td>
                {/*<td className="position-edit-value">*/}
                {/*  <center>*/}
                {/*    <InputGroup className={'mb-0 centered py-0'} size="sm">*/}
                {/*      <Form.Control className="m-0 centered"*/}
                {/*                    size="sm"*/}
                {/*                    type="number"*/}
                {/*                    onChange={handleChange('data[' + group._id + '][\'oco\'].quantity')}*/}
                {/*                    defaultValue={group?.quantity}*/}
                {/*                    disabled={ocoDisabled}*/}
                {/*      />*/}
                {/*    </InputGroup>*/}
                {/*    <span className="span-small">Quantity</span>*/}
                {/*  </center>*/}
                {/*</td>*/}
                {/*<td>*/}
                {/*  <Form.Check*/}
                {/*    className={'p-0 py-4'}*/}
                {/*    type="switch"*/}
                {/*    id={'enable_oco_' + group._id}*/}
                {/*    name={'enable_oco_' + group._id}*/}
                {/*  >*/}
                {/*    <Form.Check.Input*/}
                {/*      className="border-success btn-success mx-3 p-2"*/}
                {/*      name={'enable_oco_' + group._id}*/}
                {/*      value="true"*/}
                {/*      type="checkbox"*/}
                {/*      defaultChecked={false}*/}
                {/*      onChange={handleChange('data[' + group._id + '][\'oco\'].enable')}*/}
                {/*    />*/}
                {/*  </Form.Check>*/}
                {/*</td>*/}
              </tr>
              {[...positionEdit?.groups || []].map((group: any, index: any) => {

                let groupOrders             = group?.orders?.filter((f: any) => (f?.orderType === 'OCO' || f?.orderType === 'LIMIT' || f?.orderType === 'MARKET' || f?.orderType === 'TAKE_PROFIT_MARKET' || f?.orderType === 'STOP_MARKET' || f?.orderType === 'TAKE_PROFIT' || f?.orderType === 'STOP_LOSS' || f?.orderType === 'TAKE_PROFIT_LIMIT' || f?.orderType === 'STOP_LOSS_LIMIT') && (positionEdit?.derivative === 'SPOT' || positionEdit?.derivative === 'FUTURES'));
                let requiredNewSpotOcoOrder = (group?.derivative === 'SPOT') && ( !group?.orders?.length || group?.orders?.filter((o: any) => (o.status === 'NEW' || o.status === 'EXECUTING' || o.status === 'WAITING'))?.length === 0) ? true : false;
                let ocoDisabled             = false;
                let newOrderType            = values?.data && values?.data[group._id] && values?.data[group._id]?.oco?.type || '';

                if (positionEdit?.derivative === 'SPOT') {

                  let spotWallet = dashboard.SPOT?.balances.find((o: any) => o.assetUsdt === positionEdit.symbol);
                  group.quantity = Math.min(+spotWallet?.totalAmount || 0, group?.quantity) || group?.quantity;

                  positionEdit['type'] = positionEdit['orderType'] || 'OCO';
                  if ( !values?.data || !values?.data[group._id] || !values?.data[group._id]?.oco) {
                    setFieldValue('data[' + group._id + '][\'oco\']', {
                      type          : 'OCO',
                      enable        : false,
                      quantity      : group?.quantity,
                      price         : +Number(positionEdit?.price * 1.2).toFixed(2),
                      stopPrice     : +Number(positionEdit?.price * 0.8).toFixed(2),
                      stopLimitPrice: +Number(positionEdit?.price * 0.8).toFixed(2),
                    });
                  }
                  ocoDisabled = values?.data && values?.data[group._id] && values?.data[group._id]?.oco && values?.data[group._id]?.oco?.enable !== true;
                }

                return (
                  <>
                    <tr>
                      <td colSpan={8} className="centered" title={group?._id}>
                        <strong>
                          GROUP #{group?.index}
                        </strong>
                      </td>
                    </tr>
                    <tr hidden={ !requiredNewSpotOcoOrder}
                        className={
                          'enable-checkbox'
                          + ' ' +
                          (values?.data && values?.data[group._id] && values?.data[group._id]?.oco?.enable ? 'order-added' : '')
                        }>
                      <td colSpan={5} className="centered py-4">
                        + NEW ORDER
                      </td>
                      <td className="position-edit-value">
                        <Row>
                          <Col xs={3} md={3} lg={3}>
                            <center>
                              <Form.Select
                                className="m-0 centered" size="sm"
                                onChange={handleChange('data[' + group._id + '][\'oco\'].type')}
                                defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.type || 'OCO' : 'OCO'}
                                disabled={ocoDisabled}
                                name={`orderMode`}
                                id={`orderMode`}
                              >
                                <option value={'OCO'} defaultChecked={true}>OCO</option>
                                <option value={'TAKE_PROFIT_LIMIT'}>TAKE_PROFIT_LIMIT</option>
                                <option value={'STOP_LOSS_LIMIT'}>STOP_LOSS_LIMIT</option>
                              </Form.Select>
                              <span className="span-small">Type</span>
                            </center>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <center>
                              <Form.Control className="m-0 centered" size="sm" type="number"
                                            onChange={handleChange('data[' + group._id + '][\'oco\'].price')}
                                            defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.price : ''}
                                            disabled={ocoDisabled}
                              />
                              <span className="span-small">
                                {newOrderType?.indexOf('STOP') > -1 ? 'Stop Loss' : 'Take Profit'}
                              </span>
                            </center>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <center hidden={newOrderType === 'LIMIT'}>
                              <Form.Control className="m-0 centered"
                                            size="sm"
                                            type="number"
                                            onChange={handleChange('data[' + group._id + '][\'oco\'].stopPrice')}
                                            defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.stopPrice : ''}
                                            disabled={ocoDisabled}
                              />
                              <span className="span-small">Trigger {newOrderType?.indexOf('TAKE') > -1 ? '>=' : '<='}</span>
                            </center>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <center hidden={newOrderType !== 'OCO'}>
                              <Form.Control className="m-0 centered"
                                            size="sm"
                                            type="number"
                                            onChange={handleChange('data[' + group._id + '][\'oco\'].stopLimitPrice')}
                                            defaultValue={values?.data && values?.data[group._id] ? values?.data[group._id]?.oco?.stopLimitPrice : ''}
                                            disabled={ocoDisabled}
                              />
                              <span className="span-small">Stop Loss</span>
                            </center>
                          </Col>
                        </Row>
                      </td>
                      <td className="position-edit-value">
                        <center>
                          {/*{JSON.stringify(group)}*/}
                          <InputGroup className={'mb-0 centered py-0'} size="sm">
                            <Form.Control className="m-0 centered"
                                          size="sm"
                                          type="number"
                                          onChange={handleChange('data[' + group._id + '][\'oco\'].quantity')}
                                          defaultValue={group?.quantity}
                                          disabled={ocoDisabled}
                            />
                          </InputGroup>
                          <span className="span-small">Quantity</span>
                        </center>
                      </td>
                      <td>
                        <Form.Check
                          className={'p-0 py-4'}
                          type="switch"
                          id={'enable_oco_' + group._id}
                          name={'enable_oco_' + group._id}
                        >
                          <Form.Check.Input
                            className="border-success btn-success mx-3 p-2"
                            name={'enable_oco_' + group._id}
                            value="true"
                            type="checkbox"
                            defaultChecked={false}
                            onChange={handleChange('data[' + group._id + '][\'oco\'].enable')}
                          />
                        </Form.Check>
                      </td>
                    </tr>
                    {[...groupOrders || []]?.map((order: any, index: any) => {
                      return (
                        <tr className={
                          (values?.data && values?.data[group._id] && values?.data[group._id][order._id] ? 'position-value-changed' : '')
                          + ' ' +
                          (values?.data && values?.data[group._id] && values?.data[group._id][order._id]?.delete ? 'order-deleted' : '')
                        }>
                          <td>{index + 1}</td>
                          <td>{order.orderId} {order?.orderId2 ? ', ' + order?.orderId2 : ''}</td>
                          <td>{order?.isNew ? order.typeNew : order.orderType}</td>
                          <td>{order.status}</td>
                          <td className="centered position-edit-value">
                            <Form.Check
                              disabled={disabled || (order?.positionSide === 'LONG' && index === 0) || (order?.positionSide === 'SHORT' && index === (group?.orders?.length - 1))}
                              className={
                                'm-0 p-0 '
                                + (!(order.type.indexOf('TAKE_PROFIT_MARKET') > -1 && (order.status === 'WAITING' || order.status === 'NEW')) ? 'hidden' : '')
                                + ' '
                                + (index === 0 && !order?.breakeven ? 'hidden' : '')
                              }
                              type="checkbox"
                              id={'breakeven_' + order._id}
                              name={'breakeven_' + order._id}
                            >
                              <Form.Check.Input
                                disabled={disabled || (order?.positionSide === 'LONG' && index === 0) || (order?.positionSide === 'SHORT' && index === (group?.orders?.length - 1))}
                                className="m-0 py-3 px-0"
                                name={'breakeven_' + order._id}
                                value="true"
                                type="checkbox"
                                defaultChecked={(values?.data && values?.data[group._id] && values?.data[group._id][order._id] && values?.data[group._id][order._id]?.breakeven) || order.breakeven}
                                aria-describedby="breakeven"
                                onChange={handleChange('data[' + group._id + '][' + order._id + '].breakeven')}
                              />
                            </Form.Check>
                            {/*<Form.Check*/}
                            {/*  disabled={disabled}*/}
                            {/*  className={'m-0 p-0 '}*/}
                            {/*  type="switch"*/}
                            {/*  radioGroup={group._id}*/}
                            {/*  name={'breakeven_' + group._id}*/}
                            {/*  id={`order-${group._id}-${order._id}`}*/}
                            {/*>*/}
                            {/*  <Form.Check.Input*/}
                            {/*    disabled={disabled || order.type.indexOf('TAKE_PROFIT_MARKET') !== -1 && order.status.indexOf('NEW') !== -1}*/}
                            {/*    hidden={order.type.indexOf('TAKE_PROFIT_MARKET') === -1}*/}
                            {/*    className="m-0 py-3 px-0"*/}
                            {/*    radioGroup={group._id}*/}
                            {/*    name={'breakeven_' + group._id}*/}
                            {/*    value={order._id}*/}
                            {/*    type="radio"*/}
                            {/*    defaultChecked={values?.data && values?.data[group._id] && values?.data[group._id][order._id] && values?.data[group._id][order._id]?.breakeven || order.breakeven}*/}
                            {/*    aria-describedby="breakeven"*/}
                            {/*    aria-description={order._id}*/}
                            {/*    onChange={*/}
                            {/*      (event: any) => {*/}
                            {/*        let oid     = event.target.getAttribute('aria-description');*/}
                            {/*        let checked = event.target.getAttribute('checked');*/}
                            {/*        if (values?.data) {*/}
                            {/*          for (const k of Object.keys(values?.data)) {*/}
                            {/*            if (k === group._id && values.data[k]) {*/}
                            {/*              for (const o of Object.keys(values?.data[k])) {*/}
                            {/*                values.data[k][o]['breakeven'] = o === oid ? 'true' : 'false';*/}
                            {/*              }*/}
                            {/*            }*/}
                            {/*          }*/}
                            {/*        }*/}
                            {/*        if ( !Object.keys(values).length) {*/}
                            {/*          values['data'] = {};*/}
                            {/*        }*/}
                            {/*        if ( !values.data[group._id] || !Object.keys(values.data[group._id]).length) {*/}
                            {/*          values.data[group._id] = {};*/}
                            {/*        }*/}
                            {/*        if ( !values.data[group._id][order._id] || !Object.keys(values.data[group._id][order._id]).length) {*/}
                            {/*          values.data[group._id][order._id] = {breakeven: oid === order._id ? 'true' : 'false'};*/}
                            {/*        }*/}
                            {/*        setValues(values);*/}
                            {/*        // return handleChange('data[' + group._id + '][' + order._id + '].breakeven');*/}
                            {/*      }*/}
                            {/*    }*/}
                            {/*  />*/}
                            {/*  /!*<Form.Check.Label>{"БУ"}</Form.Check.Label>*!/*/}
                            {/*  /!*<Form.Control.Feedback type="valid"></Form.Control.Feedback>*!/*/}
                            {/*</Form.Check>*/}
                          </td>
                          <td className="position-edit-value">
                            <Row>
                              <Col xs={4} md={4} lg={4}>
                                <center>
                                  <Form.Control className="m-0 centered editsm" size="sm" hidden={order?.isAllowEditPrice === false}
                                                disabled={disabled}
                                                inputMode="decimal"
                                                defaultValue={order.price}
                                                type="number"
                                                required={true}
                                                onChange={handleChange('data[' + group._id + '][' + order._id + '].price')}
                                  />
                                  <span className="span-small" hidden={positionEdit?.derivative === 'FUTURES'}>
                                    {order?.orderType === 'OCO' || order?.orderType.indexOf('TAKE') > -1 ? 'Take Profit' : order?.orderType.indexOf('STOP') > -1 ? 'Stop Loss' : 'Price'}
                                  </span>
                                </center>
                              </Col>
                              <Col xs={4} md={4} lg={4}>
                                <center hidden={order.orderType === 'LIMIT'}>
                                  <Form.Control className="m-0 centered editsm" size="sm"
                                                disabled={disabled}
                                                inputMode="decimal"
                                                defaultValue={order.stopPrice}
                                                type="number"
                                                required={true}
                                                title=""
                                                onChange={handleChange('data[' + group._id + '][' + order._id + '].stopPrice')}
                                  />
                                  <span className="span-small">Trigger {order.orderType === 'OCO' || order.orderType.indexOf('STOP') > -1 ? '<=' : '>='}</span>
                                </center>
                              </Col>
                              <Col className=""
                                   xs={4}
                                   md={4}
                                   lg={4}
                                   hidden={order.orderType !== 'OCO'}>
                                <center>
                                  <Form.Control className="m-0 centered editsm" size="sm"
                                                disabled={disabled}
                                                inputMode="decimal"
                                                defaultValue={order.stopLimitPrice}
                                                type="number"
                                                required={true}
                                                onChange={handleChange('data[' + group._id + '][' + order._id + '].stopLimitPrice')}
                                  />
                                  <span className="span-small">Stop Loss</span>
                                </center>
                              </Col>
                            </Row>
                          </td>
                          <td className="centered position-edit-value">
                            <InputGroup className={'mb-0 centered '} size="sm"
                              // hidden={order?.isAllowEditQuantity === false}
                            >
                              <Form.Control className="m-0 centered editsm"
                                            disabled={disabled}
                                            inputMode="decimal"
                                            aria-describedby="quantity"
                                            defaultValue={order?.quantity}
                                            type="number"
                                            required={true}
                                            onChange={handleChange('data[' + group._id + '][' + order._id + '].quantity')}
                              />
                            </InputGroup>
                            <span className="span-small" hidden={positionEdit?.derivative === 'FUTURES'}>Quantity</span>
                            {/*<span hidden={order?.isAllowEditQuantity === true}>*/}
                            {/*<span>*/}
                            {/*  {order.quantity}*/}
                            {/*</span>*/}
                          </td>
                          {/*<td className="centered position-edit-value align-middle"*/}
                          {/*    hidden={positionEdit?.derivative === 'FUTURES' || positionEdit?.derivative === 'SPOT' && index > 1}*/}
                          {/*    rowSpan={groupOrders?.length}*/}
                          {/*>*/}
                          {/*  <InputGroup className={'mb-0 centered '}*/}
                          {/*              size="sm"*/}
                          {/*              hidden={order?.orderType === 'OCO' && order?.status === 'NEW'}*/}
                          {/*    // hidden={order?.isAllowEditQuantity === false}*/}
                          {/*  >*/}
                          {/*    <Form.Control className="m-0 centered"*/}
                          {/*                  disabled={disabled}*/}
                          {/*                  inputMode="decimal"*/}
                          {/*                  aria-describedby="quantity"*/}
                          {/*                  defaultValue={order?.quantity}*/}
                          {/*                  type="number"*/}
                          {/*                  required={true}*/}
                          {/*                  onChange={(e: any) => {*/}
                          {/*                    for (const o of groupOrders) {*/}
                          {/*                      setFieldValue('data[' + group._id + '][' + o._id + '].quantity', e.target.value);*/}
                          {/*                    }*/}
                          {/*                  }}*/}
                          {/*    />*/}
                          {/*  </InputGroup>*/}
                          {/*  <span hidden={ !(order?.orderType === 'OCO' && order?.status === 'NEW')}>*/}
                          {/*    {order.quantity}*/}
                          {/*  </span>*/}
                          {/*</td>*/}
                          <td className="delete-checkbox"
                            // hidden={positionEdit?.derivative === 'SPOT'}
                          >
                            <Form.Check
                              className={'p-0 py-4'}
                              type="switch"
                              id={'delete_' + order._id}
                              name={'delete_' + order._id}
                            >
                              <Form.Check.Input
                                // hidden={order?.isAllowDelete === false}
                                className="border-danger btn-danger mx-3 p-2"
                                name={'delete_' + order._id}
                                value="true"
                                type="checkbox"
                                defaultChecked={false}
                                aria-describedby="delete"
                                onChange={handleChange('data[' + group._id + '][' + order._id + '].delete')}
                              />
                            </Form.Check>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/*

           || (
           ( !values?.data?.newGroup?.newOrderType)
           && (
           (values?.data?.newGroup?.newOrderType === 'LIMIT' && +values?.data?.newGroup?.priceNew !== undefined)
           || (+values?.data?.newGroup?.priceNewProfit !== undefined || +values?.data?.newGroup?.stopPriceNew !== undefined || +values?.data?.newGroup?.stopLimitPriceNew !== undefined || +values?.data?.newGroup?.quantityNew !== undefined)
           )
           )
           || ((values?.data?.newGroup?.newOrderType !== undefined) && (
           ((positionEdit?.derivative === 'SPOT') && (
           ( !+values?.data?.newGroup?.quantityNew !== undefined)
           || (values?.data?.newGroup?.newOrderType === 'LIMIT' && !+values?.data?.newGroup?.priceNew !== undefined)
           || ( !+values?.data?.newGroup?.priceNewProfit !== undefined || !+values?.data?.newGroup?.stopLimitPriceNew !== undefined || !+values?.data?.newGroup?.stopPriceNew !== undefined)
           ))
           || ((positionEdit?.derivative === 'FUTURES') && (
           ( !+values?.data?.newGroup?.quantityNew !== undefined)
           || (values?.data?.newGroup?.newOrderType === 'LIMIT' && !+values?.data?.newGroup?.priceNew !== undefined)
           || !+values?.data?.newGroup?.priceNewProfit !== undefined || !+values?.data?.newGroup?.stopPriceNew !== undefined
           ))
           ))

           */}
          <Button variant="primary"
                  disabled={disabled}
                  onClick={_ => onSavePosition(values, positionEdit)}>
            Да
          </Button>
          <Button variant="secondary" onClick={() => setPositionEdit(undefined)}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={orderCurrent !== undefined} order={orderCurrent} onHide={() => setOrderCurrent(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>{'Закрыть ' + orderCurrent?.derivative + ' ?'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <strong>{(orderCurrent?.symbol ? orderCurrent?.symbol + ' ' + orderCurrent?.positionSide : (' Все позиции ' + orderCurrent?.derivative))}</strong>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disabled} onClick={_ => orderClose(orderCurrent)}>
            Да
          </Button>
          <Button variant="secondary" onClick={() => orderCloseCancel()}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={groupCurrent !== undefined} group={groupCurrent} onHide={() => setGroupCurrent(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>{'Закрыть группу ?'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center><strong> #{groupCurrent?.index} {(groupCurrent?.symbol + ' ' + groupCurrent?.positionSide)}</strong></center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disabled} onClick={_ => groupCloseStart(groupCurrent)}>
            Да
          </Button>
          <Button variant="secondary" onClick={() => groupCloseCancel()}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      <CreateOrderModal
        showModal={orderNew !== undefined}
        order={orderNew}
        exchange={orderNew?.exchange}
        derivative={orderNew?.derivative}
        onHide={() => setOrderNew(undefined)}
      />
    </Container>
  );
};

export default DashboardOrders;