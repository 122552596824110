import React from 'react';
import {Button, Form, Row, Col, Table, Container} from 'react-bootstrap';
import {useAppDispatch} from '../../../../hooks/redux';
import {
  getNotifications,
} from '../../../../store/reducers/Binance/BinanceActionCreator';

import './DashboardOrders.css';

interface IProps {
  active: any;
  values?: any;
  dispatch: any;
}

interface IState {
  test: number;
  values?: any;
}

class DashboardNotificationsTab extends React.Component<IProps, IState> {

  public notifications: any;
  public values: any;
  public dispatch: any;
  public active: any;

  constructor(props: IProps) {
    super(props);
    this.dispatch = props?.dispatch;
    this.active   = props?.active;
  }

  componentDidMount() {
    console.info('DashboardNotificationsTab componentDidMount', this.props);
    this.dispatch(getNotifications({
      uid    : 1,
      mode   : 2,
      created: 3,
    })).then((r: any) => {
      console.info('notifications', r?.payload);
      this.notifications = r?.payload;
    });
  }

  render() {

    const {active} = this.props;

    return (

      // if(active && !this?.notifications){
      //   this.notifications
      // }

      <>
        <Table striped bordered hover className="m-4">
          <thead>
          <tr className="header-fixed">
            <th>Date</th>
            {/*<th>Symbol</th>*/}
            <th>Message</th>
          </tr>
          </thead>
          <tbody>
          {[...(active ? this?.notifications?.messages : []) || []]?.map((s: any, index: any) => {
            return (
              <tr className='font-medium'>
                <td className='text-nowrap'>{new Date(+s.created).toLocaleDateString()}&nbsp;{new Date(+s.created).toLocaleTimeString()}</td>
                {/*<td>{s.symbol}</td>*/}
                <td>{s.text}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </>
    );
  }

}

export default DashboardNotificationsTab;