import React, {FC, useCallback, useEffect, useState, useRef} from 'react';
import {useFormik} from 'formik';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import * as Yup from 'yup';
import './CreateOrderModal.css';

// https://fontawesome.com/docs/web/use-with/react/use-with
// import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {faSave, faTrashAlt, faPlusSquare, faHourglass} from '@fortawesome/free-regular-svg-icons';

import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import {ITakeProfit} from '../../../types/ITakeProfit';
import {IStopLoss} from '../../../types/IStopLoss';
// import {IExchangeOrderNew} from '../../../types/IExchangeOrder';
import {createOrder, prepareOrder, templateSave, getTemplate} from '../../../store/reducers/Binance/BinanceActionCreator';

interface CreateOrderModalProps {
  showModal: boolean;
  onHide: any;
  order?: any;
  derivative: string;
  exchange: string;
}

type inititalValuesType = {
  templateId: string;
  templateName: string;
  symbol: string;
  asset: any;
  valueType: any;
  leverage: number;
  leverageSpot: number;
  hedgeSpot: boolean;
  leverageError: boolean;
  derivative: string;
  orderMode: string;
  exchange: string;
  workingType: string;
  orderType: string;
  timeInForce: string;
  positionSide: string;
  type: string;
  size: number;
  price?: number;
  testMaxQty: number;
  tps: ITakeProfit[];
  sl: IStopLoss;
  tpsNumber: number;
};

const CreateOrderModal: FC<CreateOrderModalProps> = ({
                                                       onHide,
                                                       showModal,
                                                       order,
                                                       exchange,
                                                       derivative,
                                                     }) => {
  const dispatch = useAppDispatch();

  let [disabled, setDisabled]                       = useState<boolean>(false);
  let [hidden, setHidden]                           = useState<boolean>(false);
  let [prepareTimeoutStart, setPrepareTimeoutStart] = useState<number>(0);
  // let waitDelayPrepareTimer: any;
  let [template, setTemplate]                       = useState<any>({});
  let [symbolSearch, setSymbolSearch]               = useState<string>('');
  // @ts-ignore
  let [dashboard, setDashboard]                     = useState<any>([]);
  let [symbols, setSymbols]                         = useState<any>([]);
  let [symbolsSpot, setSymbolsSpot]                 = useState<any>([]);

  const {
          // isLoading,
          // stateError,
          binanceOrder,
        } = useAppSelector((state) => state.binanceReducer);

  // let [tempTps, setTempTps] = useState<any>([
  //   {
  //     offset: 1,
  //     amount: 100,
  //   } as ITakeProfit,
  // ]);

  const VALIDATION_SCHEMA = Yup.object().shape({
    symbol   : Yup.string().required('Обязательно для заполнения'),
    leverage : Yup.string().required('Обязательно для заполнения'),
    size     : Yup.string().required('Обязательно для заполнения'),
    tpsNumber: Yup.string().required('Обязательно для заполнения'),
  });

  const initialValues: inititalValuesType = {
    symbol       : binanceOrder.symbol || 'BTCUSDT',
    asset        : {},
    valueType    : binanceOrder.valueType || 'a',
    workingType  : binanceOrder.workingType || 'MARK_PRICE',
    orderType    : binanceOrder.orderType || 'MARKET',
    orderMode    : binanceOrder.orderMode || 'OCO',
    type         : binanceOrder.type || 'MARKET',
    positionSide : binanceOrder.positionSide || 'LONG',
    timeInForce  : binanceOrder.timeInForce || 'GTC',
    templateId   : binanceOrder.templateId || '',
    templateName : binanceOrder.templateName || '',
    exchange     : binanceOrder.exchange || 'BINANCE',
    derivative   : binanceOrder.derivative || 'FUTURES',
    testMaxQty   : binanceOrder.testMaxQty,
    leverage     : binanceOrder.leverage || 1,
    leverageSpot : binanceOrder.leverageSpot || 1,
    hedgeSpot    : binanceOrder.hedgeSpot || false,
    leverageError: binanceOrder.leverageError || false,
    size         : binanceOrder.size,
    price        : binanceOrder.price,
    sl           : binanceOrder.sl || {
      type      : 'USUAL',
      offset    : 1,
      offsetStop: 1,
    },
    tps          : binanceOrder?.tps || [],
    tpsNumber    : binanceOrder?.tps?.length || 1,
  };

  const {
          values,
          // handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          // touched,
          setValues,
          setFieldError,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : newOrderCreate,
  });

  const getPrepareTimeout = () => {
    let seconds = 600 - +Number((+new Date() - prepareTimeoutStart) / 1000).toFixed(0);
    let result  = +seconds > 0
                  ? Number((+seconds - (+seconds % 60)) / 60).toFixed(0) + ':' + (+seconds % 60 < 10 ? '0' : '') + +seconds % 60
                  : 0
    ;
    if ( !+seconds) {
      setPrepareTimeoutStart(0);
    }
    return result;
  };

  const getSymbolsBySearch = (search: string) => {
    _initValues();
    let sc = search?.trim()?.toUpperCase(), result = [];
    let items: any                                 = derivative === 'SPOT' ? Object.keys(symbolsSpot || {}) || [] : Object.keys(symbols || {}) || [],
        itemsDetails: any                          = derivative === 'SPOT' ? symbolsSpot || [] : symbols || [];
    items                                          = items?.sort((a: string, b: string) => a.length - b.length);
    let filtered: string[]                         = items?.filter((o: string) => ( !search || (o && o?.startsWith(sc))));
    for (const symbol of filtered) {
      let details: any = itemsDetails[symbol];
      result.push(Object.assign({
        symbol,
        ...details,
      }));
    }

    if (search && filtered?.length && ( !values?.asset || (values?.asset?.a + 'USDT') !== filtered[0])) {
      values['asset'] = itemsDetails[filtered[0]];
      // setFieldValue('asset', values['asset']);
    }

    return result;
  };

  const onSaveTemplate = () => {
    let v = JSON.parse(JSON.stringify(values)), t = JSON.parse(JSON.stringify(template));
    let m = t[derivative], isNew = checkIsNewTemplate();
    if ( !isNew) {
      m = m?.filter((o: any) => o?.templateName !== values?.templateName && o?.templateId !== values?.templateId && o?.derivative === derivative);
    }
    m                 = m?.filter((o: any) => o?.derivative === derivative);
    let id            = '' + +new Date();
    v['id']           = id;
    v['templateId']   = values.templateId = id;
    v['templateName'] = values.templateName;
    v['derivative']   = derivative;
    v['exchange']     = exchange;

    m.unshift(v);
    t[derivative] = m;
    saveTemplates(t);
  };

  const onRemoveTemplate = () => {
    let t         = JSON.parse(JSON.stringify(template));
    t[derivative] = t[derivative]?.filter((o: any) => o?.templateId !== values.templateId);
    saveTemplates(t);
  };

  const getDerivativeTemplates = () => {
    if (template && template[derivative]?.length) {
      return [...(template ? template[derivative] : [])]?.filter(o => o.derivative === derivative);
    }
    return [];
  };

  const firstRender = useRef(true);

  useEffect(() => {

    const tempTps = [
      {
        offset: 1,
        amount: 100,
      },
    ];

    if (firstRender.current) {

      firstRender.current = false;

      dispatch(getTemplate()).then((r: any) => {
        let data = JSON.parse(JSON.stringify(r?.payload));
        setTemplate(data?.template);
        setSymbols(data?.symbols);
        setSymbolsSpot(data?.symbolsSpot);
      });

      if ( !values?.tps?.length) {
        setFieldValue('tps', JSON.parse(JSON.stringify(tempTps)));
        setFieldValue('tpsNumber', 1);
      }

      setFieldValue('derivative', derivative);
      setFieldValue('exchange', exchange);

      console.info('... return');
      return;
    }

  }, [dispatch, dashboard, values, setFieldValue, derivative, exchange, values?.tps?.length]);

  function checkIsNewTemplate() {
    if ( !template || !template[derivative]?.length) {
      return true;
    }
    if (template && derivative && template[derivative]?.length) {
      return template[derivative].filter((o: any) => o?.templateId === values?.templateId && o?.templateName === values?.templateName)?.length === 0;
    }
    return false;
  }

  function _initValues() {
    if (template && template[derivative]?.length && !values?.templateId) {
      setValues(template[derivative][0]);
    }
    if ( !values?.orderType) {
      setFieldValue('orderType', 'MARKET');
    }
  }

  function calculateAmount(tps: any) {
    if (tps?.length) {
      let sum = tps?.map((o: any) => o.amount).reduce((partialSum: number, a: number) => partialSum + a, 0);
      if (sum + tps[0].amount < 100) {
        return tps[0].amount;
      } else {
        return 100 - sum;
      }
    }
    return 100;
  }

  function saveTemplates(_template: any) {
    if (_template) {
      let _newTemplate = JSON.parse(JSON.stringify(_template));
      Object.assign(template, _newTemplate);
      setTemplate(template = _newTemplate);
      console.warn('saveTemplates 2', template);
      dispatch(templateSave(template));
      console.warn('saveTemplates 3', template);
      if (_template[derivative]?.length) {
        let v = _template[derivative][0];
        setFieldValue('templateName', v.templateName);
        setFieldValue('templateId', v?.templateId);
        setValues(JSON.parse(JSON.stringify(v)));
      } else {
        // setFieldValue('templateName', null);
        setFieldValue('templateId', null);
      }
    } else {
      setFieldValue('templateId', null);
    }
  }

  function checkingOrder() {

    //Проверка суммы кол-ва ТП
    const tpAmountSum = values.tps.reduce(
      (prev, cur) => prev + cur.amount,
      0,
    );
    if (tpAmountSum !== 100 && +tpAmountSum) {
      setFieldError(
        'tpsNumber',
        'Сумма кол-ва в тейкпрофитах должна быть равна 100',
      );
      return;
    }

    //Проверка SL
    if ( !values.sl.offset) {
      setFieldError(
        'sl.offset',
        'Необходимо указать значение STOP LOSS',
      );
      return;
    }

    //Проверка SL stopPrice
    if (derivative === 'SPOT' && !values.sl.offsetStop) {
      setFieldError(
        'sl.offsetStop',
        'Необходимо указать значение stopPrice',
      );
      return;
    }

    //Проверка size
    if ( !values.size) {
      setFieldError(
        'size',
        'Необходимо указать размер позиции',
      );
      return;
    }

    //Проверка leverage
    if (derivative === 'FUTURES' && !values.leverage) {
      setFieldError(
        'leverage',
        'Необходимо указать плечо',
      );
      return;
    }

    //Проверка workingType
    if (derivative === 'FUTURES' && !values.workingType) {
      setFieldError(
        'workingType',
        'Необходимо указать значение',
      );
      return;
    }

    //Проверка symbol
    if ( !values.symbol) {
      setFieldError(
        'symbol',
        'Необходимо указать торговую пару',
      );
      return;
    }

    if ( !values?.positionSide) {
      setFieldValue('positionSide', 'LONG');
    }

    //Проверка positionSide
    if (derivative === 'FUTURES' && !values.positionSide) {
      setFieldError(
        'positionSide',
        'Необходимо указать направление',
      );
      return;
    }

    //Проверка orderType
    if ( !values.orderType) {
      setFieldError(
        'orderType',
        'Необходимо указать тип ордера',
      );
      return;
    } else if (values.orderType === 'LIMIT' && !values.price) {
      setFieldError(
        'orderType',
        'Необходимо указать цену',
      );
      return;
    }

    values['derivative'] = derivative;
    values['exchange']   = exchange;
    saveTemplates(template);
    setFieldValue('templateId', '');
  }

  // function waitDelayPrepareTimeout() {
  // clearInterval(waitDelayPrepareTimer);
  // // return new Promise((resolveWait: any) => {
  // waitDelayPrepareTimer = setInterval(async () => {
  //   console.info('prepareTimeout:', prepareTimeout);
  //   try {
  //     let time = +new Date() - prepareTimeoutStart;
  //     if (time <= 0) {
  //       // resolveWait();
  //       clearInterval(waitDelayPrepareTimer);
  //     } else {
  //       // setPrepareTimeout(prepareTimeout - 1);
  //       // console.info('prepareTimeout (b):', prepareTimeout);
  //       // console.info('prepareTimeout (c):', --prepareTimeout);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     // resolveWait();
  //     clearInterval(waitDelayPrepareTimer);
  //   }
  // }, 1000);
  // });
  // };

  async function newOrderPrepare() {
    setPrepareTimeoutStart(-1);
    checkingOrder();
    dispatch(prepareOrder(values)).then(() => {
      setPrepareTimeoutStart(+new Date());
    });
  }

  function newOrderCreate() {

    setPrepareTimeoutStart(0);
    checkingOrder();
    setDisabled(true);
    setHidden(true);

    switch (values.derivative) {
      case 'SPOT':
        values['leverage']     = 1;
        values['positionSide'] = 'LONG';
        if (values['timeInForce'] === 'GTX') {
          values['timeInForce'] = 'GTC';
        }
        break;
      case 'FUTURES':

        if (values['positionSide'] === 'LONG') {
          values['hedgeSpot'] = false;
        }
        break;
    }

    console.info('newOrderCreate', values);

    dispatch(
      createOrder(values),
    ).then(() => {
      setDisabled(false);
      setHidden(false);
      onHide();
    }).catch(() => {
      setDisabled(false);
      setHidden(false);
      console.log('edit error');
    });

  }

  // const isLocal = document.location.href.indexOf('localhost') > -1;

  const getTpsForm = useCallback(() => {
    return values.tps.map((tp, index) => {
      return (
        <Row key={`tp setting ${index}`} className="align-items-center mb-3">
          <Col
            xs={12}
            md={5}
            lg={5}
            className="justify-content-center align-items-center"
          >
            <InputGroup>
              <Form.Control
                hidden = {values.orderMode === 'OCO' || derivative === 'FUTURES'}
                aria-describedby={`tp-offset-stop-${index}`}
                value={tp.offsetStop}
                type="number"
                min={0}
                onChange={handleChange(`tps[${index}].offsetStop`)}
              />
              <Form.Control
                aria-describedby={`tp-offset-${index}`}
                value={tp.offset}
                type="number"
                min={0}
                onChange={handleChange(`tps[${index}].offset`)}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={1} lg={1}>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <InputGroup>
              <Form.Control
                aria-describedby={`tp-amount-${index}`}
                value={tp.amount}
                type="number"
                max={100}
                min={0}
                onChange={handleChange(`tps[${index}].amount`)}
              />
            </InputGroup>
          </Col>
          <Col xs={12}
               md={2}
               lg={2}
               hidden={derivative === 'SPOT' || values?.tps?.length === 1 || (index === values?.tps?.length - 1) || values.tpsNumber === 1}>
            <Form.Check
              type="switch"
              id={`breakeven-checkbox-${index}`}
              label="б/у"
              checked={(index < values?.tps?.length - 1) && (values.tpsNumber > 1) ? tp.breakeven : false}
              disabled={(() => {
                return (
                  (values.tps
                      .map((data) => data.breakeven)
                      .includes(true) &&
                    !values.tps[index].breakeven)
                  ||
                  values.sl.type === 'TRAILING'
                );
              })()}
              onChange={(e) => {
                // let tpsArr              = [...values.tps];
                // tpsArr[index].breakeven = e.target.checked;
                // setTempTps(tpsArr);
                setFieldValue(`tps[${index}].breakeven`, e.target.checked);
              }}
            />
          </Col>
        </Row>
      );
    });
  }, [values, handleChange, setFieldValue]);

  return (
    <Modal
      hidden={hidden}
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Создать ордер: {derivative}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={values?.positionSide}>
        <div className="binance-settings">
          <Row>
            <Col lg={12}>
              <Row className="template-add-update-remove-section">
                <Col lg={3}>
                  <InputGroup className="mb-3 template-item">
                    <Form.Control
                      size="sm"
                      placeholder="< template >"
                      aria-describedby="templateName"
                      value={values.templateName}
                      type="text"
                      onChange={(e) => {
                        setFieldValue('templateName', e.target.value);
                        setFieldValue('templateId', '' + +new Date());
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col lg={1}>
                  <Button
                    title="Save template"
                    // size="sm"
                    variant="light"
                    className="px-3"
                    disabled={ !values?.templateName}
                    onClick={() => onSaveTemplate()}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} className={!checkIsNewTemplate() ? 'hidden' : ''} />
                    <FontAwesomeIcon icon={faSave} className={checkIsNewTemplate() ? 'hidden' : ''} />
                  </Button>
                </Col>
                <Col lg={7}>
                  <InputGroup className="mb-3 template-item">
                    <Form.Select
                      defaultValue={''}
                      onChange={(e) => {
                        let t = template[derivative]?.filter((o: any) => o?.templateId === e.target.value);
                        if (t?.length) {
                          t = t[0];
                          if (t?.symbol) {
                            setFieldValue('symbol', t?.symbol);
                            getSymbolsBySearch(t?.symbol);
                            let v: any = document.getElementById('symbolSearch');
                            if (v) {
                              v['value'] = t?.symbol;
                              setSymbolSearch(t?.symbol);
                            }
                          }
                        }

                        setFieldValue('positionSide', t?.positionSide || 'LONG');
                        if (t?.positionSide === 'SHORT') {
                          let v: any = document.getElementById('positionSideShort');
                          if (v) {
                            v.checked = true;
                          }
                        } else {
                          let v: any = document.getElementById('positionSideLong');
                          if (v) {
                            v.checked = true;
                          }
                        }

                        setFieldValue('orderType', t?.orderType || 'MARKET');
                        if (t?.orderType === 'MARKET') {
                          let v: any = document.getElementById('orderTypeMarket');
                          if (v) {
                            v.checked = true;
                          }
                        } else {
                          let v: any = document.getElementById('orderTypeLimit');
                          if (v) {
                            v.checked = true;
                          }
                        }

                        setFieldValue('hedgeSpot', t['hedgeSpot'] = values['hedgeSpot'] = false);
                        let h: any = document.getElementById('hedgeSpot');
                        h.checked  = false;

                        setValues(JSON.parse(JSON.stringify(t)));
                      }}
                      value={values?.templateId}
                      size="sm"
                    >
                      {/*<option value=""*/}
                      {/*        key="0"*/}
                      {/*        hidden={ !checkIsNewTemplate()}*/}
                      {/*        className={!checkIsNewTemplate() ? 'hidden' : ''}*/}
                      {/*        data-ioshidden={ !checkIsNewTemplate()}*/}
                      {/*>*/}
                      {/*  + {values.templateName} ...*/}
                      {/*</option>*/}
                      {[...getDerivativeTemplates()]?.map((s, index) => {
                        return (
                          <option key={index} id={s?.id} value={s?.id}>
                            {s?.templateName}
                            &nbsp;&nbsp;
                            ( {s?.symbol}
                            | {s?.size}$*{s?.leverage}
                            | S:{s?.sl?.offset}
                            | T:
                            {s?.tps?.length > 0 ? s?.tps[0].offset : ''}
                            {s?.tps?.length > 1 ? ',' + s?.tps[1].offset : ''}
                            {s?.tps?.length > 2 ? ',' + s?.tps[2].offset : ''}
                            )
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col lg={1} className="align-right">
                  <Button
                    title="Remove template"
                    // size="sm"
                    variant="light"
                    className="px-3"
                    disabled={template && template[derivative] && !template[derivative]?.length}
                    onClick={() => onRemoveTemplate()}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row hidden={derivative === 'SPOT'}>
            <Col xs={12} md={4} lg={4}>
              <p>Направление: </p>
              {errors.positionSide && (
                <div className="input-feedback">
                  {errors.positionSide}
                </div>
              )}
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <Row>
                  <Col lg={6}>
                    <Form.Check
                      disabled={disabled}
                      className=""
                      type="radio"
                      radioGroup="positionSide"
                      name="positionSide"
                      value="LONG"
                      key="LONG"
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="positionSide"
                        name="positionSide"
                        id="positionSideLong"
                        value="LONG"
                        key="LONG"
                        type="radio"
                        defaultChecked={values?.positionSide === 'LONG' || !values?.positionSide}
                        aria-describedby="positionSide"
                        onChange={handleChange('positionSide')}
                      />
                      <Form.Check.Label>
                        LONG
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={6}>
                    <Form.Check
                      disabled={disabled}
                      className="mx-3"
                      type="radio"
                      radioGroup="positionSide"
                      name="positionSide"
                      key="SHORT"
                      value="SHORT"
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="positionSide"
                        name="positionSide"
                        id="positionSideShort"
                        key="SHORT"
                        value="SHORT"
                        type="radio"
                        defaultChecked={values?.positionSide === 'SHORT'}
                        aria-describedby="positionSide"
                        onChange={handleChange('positionSide')}
                      />
                      <Form.Check.Label>
                        SHORT
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <p>Тип ордера: </p>
              {errors.orderType && (
                <div className="input-feedback">
                  {errors.orderType}
                </div>
              )}
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <Row>
                  <Col lg={values?.orderType === 'MARKET' ? 6 : 4}>
                    <Form.Check
                      disabled={disabled}
                      className="mt-2"
                      type="radio"
                      radioGroup="orderType"
                      name="orderType"
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="orderType"
                        id="orderTypeMarket"
                        name="orderType"
                        value="MARKET"
                        key="MARKET"
                        type="radio"
                        checked={values?.orderType === 'MARKET' || !values?.orderType}
                        aria-describedby="orderType"
                        onChange={handleChange('orderType')}
                      />
                      <Form.Check.Label>
                        MARKET
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={values?.orderType === 'MARKET' ? 6 : 4}>
                    <Form.Check
                      disabled={disabled}
                      type="radio"
                      radioGroup="orderType"
                      name="orderType"
                      className={'mt-2 '}
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="orderType"
                        id="orderTypeLimit"
                        name="orderType"
                        value="LIMIT"
                        key="LIMIT"
                        type="radio"
                        checked={values?.orderType === 'LIMIT'}
                        aria-describedby="orderType"
                        onChange={handleChange('orderType')}
                      />
                      <Form.Check.Label>
                        LIMIT
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col>
                    <InputGroup hidden={values?.orderType === 'MARKET'}>
                      <Form.Control
                        placeholder="price"
                        name="price"
                        aria-describedby="price"
                        value={values.price}
                        type="number"
                        onChange={handleChange('price')}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <p title="Time in force indicates how long an order will remain active before it expires with your broker. Time in force for an option is accomplished through different order types. Common examples of time in force specifications include day order, immediate-or-cancel (IOC), fill-or-kill (FOK), or good-til-canceled (GTC).">
                Time In Force:
              </p>
              {errors.timeInForce && (
                <div className="input-feedback">
                  {errors.timeInForce}
                </div>
              )}
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <Row>
                  <Col lg={3}>
                    <Form.Check
                      disabled={disabled}
                      className="mt-2"
                      type="radio"
                      radioGroup="timeInForce"
                      name="timeInForce"
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="timeInForce"
                        id="timeInForceGtc"
                        name="timeInForce"
                        value="GTC"
                        key="GTC"
                        type="radio"
                        checked={values?.timeInForce === 'GTC' || !values?.timeInForce}
                        aria-describedby="timeInForce"
                        onChange={handleChange('timeInForce')}
                      />
                      <Form.Check.Label>
                        GTC
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={3} hidden={values?.derivative === 'SPOT'}>
                    <Form.Check
                      disabled={disabled}
                      type="radio"
                      radioGroup="timeInForce"
                      name="timeInForce"
                      className={'mt-2 '}
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="timeInForce"
                        id="timeInForceGtx"
                        name="timeInForce"
                        value="GTX"
                        key="GTX"
                        type="radio"
                        checked={values?.timeInForce === 'GTX'}
                        aria-describedby="timeInForce"
                        onChange={handleChange('timeInForce')}
                      />
                      <Form.Check.Label>
                        GTX
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={3}>
                    <Form.Check
                      disabled={disabled}
                      type="radio"
                      radioGroup="timeInForce"
                      name="timeInForce"
                      className={'mt-2 '}
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="timeInForce"
                        id="timeInForceFOK"
                        name="timeInForce"
                        value="FOK"
                        key="FOK"
                        type="radio"
                        checked={values?.timeInForce === 'FOK'}
                        aria-describedby="timeInForce"
                        onChange={handleChange('timeInForce')}
                      />
                      <Form.Check.Label>
                        FOK
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={3}>
                    <Form.Check
                      disabled={disabled}
                      type="radio"
                      radioGroup="timeInForce"
                      name="timeInForce"
                      className={'mt-2 '}
                    >
                      <Form.Check.Input
                        disabled={disabled}
                        className=""
                        radioGroup="timeInForce"
                        id="timeInForceIOC"
                        name="timeInForce"
                        value="IOC"
                        key="IOC"
                        type="radio"
                        checked={values?.timeInForce === 'IOC'}
                        aria-describedby="timeInForce"
                        onChange={handleChange('timeInForce')}
                      />
                      <Form.Check.Label>
                        IOC
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {errors.symbol && (
              <div className="input-feedback">
                {errors.symbol}
              </div>
            )}
            <Col xs={12} md={4} lg={4}>
              <p>Торговая пара: </p>
            </Col>
            <Col>
              <Row>
                <Col xs={12} md={8} lg={8}>
                  <InputGroup className="mb-3">
                    <Form.Select aria-label="symbol"
                                 name="symbol"
                                 value={values?.symbol}
                                 onChange={(e: any) => {
                                   setFieldValue('symbol', e?.target?.value);
                                   let items  = getSymbolsBySearch(e?.target?.value || '');
                                   let s: any = items?.length ? items[0] : '';
                                   console.info('asset', s);
                                   let vv: any   = values;
                                   vv[s?.symbol] = s;
                                   setFieldValue('asset', s);
                                   let v: any = document.getElementById('symbolSearch');
                                   if (v) {
                                     v['value'] = e?.target?.value;
                                   }
                                 }}
                    >
                      {getSymbolsBySearch(symbolSearch)?.map((s, index) => {
                        return (
                          <option key={index} value={s.symbol}>
                            {s.symbol}
                            {/*<span> ( &#8805;{s?.notional} )</span>*/}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="">
                    <Form.Control
                      placeholder="... search ..."
                      id="symbolSearch"
                      name="symbolSearch"
                      aria-describedby="symbolSearch"
                      defaultValue={symbolSearch}
                      type="text"
                      onChange={(e) => {
                        try {
                          setSymbolSearch(e?.target?.value || '');
                          let items  = getSymbolsBySearch(e?.target?.value || '');
                          let s: any = items?.length ? items[0] : '';
                          console.info('asset', s);
                          setFieldValue('asset', s);
                          setFieldValue('symbol', s?.symbol);
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    />
                    {/*
                     onChange={handleChange('symbolSearch')}
                     */}
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row hidden={derivative === 'SPOT' || values?.orderType === 'MARKET'}>
            <Col xs={12} md={4} lg={4}>
              <span>Тип цены:</span>
              {errors.workingType && (
                <div className="input-feedback">
                  {errors.workingType}
                </div>
              )}
            </Col>
            <Col xs={12} md={5} lg={5}>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Select onChange={handleChange('workingType')} value={values?.workingType || 'MARK_PRICE'}>
                    <option key={'MARK_PRICE'} value={'MARK_PRICE'}>MARK_PRICE</option>
                    <option key={'CONTRACT_PRICE'} value={'CONTRACT_PRICE'}>CONTRACT_PRICE</option>
                  </Form.Select>
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row hidden={derivative === 'SPOT'}>
            {/*<Col xs={12} md={4} lg={4} className={values?.leverageError ? 'leverage-error' : ''}>*/}
            <Col xs={12} md={4} lg={4}>
              <span>Плечо:</span>
              {errors?.leverage && (
                <div className="input-feedback">{errors.leverage}</div>
              )}
            </Col>
            <Col xs={12} md={3} lg={3}>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    className={values?.leverageError ? 'leverage-error' : ''}
                    aria-describedby="leverage"
                    value={values.leverage}
                    min={1}
                    max={125}
                    type="number"
                    onChange={
                      event => {
                        const value = Math.max(1, Math.min(125, Number(event.target.value)));
                        setFieldValue('leverage', value);
                        let usd: any      = values?.size * value,
                            s: any        = values?.asset?.t?.find((l: any) => usd >= l?.notionalFloor && usd < l?.notionalCap && value <= +l?.initialLeverage);
                        let leverageError = usd ? false : true;
                        setFieldValue('leverageError', values.leverageError = leverageError);
                      }
                    }
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4} className={values?.leverageError ? 'leverage-error' : ''}>
              <span>Размер позиции ({values?.asset?.b || '$'}):</span>
              {errors.size && (
                <div className="input-feedback">{errors.size}</div>
              )}
            </Col>
            <Col xs={12} md={3} lg={3}>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    className={values?.leverageError ? 'leverage-error' : ''}
                    aria-describedby="size"
                    value={values.size}
                    type="number"
                    min={1}
                    onChange={handleChange('size')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <span>Тип значения:</span>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <Form.Check
                type="switch"
                id={`valueType`}
                label={!values?.valueType ? ' %' : ' Absolute'}
                defaultChecked={values?.valueType === 'a'}
                onChange={(e) => {
                  setFieldValue('valueType', e?.target?.checked ? 'a' : '');
                }}
              />
            </Col>
          </Row>
          <hr hidden={ !values?.asset?.a || derivative === 'SPOT'}></hr>
          <Row hidden={ !values?.asset?.a || derivative === 'SPOT'}>
            <Col>
              <center>
                <h4>
                  {values?.asset?.p} {values?.asset?.b} / {values?.asset?.a}
                </h4>
              </center>
            </Col>
          </Row>
          <hr hidden={ !values?.asset?.a || derivative === 'SPOT'}></hr>
          <Row hidden={ !values?.asset?.a || derivative === 'SPOT'}>
            <Col xs={12} md={12} lg={12}>
              <Row>
                <Col xs={3} md={3} lg={3}>
                </Col>
                <Col xs={3} md={3} lg={3}>
                  <strong>Market</strong>
                </Col>
                <Col xs={3} md={3} lg={3}>
                  <strong>Limit</strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      {values?.asset?.a}:
                    </Col>
                    <Col xs={6} md={6} lg={6} className={values?.orderType === 'MARKET' ? 'border-info border-1' : ''}>
                      {values?.asset?.m?.min}
                      &nbsp;&minus;&nbsp;
                      {values?.asset?.m?.max}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      {values?.asset?.b}:
                    </Col>
                    <Col xs={6} md={6} lg={6} className={values?.orderType === 'MARKET' ? 'border-info border-1' : ''}>
                      {values?.asset?.m?.minUsdt}
                      &nbsp;&minus;&nbsp;
                      {values?.asset?.m?.maxUsdt}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col className={values?.orderType === 'LIMIT' ? 'border-info border-1' : ''}>
                      {values?.asset?.l?.min}
                      &nbsp;&minus;&nbsp;
                      {values?.asset?.l?.max}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={values?.orderType === 'LIMIT' ? 'border-info border-1' : ''}>
                      {values?.asset?.l?.minUsdt}
                      &nbsp;&minus;&nbsp;
                      {values?.asset?.l?.maxUsdt}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*      {JSON.stringify(values?.asset)}*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </Col>
          </Row>
          <hr hidden={ !values?.asset?.t?.length || derivative === 'SPOT'} />
          <Row hidden={ !values?.asset?.t?.length || derivative === 'SPOT'}>
            <Col>
              <center>
                <h4>
                  LEVERAGE
                </h4>
              </center>
            </Col>
          </Row>
          <Row className="small fw-bold border-bottom" hidden={ !values?.asset?.t?.length || derivative === 'SPOT'}>
            <Col>Уровень позиции (номинальная стоимость в USDT)</Col>
            <Col>Макс. кредитное плечо</Col>
            <Col>Ставка поддерживающей маржи</Col>
            <Col>Размер обеспечения (USDT)</Col>
          </Row>
          {values?.asset?.t?.filter((f: any) => derivative === 'FUTURES')?.map((l: any, index: number) => {
            let lv      = values?.size * values.leverage,
                lvClass = (lv >= l?.notionalFloor && lv < l?.notionalCap && +values?.leverage <= +l?.initialLeverage) ? ' leverage-hl-ok' : '';
            return (
              <Row className={'small ' + lvClass} key={index}>
                <Col>{l?.bracket}) {l?.notionalFloor} - {l?.notionalCap}</Col>
                <Col>{l?.initialLeverage}x</Col>
                <Col>{l?.maintMarginRatio * 100}%</Col>
                <Col>{l?.cum}</Col>
              </Row>
            );
          })}
          <Row hidden={derivative === 'SPOT' || values.positionSide === 'LONG' || values.orderType === 'LIMIT'}>
            <hr className="hr-double" />
            <Col xs={12} md={7} lg={7} className={'float-end'}>
              <h4 className={'float-end'}>Hedge with Spot</h4>
            </Col>
            <Col xs={12} md={5} lg={5}>
              <Form.Check
                type="switch"
                id={`hedgeSpot`}
                name={`hedgeSpot`}
                label={''}
                defaultChecked={values?.hedgeSpot}
                onChange={e => {
                  console.info('e.target.checked', e.target.checked);
                  setFieldValue('hedgeSpot', e.target.checked);
                }}
              />
            </Col>
          </Row>
          <hr className="hr-double" hidden={derivative === 'FUTURES'} />
          <Row className={'my-3'} hidden={derivative === 'FUTURES'}>
            <Col>
              <Row>
                <Col xs={4} md={4} lg={4}>
                  <Form.Check
                    type="radio"
                    name={`orderMode`}
                    id={`orderMode`}
                    label="OCO"
                    defaultChecked={values.orderMode === 'OCO'}
                    value="OCO"
                    onChange={
                      event => {
                        setFieldValue('orderMode', event.target.value);
                      }
                    }
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Form.Check
                    type="radio"
                    name={`orderMode`}
                    id={`orderMode2`}
                    label="TAKE_PROFIT_LIMIT"
                    value="TAKE_PROFIT_LIMIT"
                    defaultChecked={values.orderMode === 'TAKE_PROFIT_LIMIT'}
                    onChange={
                      event => {
                        setFieldValue('orderMode', event.target.value);
                      }
                    }
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Form.Check
                    type="radio"
                    name={`orderMode`}
                    id={`orderMode3`}
                    label="STOP_LOSS_LIMIT"
                    value="STOP_LOSS_LIMIT"
                    defaultChecked={values.orderMode === 'STOP_LOSS_LIMIT'}
                    onChange={
                      event => {
                        setFieldValue('orderMode', event.target.value);
                      }
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row hidden={derivative === 'SPOT' || !values?.hedgeSpot || values.positionSide === 'LONG' || values.orderType === 'LIMIT'}>
            <Col xs={12} md={6} lg={6}>
              <span>Плечо (1 ... 10):</span>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="leverageSpot"
                    value={values.leverageSpot}
                    min={1}
                    max={10}
                    defaultValue={1}
                    type="number"
                    disabled={true}
                    onChange={
                      event => {
                        const value = Math.max(1, Math.min(10, Number(event.target.value)));
                        setFieldValue('leverageSpot', value);
                      }
                    }
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <div className="tps" hidden={derivative === 'SPOT' && values.orderMode === 'STOP_LOSS_LIMIT'}>
            <Row>
              <Col>
                <center className="mb-4">
                  <h4>Take profit</h4>
                </center>
              </Col>
              {errors.tpsNumber && (
                <div className="input-feedback">{errors.tpsNumber}</div>
              )}
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <p>Кол-во тейкпрофитов: </p>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <Row>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby="tpsNumber"
                      value={values.tpsNumber}
                      type="number"
                      min={0}
                      max={4}
                      onChange={(e) => {
                        let tpsArr          = [...values.tps];
                        let validatedNumber = Number(e.target.value);
                        if (validatedNumber > 4) {
                          validatedNumber = 4;
                        }
                        // if (validatedNumber < 1) {
                        //   validatedNumber = 1;
                        // }

                        for (let i = 0; i < validatedNumber; i++) {
                          if (tpsArr.length === validatedNumber) {
                            break;
                          }

                          if (validatedNumber < tpsArr.length) {
                            tpsArr.pop();
                          } else {
                            let amount = calculateAmount(values.tps);
                            if (amount > 0) {
                              tpsArr.push({
                                offset    : +Number(values.tps?.length ? values.tps[0].offset * validatedNumber : 1).toFixed(5),
                                offsetStop: +Number(values.tps?.length ? values.tps[0].offset * validatedNumber : 1).toFixed(5),
                                amount    : amount,
                                breakeven : false,
                              });
                            } else {
                              validatedNumber = tpsArr.length;
                            }
                          }
                        }

                        tpsArr.length = validatedNumber;

                        setFieldValue('tpsNumber', validatedNumber);
                        setFieldValue('tps', tpsArr);
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>
              <div className="tps-settings">
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <p><span hidden={values.orderMode === 'OCO' || derivative === 'FUTURES'}>
                      Trigger {'>='}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      Value
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <p>Количество (%)</p>
                  </Col>
                  <Col></Col>
                </Row>
                {getTpsForm()}
              </div>
            </Row>
          </div>
          <hr className="hr-double" />
          <div className="sls" hidden={derivative === 'SPOT' && values.orderMode === 'TAKE_PROFIT_LIMIT'}>
            <Row>
              <Col>
                <center className="mb-4">
                  <h4>Stop Loss
                    <span hidden={derivative === 'FUTURES'}>
                    </span>
                  </h4>
                </center>
              </Col>
            </Row>
            <Row hidden={derivative === 'SPOT'}>
              <Col>
                <p>Тип:</p>
              </Col>
              <Col>
                <Form.Select
                  className="mb-3"
                  aria-label="sl-type"
                  value={values.sl.type}
                  onChange={handleChange('sl.type')}
                >
                  <option key="USUAL" value="USUAL">Обычный</option>
                  <option key="TRAILING" value="TRAILING">Трейлинг</option>
                </Form.Select>
              </Col>
            </Row>
            {values.sl.type === 'USUAL' ? (
              <>
                <Row hidden={derivative === 'FUTURES'}>
                  <Col xs={12} md={6} lg={6}>
                    <p>Trigger {'<='}</p>
                    {errors?.sl?.offsetStop && (
                      <div className="input-feedback">
                        {errors?.sl?.offsetStop}
                      </div>
                    )}
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-describedby={`sl-offset`}
                        value={values.sl.offsetStop}
                        type="number"
                        onChange={handleChange('sl.offsetStop')}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <p>Value
                      {/*<br /><sup hidden={derivative === 'FUTURES'}>(меньше Stop Loss Market)</sup>*/}
                    </p>
                    {errors?.sl?.offset && (
                      <div className="input-feedback">
                        {errors?.sl?.offset}
                      </div>
                    )}
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-describedby={`sl-offset`}
                        value={values.sl.offset}
                        type="number"
                        onChange={handleChange('sl.offset')}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            ) : (
               <div className="trailing-stoploss">
                 <Row>
                   <Col>
                     <p>Отклонение трейлинга(%):</p>
                   </Col>
                   <Col>
                     <InputGroup className="mb-3">
                       <Form.Control
                         aria-describedby="trailingOffset"
                         value={values.sl.offset}
                         type="number"
                         min={0.1}
                         max={5}
                         step={0.1}
                         onChange={handleChange('sl.offset')}
                       />
                     </InputGroup>
                   </Col>
                 </Row>
               </div>
             )}
            <hr className="hr-double" hidden={derivative === 'SPOT'} />
            <Row hidden={derivative === 'SPOT'}>
              <Col>
                <p>(Тест) max позиция ($): </p>
              </Col>
              <Col>
                <Row>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby="testMaxQty"
                      value={values.testMaxQty}
                      type="number"
                      onChange={handleChange('testMaxQty')}
                    />
                  </InputGroup>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/*<Col className="mb-4">*/}
        {/*  <Button variant="light" onClick={() => saveTemplates()}>*/}
        {/*    Сохранить templates*/}
        {/*  </Button>*/}
        {/*</Col>*/}
        <Col className="mb-1">
          <Button variant="secondary" onClick={() => {
            saveTemplates(template);
            setFieldValue('templateId', '');
            return onHide();
          }}>
            Отмена
          </Button>
        </Col>
        <Col className="mb-1 align-right flex-nowrap" xs={10} md={10} lg={10}>
          <Button variant="outline-success"
                  onClick={() => newOrderPrepare()}
                  disabled={disabled}
          >
            Подготовить
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faHourglass} className={'px-1 ' + (+prepareTimeoutStart === -1 ? ' icon-loading' : ' hidden')} />
            <span hidden={+prepareTimeoutStart <= 0}>{'( ' + getPrepareTimeout() + ' )'}</span>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant={values?.positionSide === 'LONG' ? 'success' : 'danger'}
                  onClick={() => newOrderCreate()}
                  disabled={disabled || !values?.symbol}
          >
            Открыть {derivative} {values?.positionSide} {values?.symbol} {derivative === 'FUTURES' && +values?.leverage > 1 ? 'x' + values?.leverage : ''}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateOrderModal;
