import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checkAuth, getAccountStatus, login, logout, registration } from "./UserActionCreator";
import { IUser } from "../../../types/IUser";
import { IAccountStatusResponse } from "../../../types/responses/IAccountStatusResponse";

interface UserState {
  user: IUser;
  accountStatus: IAccountStatusResponse;
  isLoading: boolean;
  isAuth: boolean;
  userError: string;
}

const initialState: UserState = {
  user: {} as IUser,
  accountStatus: {
    isBybitConnected: false,
    isBinanceConnected: false,
    isTelegramConnected: false,
  },
  isLoading: false,
  isAuth: false,
  userError: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    //Login states
    [login.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.isAuth = action.payload ? true : false;
      state.isLoading = false;
      state.userError = "";
      state.user = action.payload;
    },
    [login.pending.type]: (state) => {
      state.isLoading = true;
    },
    [login.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.userError = action.payload;
    },

    //Authentification states
    [checkAuth.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.isAuth = action.payload ? true : false;
      state.isLoading = false;
      state.userError = "";
    },
    [checkAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkAuth.rejected.type]: (state, action: PayloadAction<any>) => {
      state.user = {} as IUser;
      state.isAuth = false;
      state.isLoading = false;
    },

    //Logout states
    [logout.fulfilled.type]: (state) => {
      state.user = {} as IUser;
      state.isAuth = false;
      state.isLoading = false;
      state.userError = "";
    },
    [logout.pending.type]: (state) => {
      state.isLoading = true;
    },
    [logout.rejected.type]: (state) => {
      state.user = {} as IUser;
      state.isAuth = false;
      state.isLoading = false;
    },

    //registration states
    [registration.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.isAuth = action.payload ? true : false;
      state.isLoading = false;
      state.userError = "";
    },
    [registration.pending.type]: (state) => {
      state.isLoading = true;
    },
    [registration.rejected.type]: (state, action: PayloadAction<string>) => {
      state.user = {} as IUser;
      state.isAuth = false;
      state.isLoading = false;
      state.userError = action.payload;
    },

    //registration states
    [getAccountStatus.fulfilled.type]: (state, action: PayloadAction<IAccountStatusResponse>) => {
      state.accountStatus = action.payload;
      state.isLoading = false;
      state.userError = "";
    },
    [getAccountStatus.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAccountStatus.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

export default userSlice.reducer;
