import React, { useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getAccountStatus } from "../store/reducers/User/UserActionCreator";

import "./styles/AuthHome.css";

const AuthHome = () => {
  const dispatch = useAppDispatch();
  const { accountStatus } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getAccountStatus());
  }, [dispatch]);

  return (
    <Container>
      <div className="status-container">
        <Row>
          <Col>
            {accountStatus.isBinanceConnected ? (
              <p className="input-success">Binance аккаунт подключен</p>
            ) : (
              <p className="input-feedback">Binance аккаунт не подключен</p>
            )}
           {accountStatus.isBybitConnected ? (
              <p className="input-success">Bybit аккаунт подключен</p>
            ) : (
              <p className="input-feedback">Bybit аккаунт не подключен</p>
            )}
            {accountStatus.isTelegramConnected ? (
              <p className="input-success">Telegram аккаунт подключен</p>
            ) : (
              <p className="input-feedback">Telegram аккаунт не подключен</p>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AuthHome;
