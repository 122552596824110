import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IExchangeOrderNew} from '../../../types/IExchangeOrder';
import {RejectedWithValueAction} from '../../../types/responses/RejectedWithValueAction';
import {IExchangeAccount} from '../../../types/IExchangeAccount';
import {connect, getAccount, getDashboard, getNotifications, getTemplate, getSymbols, createOrder, setMode} from './BinanceActionCreator';

interface BinanceState {
  binanceAccount: IExchangeAccount;
  binanceOrder: IExchangeOrderNew;
  isLoading: boolean;
  stateError: string;
  dashboard: any;
  notifications: any;
  symbols: any;
  data?: any;
  template?: any;
}

const initialState: BinanceState = {
  binanceAccount: {} as IExchangeAccount,
  binanceOrder  : {} as IExchangeOrderNew,
  isLoading     : false,
  stateError    : '',
  dashboard     : {},
  notifications : {},
  data          : {},
  symbols       : [],
  template      : {},
};

export const binanceSlice = createSlice({
  name         : 'binance',
  initialState,
  reducers     : {},
  extraReducers: {
    //Binance account connect
    [connect.fulfilled.type]: (state, action: PayloadAction<IExchangeAccount>) => {
      state.binanceAccount = action.payload;
      state.stateError     = '';
      state.isLoading      = false;
    },
    [connect.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [connect.rejected.type] : (state, action: RejectedWithValueAction<string>) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    //Get Binance account
    [getAccount.fulfilled.type]: (state, action: PayloadAction<IExchangeAccount>) => {
      state.binanceAccount = action.payload;
      state.stateError     = '';
      state.isLoading      = false;
    },
    [getAccount.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getAccount.rejected.type] : (state, action: RejectedWithValueAction<string>) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    //Create order
    [createOrder.fulfilled.type]: (
      state,
      action: PayloadAction<IExchangeOrderNew>,
    ) => {
      state.stateError = '';
      state.isLoading  = false;
    },
    [createOrder.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [createOrder.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    //setMode
    [setMode.fulfilled.type]: (
      state,
    ) => {
      state.stateError = '';
      state.isLoading  = false;
    },
    [setMode.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [setMode.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    [getSymbols.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.symbols    = action.payload;
      state.stateError = '';
      state.isLoading  = false;
    },
    [getSymbols.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getSymbols.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    [getDashboard.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.dashboard  = action.payload;
      state.stateError = '';
      state.isLoading  = false;
    },
    [getDashboard.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getDashboard.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    [getNotifications.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
      state.stateError    = '';
      state.isLoading     = false;
    },
    [getNotifications.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getDashboard.rejected.type]     : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

    [getTemplate.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.data       = action.payload;
      state.stateError = '';
      state.isLoading  = false;
    },
    [getTemplate.pending.type]  : (state) => {
      state.isLoading = true;
    },
    [getTemplate.rejected.type] : (
      state,
      action: RejectedWithValueAction<string>,
    ) => {
      state.stateError = action.payload;
      state.isLoading  = false;
    },

  },
});

export default binanceSlice.reducer;