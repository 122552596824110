import React, {FC, useCallback, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import * as Yup from 'yup';

import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import TelegramSettings from '../AddChannelModal/components/TelegramSettings/TelegramSettings';
import {ITakeProfit} from '../../../types/ITakeProfit';
import {IStopLoss} from '../../../types/IStopLoss';
import {editChannel} from '../../../store/reducers/Telegram/TelegramActionCreator';

interface EditChannelModalProps {
  show: boolean;
  onHide: any;
}

type inititalValuesType = {
  telegramSettings: {
    channelName: string;
    signalWordsLong: string;
    signalWordsShort: string;
    closeWords: string;
    workingType: string;
  };
  binanceSettings: {
    leverage: number;
    leverageSpot: number;
    hedgeSpot: boolean;
    whiteList: string;
    blockList: string;
    positionSize: number;
    fundingMin: number;
    fundingMax: number;
    testMaxQty: number;
    repeatTimeLimit: number;
    tps: ITakeProfit[];
    sl: {
      offset: number;
      amount: number;
      type: string;
    };
  };
  bybitSettings: {
    leverage: number;
    leverageSpot: number;
    hedgeSpot: boolean;
    whiteList: string;
    blockList: string;
    positionSize: number;
    fundingMin: number;
    fundingMax: number;
    testMaxQty: number;
    repeatTimeLimit: number;
    tps: ITakeProfit[];
    sl: {
      offset: number;
      amount: number;
      type: string;
    };
  };
  tpsNumber: number;
};

const EditChannelModal: FC<EditChannelModalProps> = ({
                                                       onHide,
                                                       show,
                                                     }) => {
  const dispatch = useAppDispatch();
  const {
          channels,
          isLoading: isTelegramLoading,
          telegramError,
          editChannel: channelData,
        }        = useAppSelector((state) => state.telegramReducer);

  let [tempTps, setTempTps] = useState<any>([
    {
      offset: 1,
      amount: 100,
    } as ITakeProfit,
  ]);

  const VALIDATION_SCHEMA = Yup.object().shape({
    telegramChannelTitle: Yup.string().required('Обязательно для заполнения'),
    leverage            : Yup.string().required('Обязательно для заполнения'),
    positionSize        : Yup.string().required('Обязательно для заполнения'),
    fundingMin          : Yup.string().required('Обязательно для заполнения'),
    fundingMax          : Yup.string().required('Обязательно для заполнения'),
    repeatTimeLimit     : Yup.string().required('Обязательно для заполнения'),
    tpsNumber           : Yup.string().required('Обязательно для заполнения'),
  });

  const initialValues: inititalValuesType = {
    telegramSettings: {
      channelName     : channelData.telegramSettings.channelName,
      signalWordsLong : channelData.telegramSettings.signalWordsLong,
      signalWordsShort: channelData.telegramSettings.signalWordsShort,
      closeWords      : channelData.telegramSettings.closeWords,
      workingType     : channelData.telegramSettings.workingType,
    },
    binanceSettings : {
      whiteList      : channelData.binanceSettings.whiteList || '',
      blockList      : channelData.binanceSettings.blockList || '',
      fundingMin     : channelData.binanceSettings.fundingMin || -5,
      fundingMax     : channelData.binanceSettings.fundingMax || 5,
      testMaxQty     : channelData.binanceSettings.testMaxQty || 0,
      repeatTimeLimit: channelData.binanceSettings.repeatTimeLimit || 0,
      leverage       : channelData.binanceSettings.leverage,
      leverageSpot   : channelData.binanceSettings.leverageSpot,
      hedgeSpot      : channelData.binanceSettings.hedgeSpot,
      positionSize   : channelData.binanceSettings.positionSize,
      sl             : channelData.binanceSettings.sl,
      tps            : JSON.parse(JSON.stringify(channelData.binanceSettings.tps)),
    },
    tpsNumber       : channelData.binanceSettings.tps.length,
    bybitSettings   : {
      whiteList      : channelData.bybitSettings.whiteList || '',
      blockList      : channelData.bybitSettings.blockList || '',
      fundingMin     : channelData.bybitSettings.fundingMin || -5,
      fundingMax     : channelData.bybitSettings.fundingMax || 5,
      testMaxQty     : channelData.bybitSettings.testMaxQty || 0,
      repeatTimeLimit: channelData.bybitSettings.repeatTimeLimit || 0,
      leverage       : channelData.bybitSettings.leverage,
      leverageSpot   : channelData.bybitSettings.leverageSpot,
      hedgeSpot      : channelData.bybitSettings.hedgeSpot,
      positionSize   : channelData.bybitSettings.positionSize,
      sl             : channelData.bybitSettings.sl,
      tps            : JSON.parse(JSON.stringify(channelData.bybitSettings.tps)),
    },
  };

  const {
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
          setValues,
          setFieldError,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : saveChannelSettingsHandle,
  });

  useEffect(() => {
    if ( !values?.binanceSettings?.tps) {
      setFieldValue('binanceSettings.tps', tempTps);
    }
  }, [dispatch, setFieldValue, tempTps]);

  function calculateAmount(tps: any) {
    if (tps?.length) {
      let sum = tps?.map((o: any) => o.amount).reduce((partialSum: number, a: number) => partialSum + a, 0);
      if (sum + tps[0].amount < 100) {
        return tps[0].amount;
      } else {
        return 100 - sum;
      }
    }
    return 100;
  }

  function saveChannelSettingsHandle() {
    //Проверка заполнения сигнальных слов
    if (
      values.telegramSettings.signalWordsLong === '' &&
      values.telegramSettings.signalWordsShort === ''
    ) {
      setFieldError(
        'telegramSettings.signalWordsLong',
        'Одно из сигнальных полей должно быть заполнено!',
      );
      setFieldError(
        'telegramSettings.signalWordsShort',
        'Одно из сигнальных полей должно быть заполнено!',
      );
      return;
    }

    //Проверка суммы кол-ва ТП
    const tpAmountSum = values.binanceSettings.tps.reduce(
      (prev, cur) => prev + cur.amount,
      0,
    );
    if (tpAmountSum !== 100 && +tpAmountSum) {
      setFieldError(
        'tpsNumber',
        'Сумма кол-ва в тейкпрофитах должна быть равна 100',
      );
      return;
    }

    //Проверка SL
    if ( !values.binanceSettings.sl.offset) {
      setFieldError(
        'binanceSettings.sl.offset',
        'Необходимо указать значение STOP LOSS',
      );
      return;
    }

    dispatch(
      editChannel({
        binanceSettings : values.binanceSettings,
        bybitSettings   : values.bybitSettings || {
          tps: [],
          sl : {
            offset: 1,
            amount: 100,
          },
        },
        telegramSettings: values.telegramSettings,
      }),
    ).then(() => {
      onHide();
    }).catch(() => {
      console.log('edit error');

    });
  }

  const getTpsForm = useCallback(() => {
    return values.binanceSettings.tps.map((tp, index) => {
      return (
        <Row key={`tp setting ${index}`} className="align-items-center mb-3">
          <Col
            xs={12}
            md={5}
            lg={5}
            className="justify-content-center align-items-center"
          >
            <InputGroup>
              <Form.Control
                aria-describedby={`tp-offset-${index}`}
                value={tp.offset}
                type="number"
                min={0}
                onChange={(e) => {
                  let tpsArr              = [...values.binanceSettings.tps];
                  // tpsArr[index].offset = Number(e.target.value);
                  tpsArr[index]['offset'] = e.target.value;
                  setTempTps(tpsArr);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={5} lg={5}>
            <InputGroup>
              <Form.Control
                aria-describedby={`tp-amount-${index}`}
                value={tp.amount}
                type="number"
                max={100}
                min={0}
                onChange={(e) => {
                  let tpsArr           = [...values.binanceSettings.tps];
                  tpsArr[index].amount = Number(e.target.value);
                  setTempTps(tpsArr);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={2} lg={2}>
            <Form.Check
              type="switch"
              id={`breakeven-checkbox-${index}`}
              label="б/у"
              checked={tp.breakeven}
              disabled={(() => {
                return (
                  (values.binanceSettings.tps
                      .map((data) => data.breakeven)
                      .includes(true) &&
                    !values.binanceSettings.tps[index].breakeven) ||
                  values.binanceSettings.sl.type === 'TRAILING' ||
                  values.tpsNumber === 1
                );
              })()}
              onChange={(e) => {
                let tpsArr              = [...values.binanceSettings.tps];
                tpsArr[index].breakeven = e.target.checked;
                setTempTps(tpsArr);
              }}
            />
          </Col>
        </Row>
      );
    });
  }, [values]);

  // @ts-ignore
  // @ts-ignore
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Редактирование настроек Telegram канала
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <h2>Telegram-Binance торговля</h2>
        </Row>
        <div className="telegram-settigns">
          <Row>
            <Col>
              <h4>Настройка Telegram</h4>
              <p>
                Добавьте канал для торговли по его сигналам на бирже Binance
              </p>
              <p>
                Робот ищет сигнальные слова в сообщении канала для
                покупки/продажи(Например, LONG ✳️, SHORT 🛑). Монету определяет
                сам исходя из представленного списка на Binance Futures.
              </p>
              <p>
                Для перечисления нескольких вариантов сигнальных слов
                используйте ; без пробелов
              </p>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row>
            {errors.telegramSettings?.channelName && (
              <div className="input-feedback">
                {errors.telegramSettings?.channelName}
              </div>
            )}
            <Col>
              <p>Название канала: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="telegramChannelTitle"
                    value={values.telegramSettings.channelName}
                    disabled={true}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            {errors.telegramSettings?.workingType && (
              <div className="input-feedback">
                {errors.telegramSettings?.workingType}
              </div>
            )}
            <Col>
              <p>Тип цены: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Select onChange={handleChange('telegramSettings.workingType')}
                               value={values?.telegramSettings?.workingType || 'MARK_PRICE'}>
                    <option>MARK_PRICE</option>
                    <option>CONTRACT_PRICE</option>
                  </Form.Select>
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            {errors.telegramSettings?.signalWordsLong && (
              <div className="input-feedback">
                {errors.telegramSettings?.signalWordsLong}
              </div>
            )}
            <Col>
              <p>Сигнальные слова лонг: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="telegramSettings.signalWordsLong"
                    value={values.telegramSettings.signalWordsLong}
                    onChange={handleChange('telegramSettings.signalWordsLong')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            {errors.telegramSettings?.signalWordsShort && (
              <div className="input-feedback">
                {errors.telegramSettings.signalWordsShort}
              </div>
            )}
            <Col>
              <p>Сигнальные слова шорт: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="signalWordsShort"
                    value={values.telegramSettings.signalWordsShort}
                    onChange={handleChange('telegramSettings.signalWordsShort')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            {errors.telegramSettings?.closeWords && (
              <div className="input-feedback">
                {errors.telegramSettings?.closeWords}
              </div>
            )}
            <Col>
              <p>Сигнальные слова закрытия позиции: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="closeWords"
                    value={values.telegramSettings.closeWords}
                    onChange={handleChange('telegramSettings.closeWords')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="binance-settings">
          <Row>
            <Col>
              <h4>Настройка Binance</h4>
              <p>Торговля производится только в режиме хэджирования</p>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row className={values.binanceSettings.blockList && !values.binanceSettings.whiteList ? 'hiding' : ''}>
            <Col>
              <p>Whitelist: <span className="hint">(BTCUSDT, ETHUSDT, ...)</span></p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="whiteList"
                    value={values.binanceSettings.whiteList}
                    type="text"
                    onChange={handleChange('binanceSettings.whiteList')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row className={values.binanceSettings.whiteList && !values.binanceSettings.blockList ? 'hiding' : ''}>
            <Col>
              <p>Blocklist: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="blockList"
                    value={values.binanceSettings.blockList}
                    type="text"
                    onChange={handleChange('binanceSettings.blockList')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row>
            <Col>
              <p>Плечо:</p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="leverage"
                    value={values.binanceSettings.leverage}
                    min={1}
                    max={125}
                    type="number"
                    onChange={handleChange('binanceSettings.leverage')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Размер позиции: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="positionSize"
                    value={values.binanceSettings.positionSize}
                    type="number"
                    min={1}
                    onChange={handleChange('binanceSettings.positionSize')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Cтавка финансирования (диапазон, %): </p>
            </Col>
            <Col>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Form.Control
                    aria-describedby="fundingMin"
                    value={values.binanceSettings.fundingMin}
                    type="number"
                    min={-1.0}
                    max={1.0}
                    step="0.01"
                    onChange={handleChange('binanceSettings.fundingMin')}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Form.Control
                    aria-describedby="fundingMax"
                    value={values.binanceSettings.fundingMax}
                    type="number"
                    min={-1.0}
                    max={1.0}
                    step="0.01"
                    onChange={handleChange('binanceSettings.fundingMax')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Ограничение для повторных сделок (минут): </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="repeatTimeLimit"
                    value={values.binanceSettings.repeatTimeLimit}
                    type="number"
                    min={1}
                    step="1"
                    onChange={handleChange('binanceSettings.repeatTimeLimit')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <div className="tps">
            <Row>
              <Col>
                <h5>Тейкпрофиты</h5>
              </Col>
              {errors.tpsNumber && (
                <div className="input-feedback">{errors.tpsNumber}</div>
              )}
            </Row>
            <Row>
              <Col xs={12} md={5} lg={5}>
                <p>Кол-во тейкпрофитов: </p>
              </Col>
              <Col xs={12} md={5} lg={5}>
                <Row>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby="tpsNumber"
                      value={values.tpsNumber}
                      type="number"
                      min={0}
                      max={4}
                      onChange={(e) => {
                        let tpsArr          = [...values.binanceSettings.tps];
                        let validatedNumber = Number(e.target.value);
                        if (validatedNumber > 4) {
                          validatedNumber = 4;
                        }
                        // if (validatedNumber < 1) {
                        //   validatedNumber = 1;
                        // }

                        for (let i = 0; i < validatedNumber; i++) {
                          if (tpsArr.length === validatedNumber) {
                            break;
                          }

                          if (validatedNumber < tpsArr.length) {
                            tpsArr.pop();
                          } else {
                            let amount = calculateAmount(values.binanceSettings.tps);
                            if (amount > 0) {
                              tpsArr.push({
                                offset   : +Number(values.binanceSettings.tps?.length ? values.binanceSettings.tps[0].offset * validatedNumber : 1).toFixed(5),
                                amount   : amount,
                                breakeven: false,
                              });
                            } else {
                              validatedNumber = tpsArr.length;
                            }
                          }
                        }

                        tpsArr.length = validatedNumber;
                        console.log(tpsArr);

                        setFieldValue('tpsNumber', validatedNumber);
                        setFieldValue('binanceSettings.tps', tpsArr);
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>
              <div className="tps-settings">
                <Row>
                  <Col xs={12} md={5} lg={5}>
                    <p>Отклонение (%)</p>
                  </Col>
                  <Col xs={12} md={7} lg={7}>
                    <p>Количество (%)</p>
                  </Col>
                  <Col></Col>
                </Row>
                {getTpsForm()}
              </div>
            </Row>
          </div>
          <hr className="hr-double" />
          <div className="sls">
            <Row>
              <Col>
                <h5>Стоплосс</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Тип:</p>
              </Col>
              <Col>
                <Form.Select
                  className="mb-3"
                  aria-label="sl-type"
                  value={values.binanceSettings.sl.type}
                  onChange={handleChange('binanceSettings.sl.type')}
                >
                  <option value="USUAL">Обычный</option>
                  <option value="TRAILING">Трейлинг</option>
                </Form.Select>
              </Col>
            </Row>
            <hr />
            {values.binanceSettings.sl.type === 'USUAL' ? (
              <Row>
                <Col>
                  <p>Отклонение (%)</p>
                  {errors?.binanceSettings?.sl?.offset && (
                    <div className="input-feedback">
                      {errors?.binanceSettings?.sl?.offset}
                    </div>
                  )}
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby={`sl-offset`}
                      value={values.binanceSettings.sl.offset}
                      type="number"
                      onChange={handleChange('binanceSettings.sl.offset')}
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : (
               <div className="trailing-stoploss">
                 <Row>
                   <Col>
                     <p>Отклонение трейлинга(%):</p>
                   </Col>
                   <Col>
                     <InputGroup className="mb-3">
                       <Form.Control
                         aria-describedby="trailingOffset"
                         value={values.binanceSettings.sl.offset}
                         type="number"
                         min={0.1}
                         max={5}
                         step={0.1}
                         onChange={handleChange('binanceSettings.sl.offset')}
                       />
                     </InputGroup>
                   </Col>
                 </Row>
               </div>
             )}
            <hr className="hr-double" />
            <Row>
              <Col>
                <p>(Тест) max позиция ($): </p>
              </Col>
              <Col>
                <Row>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby="testMaxQty"
                      value={values.binanceSettings.testMaxQty}
                      type="number"
                      onChange={handleChange('binanceSettings.testMaxQty')}
                    />
                  </InputGroup>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => saveChannelSettingsHandle()}>
          Сохранить
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditChannelModal;
