import {AxiosResponse} from 'axios';

import $api from '../http';
import {IChannel} from '../types/IChannel';
import {ITelegramAccount} from '../types/ITelegramAccount';
import {IChannelRequest} from '../types/requests/IChannelRequest';
import {TelegramConnectionResponse} from '../types/responses/TelegramConnectionResponse';

export default class TelegramService {
  static async connect(
    apiId: string,
    apiHash: string,
    phoneNumber: string,
  ): Promise<AxiosResponse<{ status: string }>> {
    return $api.post<{ status: string }>('/telegram/connect', {
      apiId,
      apiHash,
      phoneNumber,
    });
  }

  static async sendCode(
    apiId: string,
    apiHash: string,
    phoneNumber: string,
    authCode: string,
  ): Promise<AxiosResponse<TelegramConnectionResponse>> {
    return $api.post<TelegramConnectionResponse>('/telegram/code', {
      apiId,
      apiHash,
      phoneNumber,
      authCode,
    });
  }

  static async getAccount(): Promise<AxiosResponse<ITelegramAccount>> {
    return $api.get<ITelegramAccount>('/telegram/getAccount');
  }

  static async getChannels(): Promise<AxiosResponse<IChannel[]>> {
    return $api.get<IChannel[]>('/trading/getChannels');
  }

  static async addChannel(
    data: IChannelRequest,
  ): Promise<AxiosResponse<IChannel[]>> {
    return $api.post<IChannel[]>('/trading/addChannel', data);
  }

  static async deleteChannel(
    channelName: string,
  ): Promise<AxiosResponse<IChannel[]>> {
    return $api.post<IChannel[]>('/trading/deleteChannel', {channelName});
  }

  static async editChannel(
    data: any,
  ): Promise<AxiosResponse<IChannel[]>> {
    return $api.post<IChannel[]>('/trading/editChannel', data);
  }

  static async getTradeHistory(period: any): Promise<AxiosResponse<any>> {
    return (
      $api.get<any>('/trading/getTradingHistory?period=' + period, {
        responseType: 'stream',
        // responseEncoding: 'BINARY',
        // headers: {
        //   'Content-Disposition': '1.xls',
        //   'Content-Type': 'application/vnd.ms-excel',
        //   'Content-Type': 'plain/text'
        // },
      })
      // .then(r => {
      //   console.log(r);
      // })
      // .catch(_ => {
      //   console.log(2);
      // })
    );
  }

}
