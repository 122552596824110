import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { checkAuth } from "./store/reducers/User/UserActionCreator";
import { Pages } from "./pages/index";
import AuthLayout from "./pages/AuthLayout";
import AuthHome from "./modules/AuthHome";
import Connect from "./modules/Connect";
import Telegram from "./modules/TelegramTraiding";
import Statistics from "./modules/Statistics";
import Dashboard from './modules/Dashboard';

import "./App.css";
function App() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  if (isAuth) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route path="/" element={<AuthHome />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/connect" element={<Connect />} />
              <Route path="/telegram" element={<Telegram />} />
              <Route path="/statistics/:period" element={<Statistics />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Pages.Login />} />
          <Route path="/signup" element={<Pages.Registration />} />
          <Route path="/" element={<Navigate to="/login" />}>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
