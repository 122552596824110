import { AxiosResponse } from "axios";
import $api from "../http";
import { AuthResponse } from "../types/responses/AuthResponse";
import { IAccountStatusResponse } from "../types/responses/IAccountStatusResponse";

export default class AuthService {
    static async login(
        email: string,
        password: string
    ): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>("/login", { email, password });
    }

    static async registration(
        email: string,
        password: string
    ): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>("/registration", { email, password });
    }

    static async logout(): Promise<AxiosResponse<AuthResponse>> {
        return $api.get<AuthResponse>("/logout");
    }

    static async getAccountStatus(): Promise<AxiosResponse<IAccountStatusResponse>> {
        return $api.get<IAccountStatusResponse>("/trading/getAccountStatus");
    }
}