import {AxiosResponse} from 'axios';
import $api from '../http';
import {IExchangeAccount} from '../types/IExchangeAccount';
import {IExchangeOrderBase, IExchangeOrderNew} from '../types/IExchangeOrder';

export default class BybitService {
  static async connect(
    key: string,
    secret: string,
  ): Promise<AxiosResponse<IExchangeAccount>> {
    return $api.post<IExchangeAccount>('/bybit/connect', {
      key,
      secret,
    });
  }

  static async getAccount(): Promise<AxiosResponse<IExchangeAccount>> {
    return $api.get<IExchangeAccount>('/bybit/getAccount');
  }

  static async getDashboard(part: any): Promise<AxiosResponse<any>> {
    return $api.get<any>('/bybit/getDashboard?part=' + part);
  }

  static async transferFunds(item: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/bybit/transferFunds', item);
  }

  static async savePosition(item: any): Promise<AxiosResponse<any>> {
    return $api.post<IExchangeOrderBase>('/bybit/savePosition', item);
  }

  static async groupClose(group: any): Promise<AxiosResponse<IExchangeOrderBase>> {
    return $api.post<IExchangeOrderBase>('/bybit/groupClose', group);
  }

  static async orderClose(order: IExchangeOrderBase): Promise<AxiosResponse<IExchangeOrderBase>> {
    return $api.post<IExchangeOrderBase>('/bybit/orderClose', order);
  }

  static async orderCreate(order: IExchangeOrderNew): Promise<AxiosResponse<any>> {
    return $api.post<any>('/bybit/orderCreate', order);
  }

  static async templateSave(data: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/bybit/templateSave', data);
  }

  static async getTemplate(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/bybit/getTemplate');
  }

  static async getSymbols(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/bybit/getSymbols');
  }

}