import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {getAccount} from '../../../store/reducers/Bybit/BybitActionCreator';
import {Row, Button, Col} from 'react-bootstrap';
import APITable from '../../../components/common/APITable/APITable';
import ConnectBybitModal from '../../../components/modals/ConnectBybitModal/ConnectBybitModal';
import './BybitConnect.css';

const BybitConnect = () => {
  const dispatch                  = useAppDispatch();
  const {bybitAccount}            = useAppSelector((state) => state.bybitReducer);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  return (
    <div className="connect-wrapper">
      <div className="module-header">
        <Row>
          <Col className="">
            <h3>BYBIT</h3>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button onClick={() => setShowModal(true)}>
              Добавить ключ API
            </Button>
          </Col>
        </Row>
        <hr />
      </div>
      <APITable
        data={{
          'api key'   : bybitAccount.key,
          'api secret': bybitAccount.secret,
        }}
      />
      <ConnectBybitModal showModal={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default BybitConnect;