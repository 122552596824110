import React, {FC} from 'react';

import {Table, Row, Col} from 'react-bootstrap';
import './APITable.css';

interface APITableProps {
  data: {
    [key: string]: any;
  };
}

const APITable: FC<APITableProps> = ({data}) => {
  return (
    <Table striped hover>
      {Object.keys(data).map((dataKey, index, arr) => {
        if (
          arr.length % 2 !== 0 &&
          index === arr.length - 1 &&
          arr.length > 2
        ) {
          return (
            <Row key={`${dataKey} ${index}`}>
              <Col>
                <p>
                  <strong>{String(dataKey).toUpperCase()}:</strong> &nbsp;&nbsp; {data[dataKey]}
                </p>
              </Col>
              <Col></Col>
            </Row>
          );
        }

        if ((index + 1) % 2 === 0) {
          return (
            <Row key={`${dataKey} ${index}`}>
              <Col>
                <p>
                  <strong>{String(arr[index - 1]).toUpperCase()}:</strong> &nbsp;&nbsp; {data[arr[index - 1]]}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>{String(dataKey).toUpperCase()}:</strong> &nbsp;&nbsp; {data[dataKey]}
                </p>
              </Col>
            </Row>
          );
        } else {
          return <></>;
        }
      })}
    </Table>
  );
};

export default APITable;
