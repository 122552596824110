import React, {useEffect, useState} from 'react';
import {Nav, Table} from 'react-bootstrap';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../hooks/redux';
import {getTradeHistory} from '../store/reducers/Telegram/TelegramActionCreator';
import './styles/Common.css';

const Statistics = () => {

  let {period}                                = useParams<'period'>();
  const dispatch                              = useAppDispatch();
  const {tradeHistory}                        = useAppSelector((state) => state.telegramReducer);
  let [showPositions, setShowPositions]       = useState<any>(undefined);
  // let [showGroups, setShowGroups]             = useState<any>(undefined);
  let [showOrders, setShowOrders]             = useState<any>(undefined);
  let [showOrderHistory, setShowOrderHistory] = useState<any>(undefined);

  const fin = (x: any, defaultValue: any = 0, digits: any = 4) => {
    return +x ? +Number.parseFloat(x).toFixed(digits) : (defaultValue ? +Number.parseFloat(defaultValue).toFixed(digits) : '');
  };

  useEffect(() => {
    dispatch(getTradeHistory(period));
  }, [dispatch, period]);

  // let groups: any = {};
  // for (const th of tradeHistory) {
  //   groups[th.groupId] = {
  //     groupId        : th.groupId,
  //     channelName    : th.channelName,
  //     symbol         : th.symbol,
  //     positionSide   : th.positionSide,
  //     created        : th.created,
  //     minPrice       : Math.min(th.lastFilledPrice, groups[th.groupId]?.minPrice || th.lastFilledPrice),
  //     maxPrice       : Math.max(th.lastFilledPrice, groups[th.groupId]?.maxPrice || th.lastFilledPrice),
  //     quantity       : (groups[th.groupId]?.quantity || 0) + th.quantity,
  //     profit         : (groups[th.groupId]?.profit || 0) + th.profit,
  //     commission     : (groups[th.groupId]?.commission || 0) + th.commission,
  //     commissionAsset: th.commissionAsset,
  //   };
  // }
  // for (let groupId of Object.keys(groups)) {
  //   let g                    = groups[groupId];
  //   groups[groupId]['items'] = tradeHistory?.filter((th: any) => th.groupId === groupId);
  // }

  return (
    <Table striped bordered hover className="nav-black">
      <thead>
      <tr>
        <th colSpan={16}>
          <Nav>
            Показать:
            <Nav.Item>
              <Link to="/statistics/today">Сегодня</Link>
            </Nav.Item>
            |
            <Nav.Item>
              <Link to="/statistics/yesterday">Вчера</Link>
            </Nav.Item>
            |
            <Nav.Item>
              <Link to="/statistics/all">Все</Link>
            </Nav.Item>
            |
            <Nav.Item>
              <Link to="/statistics/xls">Скачать</Link>
            </Nav.Item>
          </Nav>
        </th>
      </tr>
      </thead>
      {[...Object.values(tradeHistory)].map((position: any, index) => {

        const t2 = (v: any) => {
          return +v < 10 ? '0' + v : v;
        };

        const d = (_d: any) => {
          let d = _d ? new Date(_d) : undefined;
          return d ? d.getDate() + '/' + t2(d.getMonth()) + '/' + (d.getFullYear() - 2000) + ' ' + d.getHours() + ':' + t2(d.getMinutes()) : undefined;
        };

        const dc = d(position?.created);
        const du = d(position?.updated);

        return (
          <>
            <thead>
            <tr className={index === 0 ? '' : 'hidden'}>
              <th colSpan={3}></th>
              <td>Тип</td>
              <td>Дата</td>
              <td>Канал</td>
              <td>Символ</td>
              <td>Направление</td>
              <td>Плечо</td>
              <td>Цена</td>
              <td>Объём</td>
              <td>Комиссия</td>
              <td>Профит (USDT)</td>
            </tr>
            <tr className={'fw-bold'}>
              <th colSpan={3}>
                <center>
                  {index + 1}
                  &nbsp;&nbsp;
                  <button className="btn-groups-toggle"
                          disabled={ !position?.groups?.length}
                          onClick={() => { return setShowPositions(showPositions === position?._id ? undefined : position?._id); }}
                  >
                    {position?.groups?.length && position?._id && showPositions === position?._id ? '-' : '+'}
                  </button>
                </center>
              </th>
              <td>{position.derivative}</td>
              <td className={'nobr'}>
                {dc} {du ? ' - ' : ''} {du}
              </td>
              <td>{position.channelName}</td>
              <td>{position.symbol}</td>
              <td className={position?.positionSide === 'LONG' ? 'c-long' : 'c-short'}>{position.positionSide}</td>
              <td>{position.leverage}</td>
              <td>{position?.minPrice} ... {position?.maxPrice}</td>
              <td>{fin(position?.quantity, '', 5)}</td>
              <td>{fin(position?.commission, '', 6)} {position?.commission ? position?.commissionAsset || 'USDT' : ''}</td>
              <td className={+position?.profit >= 0 ? 'pnl-plus' : 'pnl-minus'}>{fin(position.profit, '')}</td>
              <td>{position.closed === true ? 'CLOSED' : ''}</td>
            </tr>
            </thead>
            <tbody className={position?.groups?.length && position?._id && showPositions === position?._id ? '' : 'hidden'}>
            {/*<tr>*/}
            {/*  <td></td>*/}
            {/*  <th>#</th>*/}
            {/*  <th>Создание*/}
            {/*    <br /> / Обновление*/}
            {/*    <br /> / Закрытие*/}
            {/*  </th>*/}
            {/*  <th>Канал</th>*/}
            {/*  <th>Символ</th>*/}
            {/*  <th>Направление</th>*/}
            {/*  <th>Цена</th>*/}
            {/*  <th>Объём</th>*/}
            {/*  <th>Комиссия</th>*/}
            {/*  <th>Профит (USDT)</th>*/}
            {/*  /!*<th>Статус</th>*!/*/}
            {/*  <th>Закрыт</th>*/}
            {/*</tr>*/}
            {[...position?.groups]?.map((group, index) => {
              return (
                <>
                  <tr>
                    <td rowSpan={2}></td>
                    <td rowSpan={2} className="td-group-block">
                      <center>{index + 1}</center>
                    </td>
                    <td rowSpan={2}>
                      {/*<br/>*/}
                      &nbsp;&nbsp;
                      <button className="btn-orders-toggle"
                              disabled={ !group?.orders?.length}
                              onClick={() => { return setShowOrders(showOrders === group.clientOrderId ? undefined : group.clientOrderId); }}
                      >
                        {group?.orders?.length && group.clientOrderId && showOrders === group.clientOrderId ? '-' : '+'}
                      </button>
                    </td>
                    <td rowSpan={2} className={'nobr'}>
                      {new Date(group.created).toLocaleDateString() + ' ' + new Date(group.created).toLocaleTimeString()}
                      <br />{group?.updated ? new Date(group.updated).toLocaleDateString() + ' ' + new Date(group.updated).toLocaleTimeString() : ''}
                      <br />{group?.timestampClose ? new Date(group.timestampClose).toLocaleDateString() + ' ' + new Date(group.timestampClose).toLocaleTimeString() : ''}
                    </td>
                    <td colSpan={5}>
                      {/*{group.channelName}*/}
                    </td>
                    {/*<td>*/}
                    {/*{group.symbol}*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*{group.positionSide}*/}
                    {/*</td>*/}
                    <td className="align-right font-small">{+group.lastFilledPrice || +group.price}</td>
                    <td className="align-right font-small">{fin(group.quantity, '', 5)}</td>
                    <td className="align-right font-small">{group.commission} {/*group.commissionAsset*/}</td>
                    <td className="align-right font-small">{fin(group.profit, '', 6)}</td>
                    {/*<td>{group.status}</td>*/}
                    <td>{group.closed === true ? 'CLOSED' : ''}</td>
                  </tr>
                  <tr>
                    {/*<td colSpan={1}></td>*/}
                    <td colSpan={7} className={'order-td-info ' + (!group.info ? 'hidden' : '')}>
                      <div className="div-description">
                        {group.info}
                      </div>
                    </td>
                  </tr>
                  <tr className={group?.orders?.length && group.clientOrderId && showOrders === group.clientOrderId ? '' : 'hidden'}>
                    <th></th>
                    <th colSpan={12}>
                      <Table hover className={'tbl-plain-text'}>
                        <thead>
                        <tr>
                          <th colSpan={12} className="centered">
                            СПИСОК ОРДЕРОВ
                          </th>
                        </tr>
                        <tr>
                          <th>#</th>
                          <th>Создание
                            <br /> / Обновление
                            <br /> / Закрытие
                          </th>
                          <th>Ордер</th>
                          <th>Тип</th>
                          <th>Направление</th>
                          <th>Цена</th>
                          <th>Объём</th>
                          <th>Комиссия</th>
                          <th>Профит (USDT)</th>
                          <th>Б/У</th>
                          <th>Статус</th>
                          <th>Закрыт</th>
                        </tr>
                        </thead>
                        <tbody>
                        {group?.orders?.map(function (order: any, index2: number) {
                          return (
                            <>
                              <tr className={order?.type === 'MARKET' && order?.main === true ? 'row-order-main' : ''}>
                                <td rowSpan={2}>
                                  {index2 + 1}
                                  &nbsp;&nbsp;
                                  <button className="btn-orders-toggle"
                                          disabled={ !order?.statuses?.length}
                                          onClick={() => { return setShowOrderHistory(showOrderHistory === order._id ? undefined : order._id); }}
                                  > ...
                                  </button>
                                </td>
                                <td rowSpan={2}>
                                  {new Date(order.created).toLocaleDateString() + ' ' + new Date(order.created).toLocaleTimeString()}
                                  <br /> {order?.timestamp ? new Date(order.timestamp).toLocaleDateString() + ' ' + new Date(order.timestamp).toLocaleTimeString() : ''}
                                  <br /> {order?.timestampClose ? new Date(order.timestampClose).toLocaleDateString() + ' ' + new Date(order.timestampClose).toLocaleTimeString() : ''}
                                </td>
                                <td>{order?.orderId} {order?.orderId2 ? ', ' + order?.orderId2 : ''}</td>
                                <td>{order?.type} {order.breakeven ? ' (Б/У)' : ''}</td>
                                <td>{order.positionSide} {order.side}</td>
                                <td>
                                  {+order.lastFilledPrice || +order.priceAvg || +order.price || ''}
                                  {+order.stopPrice ? ' | ' + order.stopPrice : ''}
                                  {+order.stopLimitPrice ? ' | ' + order.stopLimitPrice : ''}
                                </td>
                                <td>{order.quantity || ''}</td>
                                <td>{order.commission || ''} {order.commission ? order.commissionAsset || 'USDT' : ''}</td>
                                <td>{fin(order.profit || order.realizedProfit, '')}</td>
                                <td>
                                  <span className={order?.breakeven ? '' : 'hidden'}>
                                    &#x2714;
                                  </span>
                                </td>
                                <td>{order.status}</td>
                                <td>{order.closed === true ? 'CLOSED' : ''}</td>
                              </tr>
                              <tr>
                                <td colSpan={10} className={'tbl-info-cell order-td-info ' + (!order.info ? 'hidden' : '')}>
                                  {order.info}
                                </td>
                              </tr>
                              <tr className={showOrderHistory === order._id && order._id ? '' : 'hidden'}>
                                <td></td>
                                <td colSpan={11}>
                                  <Table striped bordered hover className="historyStatusesTable">
                                    <thead>
                                    <tr>
                                      <th colSpan={5} className="centered">
                                        ИСТОРИЯ ИЗМЕНЕНИЙ ОРДЕРА {order?.orderId}
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <th>Дата</th>
                                      <th>Статус</th>
                                      <th>Объём</th>
                                      <th>Цена</th>
                                      <th>Комиссия</th>
                                    </tr>
                                    {order?.statuses?.map(function (status: any, index3: number) {
                                      return (
                                        <tr>
                                          <td>{new Date(status[1]).toLocaleDateString() + ' ' + new Date(status[1]).toLocaleTimeString()}</td>
                                          <td>{status[0]}</td>
                                          <td>{+status[2] || ''}</td>
                                          <td>{+status[3] || ''}</td>
                                          <td>{+status[4] || ''} {+status[4] ? status[5] : ''}</td>
                                        </tr>
                                      );
                                    })}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        </tbody>
                      </Table>
                    </th>
                  </tr>
                </>
              );
            })}
            </tbody>
          </>
        );
      })}
    </Table>
  );
};

export default Statistics;
