import $api from "../../../http";
import { createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../../service/auth.service";
import { AuthResponse } from "../../../types/responses/AuthResponse";
import { URL } from "../../../utils/config";

interface loginData {
  email: string;
  password: string;
}

export const login = createAsyncThunk(
  "user/login",
  async (userData: loginData, thunkAPI) => {
    try {
      const response = await AuthService.login(
        userData.email,
        userData.password
      );

      console.log(response.data);

      localStorage.setItem("token", response.data.accessToken);
      return response.data.user;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message || "Не удалось войти!");
    }
  }
);

export const logout = createAsyncThunk("user/logout", async (_, thunkAPI) => {
  try {
    const response = await AuthService.logout();
    localStorage.removeItem("token");

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Не удалось выйти!");
  }
});

export const registration = createAsyncThunk(
  "user/registration",
  async (userData: loginData, thunkAPI) => {
    try {
     const response = await AuthService.registration(
        userData.email,
        userData.password
      );
      localStorage.setItem("token", response.data.accessToken);
      console.log(response.data);

      return response.data.user;
    } catch (err: any) {
      console.error(err?.response?.data?.message);
      return thunkAPI.rejectWithValue("Не удалось зарегистрироваться! " + (err?.response?.data?.message || ''));
    }
  }
);

export const checkAuth = createAsyncThunk(
  "user/refresh",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get<AuthResponse>(`${URL}/refresh`);
      console.log(response.data);
      localStorage.setItem("token", response.data.accessToken);

      return response.data.user;
    } catch (err: any) {
      console.error(err.response.data.message);
      return thunkAPI.rejectWithValue("Ошибка авторизации!");
    }
  }
);

export const getAccountStatus = createAsyncThunk(
  "user/getAccountStatus",
  async (_, thunkAPI) => {
    try {
      const response = await AuthService.getAccountStatus();

      return response.data
    } catch (err: any) {
      console.error(err.response.data.message);
      return thunkAPI.rejectWithValue("Ошибка авторизации!");
    }
  }
);
