import React from "react";
import { useAppSelector } from "../hooks/redux";
import TelegramConnect from "./components/TelegramConnect/TelegramConnect";
import BinanceConnect from "./components/BinanceConnect/BinanceConnect";
import BybitConnect from "./components/BybitConnect/BybitConnect";
import "./styles/Connect.css";

const Connect = () => {
  const { telegramError } = useAppSelector((state) => state.telegramReducer);
  const { stateError: stateBinanceError } = useAppSelector((state) => state.binanceReducer);
  const { stateError: stateBybitError } = useAppSelector((state) => state.bybitReducer);

  return (
    <div>
      <div className="error-container">
        {telegramError && <div className="input-feedback">{telegramError}</div>}
        {stateBinanceError && <div className="input-feedback">{stateBinanceError}</div>}
        {stateBybitError && <div className="input-feedback">{stateBybitError}</div>}
      </div>

      <TelegramConnect />

      <BinanceConnect />

      <BybitConnect />

    </div>
  );
};

export default Connect;
