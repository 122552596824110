import {createAsyncThunk} from '@reduxjs/toolkit';
import TelegramService from '../../../service/telegram.service';
import {ITelegramConnect} from '../../../types/requests/ITelegramConnect';
import {ITelegramCode} from '../../../types/requests/ITelegramCode';
import {IChannelRequest} from '../../../types/requests/IChannelRequest';

export const connect = createAsyncThunk('telegram/connect', async (data: ITelegramConnect, thunkAPI) => {
  try {
    const response = await TelegramService.connect(data.apiId, data.apiHash, data.phoneNumber);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const sendCode = createAsyncThunk('telegram/code', async (data: ITelegramCode, thunkAPI) => {
  try {
    const response = await TelegramService.sendCode(data.apiId, data.apiHash, data.phoneNumber, data.authCode);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const getAccount = createAsyncThunk('telegram/getAccount', async (_, thunkAPI) => {
  try {
    const response = await TelegramService.getAccount();
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});


export const getChannels = createAsyncThunk('telegram/getChannels', async (_, thunkAPI) => {
  try {
    const response = await TelegramService.getChannels();
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const addChannel = createAsyncThunk('telegram/addChannel', async (data: IChannelRequest, thunkAPI) => {
  try {
    const response = await TelegramService.addChannel(data);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const deleteChannel = createAsyncThunk('telegram/deleteChannel', async (channelName: string, thunkAPI) => {
  try {
    const response = await TelegramService.deleteChannel(channelName);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const getTradeHistory = createAsyncThunk('telegram/getTradeHistory', async (period: any, thunkAPI) => {
  try {
    const response = await TelegramService.getTradeHistory(period);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const editChannel = createAsyncThunk('telegram/editChannel', async (data: any, thunkAPI) => {
  try {
    const response = await TelegramService.editChannel(data);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});