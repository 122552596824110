import React from 'react';
import {Button, Form, Row, Col, Table, Container} from 'react-bootstrap';
import {useAppDispatch} from '../../../../hooks/redux';
import {
  autoModeStart,
  autoModeSave,
  autoModeGet,
} from '../../../../store/reducers/Binance/BinanceActionCreator';

import './DashboardOrders.css';

interface IProps {
  active: any;
  dashboard: any;
  values?: any;
  dispatch: any;
}

interface IState {
  test: number;
  values?: any;
}

class DashboardAutoTab extends React.Component<IProps, IState> {

  public dashboard: any;
  public values: any;
  public autoModeStarted: boolean = false;
  public dispatch: any;
  public active: any;

  constructor(props: IProps) {
    super(props);
    this.dispatch = props?.dispatch;
    this.active   = props?.active;
    // this.dashboard = props?.dashboard;
    // this.values   = props?.values;
    // this.state     = {
    //   test: 23,
    // };
  }

  componentDidMount() {
    console.info('componentDidMount', this.props);
    this.dispatch(autoModeGet()).then((r: any) => {
      console.info('rrr', r?.payload?.data);
      this.values = r?.payload?.data;
    });
  }

  handleChange(symbol: any, field: any, value: any): any {
    if ( !this.values) {
      this.values = {};
    }
    if ( !this.values[symbol]) {
      this.values[symbol] = {};
    }
    if (field !== 'enable') {
      this.values[symbol]['enable'] = true;
    }
    this.values[symbol][field] = value;
    this.clickAutoModeSave(false);
  }

  clickAutoModeStart() {
    this.autoModeStarted = true;
    let items: any       = {};

    if (this.values && Object.keys(this.values)?.length) {
      for (const s of Object.keys(this.values)) {
        let o = this.values[s];
        if (o?.enable) {
          items[s] = o;
        }
      }
    }

    this.dispatch(autoModeStart(items)).then(() => {
      this.autoModeStarted = false;
    });
  };

  clickAutoModeSave(prepare: any = false) {
    this.autoModeStarted = true;
    let data: any        = {};

    if (this.values && Object.keys(this.values)?.length) {
      for (const s of Object.keys(this.values)) {
        let o = this.values[s];
        if (o?.enable) {
          data[s] = o;
        }
      }
    }

    this.dispatch(autoModeSave({
        data,
        prepare,
      }))
      .then(() => {
        this.autoModeStarted = false;
      });
  };

  clickAutoModeReset() {
    this.autoModeStarted = true;
    this.values          = {};
    this.dispatch(autoModeSave({data: {}})).then(() => {
      this.autoModeStarted = false;
    });
  };

  checkValues() {

    // console.info('this.values 1', this.values);

    if ( !this.values || !Object.keys(this.values).length) {
      this.values = {};
    }

    // if(!Object.keys(this.values).length){
    if (this.dashboard?.AUTO?.data?.length) {
      for (const s of this.dashboard?.AUTO?.data) {
        let current = this.values[s.symbol];
        if ( !current || s.enable !== current?.enable) {
          this.values[s.symbol] = {
            isMarkPrice : s.isMarkPrice,
            isLimitExit : s.isLimitExit,
            leverage    : s.leverage,
            volume      : s.volume,
            pricePercent: s.pricePercent,
            tp          : s.tp,
            sl          : s.sl,
            enable      : s.enable,
          };
        }
      }
    }

    // console.info('this.values 2', this.values);

    // }
  }

  render() {

    const {dashboard, active} = this.props;
    this.checkValues();
    // console.info('!!! values', this.values);

    return (
      <>
        <Table striped bordered hover>
          <thead>
          <tr className="header-fixed">
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Дата</th>
            <th rowSpan={2}>Торговая пара</th>
            <th rowSpan={2}>Направление</th>
            <th rowSpan={2}>Цена открытия</th>
            <th rowSpan={2}>Плечо</th>
            <th rowSpan={2}>Сумма,&nbsp;$</th>
            <th rowSpan={2}>Цена,&nbsp;%</th>
            <th rowSpan={2}>SL,&nbsp;%</th>
            <th rowSpan={2}>TP,&nbsp;%</th>
            <th rowSpan={2}>Тип цены<br />Вход/Выход</th>
            <th colSpan={4} className="centered">Price</th>
            <th colSpan={2} className="centered">
              Ставка, %
            </th>
            {/*<th rowSpan={2}>Fee</th>*/}
            <th rowSpan={2}>Время</th>
            <th rowSpan={2}>Enable</th>
          </tr>
          <tr>
            <th>Last</th>
            <th>Mark</th>
            <th>Diff</th>
            <th>Diff, %</th>
            <th className="funding-rate">Last Funding Rate</th>
            <th>Interest Rate</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th colSpan={20} className="centered">
              <Button className="auto-button-save border-black mx-3 float-start"
                      variant="light"
                      size="sm"
                      disabled={this.autoModeStarted}
                      onClick={_ => this.clickAutoModeStart()}
              >
                START NOW
              </Button>
              <span> -0.04 &laquo; &raquo; 0.04</span>
              <Button disabled={dashboard?.AUTO?.saveEnable !== true}
                      className="auto-button-save border-black"
                      variant="success"
                      size="sm"
                      onClick={_ => this.clickAutoModeSave(true)}
              >
                SAVE & PREPARE
              </Button>
              <Button className="auto-button-save border-black mx-2"
                      variant="danger"
                      size="sm"
                      onClick={_ => this.clickAutoModeReset()}
              >
                CLEAR
              </Button>
            </th>
          </tr>
          {[...(active ? dashboard?.AUTO?.data : []) || []]?.filter(o => Math.abs(o.rate) >= 0.04)?.map((s: any, index: any) => {

            this.checkValues();

            let enable = this.values && this.values[s.symbol] && this.values[s.symbol]?.enable !== undefined
                         ? this.values[s.symbol]?.enable
                         : s.enable;
            // let enable = String(e0) !== '' && String(e0) !== 'undefined'
            //              ? this.values[s.symbol]?.enable
            //              : s?.enable;

            return (
              <tr className={enable ? 'symbol-autoorder-enabled' : ''} key={'tr_' + s.symbol}>
                <td>{index + 1}</td>
                <td className="text-nowrap">{new Date(+s.date).toLocaleDateString()} {new Date(+s.date).toLocaleTimeString()}</td>
                <td>{s.symbol}</td>
                <td className={s.positionSide === 'LONG' ? 'long' : 'short'}>
                  {s.positionSide}
                </td>
                <td>
                  {s.priceOpen || '?'}
                </td>
                <td className="td-90">
                  <Form.Control className=""
                                size="sm"
                                inputMode="decimal"
                                type="number"
                                key={'auto_' + s.symbol + '_leverage'}
                                id={'auto_' + s.symbol + '_leverage'}
                                name={'auto[' + s.symbol + '].leverage'}
                                required={true}
                                defaultValue={s.leverage}
                                onChange={(e) => this.handleChange(s.symbol, `leverage`, e?.target?.value)}
                                disabled={dashboard?.AUTO?.saveEnable !== true}
                  />
                </td>
                <td className="td-90">
                  <Form.Control className=""
                                size="sm"
                                inputMode="decimal"
                                type="number"
                                key={'auto_' + s.symbol + '_volume'}
                                id={'auto_' + s.symbol + '_volume'}
                                name={'auto[' + s.symbol + '].volume'}
                                required={true}
                                defaultValue={s.volume}
                                onChange={(e) => this.handleChange(s.symbol, `volume`, e?.target?.value)}
                                disabled={dashboard?.AUTO?.saveEnable !== true}
                  />
                </td>
                <td className="td-90">
                  <Form.Control className=""
                                size="sm"
                                inputMode="decimal"
                                type="number"
                                key={'auto_' + s.symbol + '_pricePercent'}
                                id={'auto_' + s.symbol + '_pricePercent'}
                                name={'auto[' + s.symbol + '].pricePercent'}
                                required={true}
                                defaultValue={s.pricePercent}
                                onChange={(e) => this.handleChange(s.symbol, `pricePercent`, e?.target?.value)}
                                disabled={dashboard?.AUTO?.saveEnable !== true}
                  />
                </td>
                <td className="td-90">
                  <Form.Control className=""
                                size="sm"
                                inputMode="decimal"
                                type="number"
                                key={'auto_' + s.symbol + '_sl'}
                                id={'auto_' + s.symbol + '_sl'}
                                name={'auto[' + s.symbol + '].sl'}
                                required={true}
                                defaultValue={s.sl}
                                onChange={(e) => this.handleChange(s.symbol, `sl`, e?.target?.value)}
                                disabled={dashboard?.AUTO?.saveEnable !== true}
                  />
                </td>
                <td className="td-90">
                  <Form.Control className=""
                                size="sm"
                                inputMode="decimal"
                                type="number"
                                key={'auto_' + s.symbol + '_tp'}
                                id={'auto_' + s.symbol + '_tp'}
                                name={'data[' + s.symbol + '].tp'}
                                required={true}
                                defaultValue={s.tp}
                                onChange={(e) => this.handleChange(s.symbol, `tp`, e?.target?.value)}
                                disabled={dashboard?.AUTO?.saveEnable !== true}
                  />
                </td>
                <td>
                  <Container fluid>
                    <Row className="justify-content-md-center justify-content-lg-center">
                      <Col xs={6} md={6} lg={6}>
                        <Form.Check
                          type="switch"
                          key={'pricemode_' + s.symbol}
                          id={'pricemode_' + s.symbol}
                          name={'pricemode_' + s.symbol}
                        >
                          <Form.Check.Input
                            className=""
                            key={'auto_' + s.symbol + '_isMarkPrice'}
                            id={'auto_' + s.symbol + '_isMarkPrice'}
                            name={'auto[' + s.symbol + '].isMarkPrice'}
                            value="true"
                            type="checkbox"
                            defaultChecked={s?.isMarkPrice}
                            hidden={ !+s?.price || !+s?.priceMark}
                            onChange={(e) => this.handleChange(s.symbol, 'isMarkPrice', e?.target?.checked)}
                            disabled={dashboard?.AUTO?.saveEnable !== true}
                          />
                        </Form.Check>
                        <span className="float-start">
                          {this.values[s.symbol]?.isMarkPrice !== undefined && this.values[s.symbol]?.isMarkPrice === true ? 'MARK' : 'LAST'}
                          {/*{String(this.values && this.values[s.symbol] ? this.values[s.symbol]?.isMarkPrice : '') !== ''*/}
                          {/* ? (this.values[s.symbol]?.isMarkPrice === undefined || this.values[s.symbol]?.isMarkPrice ? 'MARK' : 'LAST')*/}
                          {/* : (s?.isMarkPrice !== undefined && s?.isMarkPrice === true ? 'MARK' : 'LAST')*/}
                          {/*}*/}
                        </span>
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Check
                          type="switch"
                          key={'pricemode2_' + s.symbol}
                          id={'pricemode2_' + s.symbol}
                          name={'pricemode2_' + s.symbol}
                        >
                          <Form.Check.Input
                            className=""
                            key={'auto_' + s.symbol + '_isLimitExit'}
                            id={'auto_' + s.symbol + '_isLimitExit'}
                            name={'auto[' + s.symbol + '].isLimitExit'}
                            value="true"
                            type="checkbox"
                            defaultChecked={s?.isLimitExit}
                            hidden={ !+s?.price || !+s?.priceMark}
                            onChange={(e) => this.handleChange(s.symbol, 'isLimitExit', e?.target?.checked)}
                            disabled={dashboard?.AUTO?.saveEnable !== true}
                          />
                        </Form.Check>
                        <span className="float-start">
                          {String(this.values && this.values[s.symbol] ? this.values[s.symbol]?.isLimitExit : '') !== ''
                           ? (this.values[s.symbol]?.isLimitExit === undefined || this.values[s.symbol]?.isLimitExit ? 'LIMIT' : 'MARKET')
                           : (s?.isLimitExit ? 'LIMIT' : 'MARKET')
                          }
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </td>
                <td>{s?.price || '?'}</td>
                <td>{s?.priceMark || '?'}</td>
                <td>{s?.priceMarkDiff ? +s.priceMarkDiff : ''}</td>
                <td>{s?.priceMarkDiffPercent ? s.priceMarkDiffPercent : ''}</td>
                <td className="funding-rate">{+Number(s.lastFundingRate).toFixed(5)}</td>
                <td>{+Number(s.interestRate).toFixed(5)}</td>
                {/*<td>{+Number(s.liquidationFee).toFixed(5)}</td>*/}
                <td className="text-nowrap">
                  <span className="span-hms">{s?.dateDiff?.h ? s?.dateDiff?.h + 'h' : ''}</span>
                  <span className="span-hms">{s?.dateDiff?.m ? s?.dateDiff?.m + 'm' : ''}</span>
                  <span className="span-hms">{s?.dateDiff?.s}s</span>
                </td>
                <td className="td-90 centered">
                  <Form.Check
                    type="switch"
                    key={'auto_' + s.symbol + '_enable0'}
                    id={'auto_' + s.symbol + '_enable0'}
                    name={'auto_' + s.symbol + '_enable0'}
                  >
                    <Form.Check.Input
                      hidden={ !+s?.price && !+s?.priceMark}
                      className=""
                      key={'auto_' + s.symbol + '_enable'}
                      id={'auto_' + s.symbol + '_enable'}
                      name={'auto_' + s.symbol + '_enable'}
                      value="true"
                      type="checkbox"
                      onChange={(e) => this.handleChange(s.symbol, 'enable', e?.target?.checked)}
                      disabled={dashboard?.AUTO?.saveEnable !== true}
                    />
                  </Form.Check>
                </td>
              </tr>
            );
          })}
          {[...(active ? dashboard?.AUTO?.data : []) || []]?.filter(o => Math.abs(o.rate) >= 0.03 && Math.abs(o.rate) < 0.04)?.map((s: any, index: any) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{new Date(+s.date).toLocaleDateString()} {new Date(+s.date).toLocaleTimeString()}</td>
                <td>{s.symbol}</td>
                <td colSpan={8} className={s.positionSide === 'LONG' ? 'long' : 'short'}>{s.positionSide}</td>
                <td>{s.price || '?'}</td>
                <td>{s.priceMark}</td>
                <td>{s.priceMarkDiff}</td>
                <td>{s.priceMarkDiffPercent}</td>
                <td className="funding-rate">{+Number(s.lastFundingRate).toFixed(5)}</td>
                <td>{+Number(s.interestRate).toFixed(5)}</td>
                {/*<td>{+Number(s.liquidationFee).toFixed(5)}</td>*/}
                <td colSpan={2}>
                  <span className="span-hms">{s?.dateDiff?.h ? s?.dateDiff?.h + 'h' : ''}</span>
                  <span className="span-hms">{s?.dateDiff?.m ? s?.dateDiff?.m + 'm' : ''}</span>
                  <span className="span-hms">{s?.dateDiff?.s}s</span>
                </td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </>
    );
  }

}

export default DashboardAutoTab;