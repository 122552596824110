import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../hooks/redux';

import {
  deleteChannel,
  getChannels,
} from '../store/reducers/Telegram/TelegramActionCreator';

import {Accordion, Button, Col, Container, Row} from 'react-bootstrap';
import AddChannelModal from '../components/modals/AddChannelModal/AddChannelModal';
import EditChannelModal from '../components/modals/EditChannelModal/EditChannelModal';

import './styles/TelegramTraiding.css';
import {setEditingChannel} from '../store/reducers/Telegram/TelegramSlice';

const Telegram = () => {
  const dispatch = useAppDispatch();
  const {
          channels,
          // isLoading: isTelegramLoading,
          telegramError,
          // editChannel,
        }        = useAppSelector((state) => state.telegramReducer);

  let [showAddChannel, setShowAddChannel]   = useState<boolean>(false);
  let [showEditChannel, setShowEditChannel] = useState<boolean>(false);
  // let [currentChannel, setCurrentChannel]   = useState<any>({
  //   status          : '',
  //   telegramSettings: {
  //     channelName     : '',
  //     signalWordsLong : '',
  //     signalWordsShort: '',
  //     closeWords      : '',
  //     workingType     : '',
  //   },
  //   binanceSettings : {
  //     leverage       : 1,
  //     whiteList      : '',
  //     blockList      : '',
  //     positionSize   : 10,
  //     fundingMin     : -5,
  //     fundingMax     : 5,
  //     testMaxQty     : 0,
  //     repeatTimeLimit: 60,
  //     tps            : [
  //       {
  //         amount   : 100,
  //         breakeven: false,
  //         offset   : 1,
  //       },
  //     ],
  //     sl             : {
  //       type  : 'USUAL',
  //       amount: 100,
  //       offset: 1,
  //     },
  //   },
  //   bybitSettings   : {
  //     leverage       : 1,
  //     whiteList      : '',
  //     blockList      : '',
  //     positionSize   : 10,
  //     fundingMin     : -5,
  //     fundingMax     : 5,
  //     testMaxQty     : 0,
  //     repeatTimeLimit: 60,
  //     tps            : [
  //       {
  //         amount   : 100,
  //         breakeven: false,
  //         offset   : 1,
  //       },
  //     ],
  //     sl             : {
  //       type  : 'USUAL',
  //       amount: 100,
  //       offset: 1,
  //     },
  //   },
  // });

  useEffect(() => {
    dispatch(getChannels());
  }, [dispatch]);

  const deleteHandler = (channelName: string) => {
    dispatch(deleteChannel(channelName));
  };

  return (
    <Container className="trading-wrapper">
      <div>
        {telegramError !== '' && (
          <p className="input-feedback">{telegramError}</p>
        )}
      </div>
      <div className="mb-3">
        <Button onClick={() => setShowAddChannel(true)}>Добавить канал</Button>
      </div>
      <Accordion defaultActiveKey="0">
        {channels.map((channel, index) => {
          return (
            <Accordion.Item
              key={`Channel ${index}`}
              eventKey={index.toString()}
            >
              <Accordion.Header>
                {channel.telegramSettings.channelName}
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <p>Статус:</p>
                  </Col>
                  <Col>
                    <p>{channel.status}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Сигнальные слова лонг:</p>
                  </Col>
                  <Col>
                    <p>{channel.telegramSettings.signalWordsLong}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Сигнальные слова шорт:</p>
                  </Col>
                  <Col>
                    <p>{channel.telegramSettings.signalWordsShort}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Сигнальные слова закрытия позиции:</p>
                  </Col>
                  <Col>
                    <p>{channel.telegramSettings.closeWords}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Тип цены:</p>
                  </Col>
                  <Col>
                    <p>{channel.telegramSettings.workingType || 'MARK_PRICE'}</p>
                  </Col>
                </Row>
                <Row className={channel.binanceSettings?.whiteList ? '' : 'hidden'}>
                  <Col>
                    <p>Whitelist:</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.whiteList}</p>
                  </Col>
                </Row>
                <Row className={channel.binanceSettings?.blockList ? '' : 'hidden'}>
                  <Col>
                    <p>Blocklist:</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.blockList}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Плечо:</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.leverage}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Размер позиции:</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.positionSize}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Cтавка финансирования (диапазон, %):</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.fundingMin} ... {channel.binanceSettings.fundingMax}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Ограничение для повторных сделок (минут):</p>
                  </Col>
                  <Col>
                    <p>{channel.binanceSettings.repeatTimeLimit}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Тейкпрофиты:</p>
                  </Col>
                  <Col>
                    {channel.binanceSettings.tps.map((tp, indexTp) => {
                      return (
                        <div key={`Channel ${index} tp ${indexTp}`}>
                          <p>
                            {indexTp + 1}. Отклонение: {tp.offset}; Кол-во:{' '}
                            {tp.amount} {tp.breakeven && 'б/у'}
                          </p>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Стоплосс:</p>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        Отклонение: {channel.binanceSettings.sl.offset}; Кол-во:
                        {channel.binanceSettings.sl.amount}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className={channel.binanceSettings.testMaxQty ? '' : 'hidden'}>
                  <Col>
                    <p>(Тест) max позиция ($):</p>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        {channel.binanceSettings.testMaxQty}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end">
                  <Button
                    className="m-1"
                    onClick={() => {
                      dispatch(setEditingChannel(channel));
                      setShowEditChannel(true);
                    }}
                  >
                    Редактировать
                  </Button>
                  <Button
                    className="m-1"
                    variant="danger"
                    onClick={() =>
                      deleteHandler(channel.telegramSettings.channelName)
                    }
                  >
                    Удалить
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <AddChannelModal
        showModal={showAddChannel}
        onHide={() => setShowAddChannel(false)}
      />
      <EditChannelModal
        show={showEditChannel}
        onHide={() => setShowEditChannel(false)}
      />
    </Container>
  );
};

export default Telegram;
