import {createAsyncThunk} from '@reduxjs/toolkit';
import {IExchangeOrderNew} from '../../../types/IExchangeOrder';
import BinanceService from '../../../service/binance.service';
import {IExchangeAccount} from '../../../types/IExchangeAccount';

export const connect = createAsyncThunk('binance/connect', async (data: IExchangeAccount, thunkAPI) => {
  try {
    const response = await BinanceService.connect(data.key, data.secret);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const getDashboard = createAsyncThunk('binance/getDashboard', async (part: any, thunkAPI) => {
  try {
    const result    = (await BinanceService.getDashboard(part));
    let data        = result?.data;
    data['success'] = true;
    return data;
  } catch (err: any) {
    console.error(err);
    return {success: false};
    // return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const getNotifications = createAsyncThunk('binance/getNotifications', async (data: any, thunkAPI) => {
  try {
    const result = (await BinanceService.getNotifications(data?.uid, data?.mode, data?.created));
    let r        = result?.data;
    r['success'] = true;
    return r;
  } catch (err: any) {
    console.error(err);
    return {success: false};
    // return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const transferFunds = createAsyncThunk('binance/transferFunds', async (item: any, thunkAPI) => {
  try {
    const r = await BinanceService.transferFunds(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось переместить средства!');
  }
});

export const closeSpotPosition = createAsyncThunk('binance/closeSpotPosition', async (item: any, thunkAPI) => {
  try {
    const r = await BinanceService.closeSpotPosition(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось переместить средства!');
  }
});

export const restoreSpotPosition = createAsyncThunk('binance/restoreSpotPosition', async (item: any, thunkAPI) => {
  try {
    const r = await BinanceService.restoreSpotPosition(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed!');
  }
});

export const savePosition = createAsyncThunk('binance/savePosition', async (item: any, thunkAPI) => {
  try {
    const r = await BinanceService.savePosition(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить позицию!');
  }
});

export const autoModeParamsGet = createAsyncThunk('binance/autoModeParamsGet', async (_, thunkAPI) => {
  try {
    let r = await BinanceService.autoModeParamsGet();
    // r['success'] = r?.status === 200;
    return r;
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить данные!');
  }
});

export const autoModeParamsSave = createAsyncThunk('binance/autoModeParamsSave', async (data: any, thunkAPI) => {
  try {
    const r = await BinanceService.autoModeParamsSave(data);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить позицию!');
  }
});

export const autoModeGet = createAsyncThunk('binance/autoModeGet', async (_, thunkAPI) => {
  try {
    let r = await BinanceService.autoModeGet();
    // r['success'] = r?.status === 200;
    return r;
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить данные!');
  }
});

export const autoModeSave = createAsyncThunk('binance/autoModeSave', async (data: any, thunkAPI) => {
  try {
    const r = await BinanceService.autoModeSave(data);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить позицию!');
  }
});

export const autoModeStart = createAsyncThunk('binance/autoModeStart', async (data: any, thunkAPI) => {
  try {
    const r = await BinanceService.autoModeStart(data);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить позицию!');
  }
});

export const setMode    = createAsyncThunk('binance/setMode', async (_, thunkAPI) => {
  try {
    let r: any        = await BinanceService.setMode();
    let payload       = r?.payload || r;
    let result        = payload?.data || payload;
    result['success'] = r.status === 200 ? true : false;
    return r;
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось изменить!');
  }
});
export const closeGroup = createAsyncThunk('binance/groupClose', async (group: any, thunkAPI) => {
  try {
    const r = await BinanceService.groupClose(group);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось закрыть ордер!');
  }
});

export const startOrder = createAsyncThunk('binance/orderStart', async (order: any, thunkAPI) => {
  try {
    const r = await BinanceService.orderStart(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось открыть ордер!');
  }
});

export const closeOrder = createAsyncThunk('binance/orderClose', async (order: any, thunkAPI) => {
  try {
    const r = await BinanceService.orderClose(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось закрыть ордер!');
  }
});

export const closeCurrentOrders = createAsyncThunk('binance/closeCurrentOrders', async (position: any, thunkAPI) => {
  try {
    const r = await BinanceService.closeCurrentOrders(position);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось закрыть ордера!');
  }
});

export const prepareOrder = createAsyncThunk('binance/orderPrepare', async (order: IExchangeOrderNew, thunkAPI) => {
  try {
    const r = await BinanceService.orderPrepare(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось подготовить ордер!');
  }
});

export const createOrder = createAsyncThunk('binance/orderCreate', async (order: IExchangeOrderNew, thunkAPI) => {
  try {
    const r = await BinanceService.orderCreate(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось открыть ордер!');
  }
});

export const templateSave = createAsyncThunk('binance/templateSave', async (data: any, thunkAPI) => {
  try {
    const r = await BinanceService.templateSave(data);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить template!');
  }
});

export const getTemplate = createAsyncThunk('binance/getTemplate', async (_, thunkAPI) => {
  try {
    const r = await BinanceService.getTemplate();
    return r?.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить template!');
  }
});

export const getSymbols = createAsyncThunk('binance/getSymbols', async (_, thunkAPI) => {
  try {
    const r = await BinanceService.getSymbols();
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить getSymbols!');
  }
});

export const getAccount = createAsyncThunk('binance/getAccount', async (_, thunkAPI) => {
  try {
    const response = await BinanceService.getAccount();
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});