import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import AuthMenu from "../components/common/AuthMenu/AuthMenu";

const AuthLayout: FC = () => {
  return (
    <div>
      <AuthMenu />

      <main className="wrapper">
        <Outlet />
      </main>
    </div>
  );
};

export default AuthLayout;