import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';

import {getAccount} from '../../../store/reducers/Telegram/TelegramActionCreator';

import {Row, Button, Col} from 'react-bootstrap';
import APITable from '../../../components/common/APITable/APITable';
import ConnectTelegramModal from '../../../components/modals/ConnectTelegramModal/ConnectTelegramModal';

import './TelegramConnect.css';

const TelegramConnect = () => {
  const dispatch                = useAppDispatch();
  const {telegramAccount}       = useAppSelector((state) => state.telegramReducer);
  let [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  return (
    <div className="connect-wrapper">
      <div className="module-header">
        <Row>
          <Col className="">
            <h3>TELEGRAM</h3>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button onClick={() => setShowModal(true)}>
              Добавить новый аккаунт
            </Button>
          </Col>
        </Row>
        <hr />
      </div>
      <APITable
        data={{
          'api Id'        : telegramAccount.apiId,
          'api Hash'      : telegramAccount.apiHash,
          'Номер телефона': telegramAccount.phoneNumber,
        }}
      />
      <ConnectTelegramModal
        onHide={() => setShowModal(false)}
        showModal={showModal}
      />
    </div>
  );
};

export default TelegramConnect;
