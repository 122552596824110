import React, {FC} from 'react';
import {registration} from '../../store/reducers/User/UserActionCreator';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Col,
  Form,
  Row,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap';
import Menu from '../../components/common/UnauthMenu/UnauthMenu';

import './Registration.css';

const Registration: FC = () => {

  const {userError: error} = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const initialValues = {
    email        : '',
    password     : '',
    passwordAgain: '',
  };

  const validationSchema = Yup.object().shape({
    email        : Yup.string()
      .email('Email должен соответствовать форме example@mail.com')
      .required('Email не введен!'),
    password     : Yup.string()
      .required('Пароль не введен!')
      .min(8, 'Пароль должен быть длинной в 8 символов!')
      .matches(/(?=.*[0-9])/, 'Пароль должен включать цифры!'),
    passwordAgain: Yup.string()
      .required('Пароль не введен!')
      .min(8, 'Пароль должен быть длинной в 8 символов!')
      .matches(/(?=.*[0-9])/, 'Пароль должен включать цифры!'),
  });

  return (
    <div>
      <Menu />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            console.log('Signing in', values);
            setSubmitting(false);
            if (values.passwordAgain === values.password) {
              dispatch(
                registration({
                  email   : values.email,
                  password: values.password,
                }),
              );
            }
          }, 500);
        }}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const {
                  values,
                  errors,
                  touched,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                } = formik;
          return (
            <div className="signup-container">
              <Container fluid>
                <Row className="justify-content-md-center justify-content-lg-center">
                  <Col xs={12} md={12} lg={8} xl={7} xxl={6}>
                    <Form
                      onSubmit={handleSubmit}
                      className="signup-form shadow p-5"
                    >
                      <Row className="d-flex text-center mb-2">
                        <h2>Регистрация</h2>
                      </Row>
                      <FormGroup as={Col} className="mb-3">
                        {error && (
                          <div className="input-feedback">
                            {error}
                          </div>
                        )}
                        <FormLabel>Email адрес</FormLabel>
                        <FormControl
                          name="email"
                          type="email"
                          value={values.email}
                          placeholder="Введите email"
                          onChange={handleChange}
                        ></FormControl>
                        {errors.email && touched.email && (
                          <div className="input-feedback">{errors.email}</div>
                        )}
                      </FormGroup>
                      <FormGroup as={Col} className="mb-3">
                        <FormLabel>Введите пароль</FormLabel>
                        <FormControl
                          name="password"
                          type="password"
                          value={values.password}
                          placeholder="Пароль"
                          onChange={handleChange}
                        ></FormControl>
                        {errors.password && touched.password && (
                          <div className="input-feedback">
                            {errors.password}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup as={Col} className="mb-3">
                        <FormLabel>Введите повторно пароль</FormLabel>
                        <FormControl
                          name="passwordAgain"
                          type="password"
                          value={values.passwordAgain}
                          placeholder="Пароль"
                          onChange={handleChange}
                        ></FormControl>
                        {errors.passwordAgain && touched.passwordAgain && (
                          <div className="input-feedback">
                            {errors.passwordAgain}
                          </div>
                        )}
                        {values.password !== values.passwordAgain && (
                          <div className="input-feedback">
                            Пароли не совпадают
                          </div>
                        )}
                      </FormGroup>
                      <Row className="d-flex justify-content-center">
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          xl={6}
                          xxl={6}
                          className="d-flex justify-content-center"
                        >
                          <Button
                            className="signup-btn px-5 py-2"
                            variant="primary"
                            type="submit"
                            disabled={
                              !isValid ||
                              isSubmitting ||
                              values.password !== values.passwordAgain
                            }
                          >
                            Создать аккаунт
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Registration;
