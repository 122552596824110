import React from 'react';
import {Button, Form, Row, Col, Table, Container} from 'react-bootstrap';
import {useAppDispatch} from '../../../../hooks/redux';
import {
  autoModeGet,
} from '../../../../store/reducers/Binance/BinanceActionCreator';

import './DashboardOrders.css';

interface IProps {
  active: any;
  dashboard: any;
  values?: any;
  dispatch: any;
}

interface IState {
  test: number;
  values?: any;
}

class DashboardInfoTab extends React.Component<IProps, IState> {

  public dashboard: any;
  public values: any;
  public autoModeStarted: boolean = false;
  public dispatch: any;
  public active: any;
  public varRate: any             = 0;
  public varMarkDiffPercent: any  = 0;
  public varSymbol: any;

  constructor(props: IProps) {
    super(props);
    this.dispatch = props?.dispatch;
    this.active   = props?.active;
  }

  componentDidMount() {
    console.info('componentDidMount', this.props);
    this.dispatch(autoModeGet()).then((r: any) => {
      this.values = r?.payload?.data;
    });
  }

  checkValues() {

    // console.info('this.values 1', this.values);

    if ( !this.values || !Object.keys(this.values).length) {
      this.values = {};
    }

    // if(!Object.keys(this.values).length){
    if (this.dashboard?.AUTO?.data?.length) {
      for (const s of this.dashboard?.AUTO?.data) {
        let current = this.values[s.symbol];
        if ( !current || s.enable !== current?.enable) {
          this.values[s.symbol] = {
            isMarkPrice : s.isMarkPrice,
            isLimitExit : s.isLimitExit,
            leverage    : s.leverage,
            volume      : s.volume,
            pricePercent: s.pricePercent,
            tp          : s.tp,
            sl          : s.sl,
            enable      : s.enable,
          };
        }
      }
    }

    // console.info('this.values 2', this.values);

    // }
  }

  render() {

    const {
            dashboard,
            active,
          } = this.props;

    this.checkValues();
    // console.info('!!! values', this.values);

    return (
      <>
        <Table striped bordered hover>
          <thead>
          <tr className="header-fixed">
            <th rowSpan={3}>#</th>
            <th rowSpan={3}>Дата</th>
            <th rowSpan={2}>Торговая пара</th>
            <th rowSpan={3}>Направление</th>
            <th colSpan={4} className="centered">Price</th>
            <th colSpan={2} className="centered">
              Ставка, %
            </th>
            <th rowSpan={3}>Время</th>
          </tr>
          <tr>
            <th rowSpan={2}>Last</th>
            <th rowSpan={2}>Mark</th>
            <th rowSpan={2}>Diff</th>
            <th>Diff, %</th>
            <th className="funding-rate">Last Funding Rate</th>
            <th rowSpan={2}>Interest Rate</th>
          </tr>
          <tr>
            <td>
              <Form.Control className=""
                            inputMode="text"
                            type="text"
                            key={'info_symbol'}
                            id={'info_symbol'}
                            name={'varSymbol'}
                            onChange={(e) => { this.varSymbol = e?.target?.value;}}
              />
            </td>
            <td className="td-160">
              <Form.Control className=""
                            size="sm"
                            type="text"
                            key={'info_mpd'}
                            id={'info_mpd'}
                            name={'varMarkPriceDiff'}
                            placeholder="-1/1"
                            onChange={(e) => { this.varMarkDiffPercent = e?.target?.value;}}
              />
            </td>
            <td className="td-160">
              <Form.Control className=""
                            size="sm"
                            type="text"
                            key={'info_rate'}
                            id={'info_rate'}
                            name={'varRate'}
                            placeholder="-1/1"
                            onChange={(e) => { this.varRate = e?.target?.value;}}
              />
            </td>
          </tr>
          </thead>
          <tbody>
          {[...(active ? dashboard?.AUTO?.data : []) || []]
            // ?.filter((o: any) => {
            //   if (this?.varSymbol?.trim()) {
            //     for (const t of this?.varSymbol?.trim()?.split(' ')) {
            //       if (o?.symbol?.startsWith(t.toUpperCase())) {
            //         return true;
            //       }
            //     }
            //   }
            //   return Math.abs(o.rate) >= +this.varRate && Math.abs(+o?.priceMarkDiffPercent) >= +this.varMarkDiffPercent;
            // })
            ?.map((s: any, index: any) => {
              let o       = Object.assign({}, s);
              o['sort']   = Math.abs(o.lastFundingRate) + Math.abs(o?.priceMarkDiffPercent);
              o['sortHl'] = 0;
              if (this?.varSymbol?.trim()) {
                for (const t of this?.varSymbol?.trim()?.split(' ')) {
                  if (o?.symbol?.startsWith(t.toUpperCase())) {
                    o['sort'] += 100;
                    o['sortHl'] = 1;
                  }
                }
              }
              //
              // let v1 = +this.varMarkDiffPercent2
              //          ? +o.priceMarkDiffPercent >= Math.min(+this.varMarkDiffPercent, +this.varMarkDiffPercent2) && +o.priceMarkDiffPercent <= Math.max(+this.varMarkDiffPercent, +this.varMarkDiffPercent2)
              //          : +Math.abs(+o.priceMarkDiffPercent) >= +this.varMarkDiffPercent;
              // let v2 = +this.varRate2
              //          ? +o.lastFundingRate >= Math.min(+this.varRate, +this.varRate2) && +o.lastFundingRate <= Math.max(+this.varRate, +this.varRate2)
              //          : +Math.abs(+o.lastFundingRate) >= +this.varRate;

              let v1, v2;

              if (v1 && v2) {
                o['sort'] += 10;
                o['sortHl'] = 2;
              }

              o['v1'] = v1;
              o['v2'] = v2;

              return o;
            })
            ?.filter((o: any) => {

              const inInterval: any = (v: any, r: string) => {
                if ( !r || !r?.length) {
                  return true;
                }
                if (r && r?.length) {
                  let rt = r?.indexOf(',') > -1 ? r.split(',') : [r];
                  for (let r1 of rt) {
                    let rr = r1 && r1?.indexOf('/') > -1 ? r1?.split('/') : [+r1];
                    if (rr?.length > 1 && rr[0] && rr[1]) {
                      if (+v >= Math.min(+rr[0], +rr[1]) && +v <= Math.max(+rr[0], +rr[1])) {
                        return true;
                      }
                    } else if (rr?.length === 1) {
                      // if (r?.indexOf(',') === -1) {
                      //   if (+v >= Math.abs(+rr[0]) && +v <= Math.abs(+rr[0])) {
                      //     return true;
                      //   }
                      // } else
                      if (+v === +rr[0]) {
                        return true;
                      }
                    }
                  }
                }
                return false;
              };

              if ( !this.varRate && !this.varMarkDiffPercent) {
                return o;
              }

              let a1 = inInterval(+o.lastFundingRate, this.varRate);
              let a2 = inInterval(+o.priceMarkDiffPercent, this.varMarkDiffPercent);
              if ( !this.varRate && this.varMarkDiffPercent && a2
                || !this.varMarkDiffPercent && this.varRate && a1
                || this.varRate && a1 && this.varMarkDiffPercent && a2
              ) {
                return o;
              }

              // if (
              //   (this.varRate2 && o['v2'] || this.varMarkDiffPercent2 && o['v1'])
              //   || !this.varRate && !this.varMarkDiffPercent
              //   || !this.varRate2 && !this.varMarkDiffPercent2
              // ) {
              //   return o;
              // }
              // return o;
            })
            ?.sort((a: any,
                    b: any) => (Math.abs(+b?.sort) - Math.abs(+a?.sort)) + (a?.sortHl === true && b?.sortHl === true ? 0 : a.symbol.localeCompare(b.symbol)))
            ?.map((s: any, index: any) => {
              return (
                <tr key={'tr_' + s.symbol} className={
                  (s?.sortHl > 0 ? 'tr-info-hl' : '')
                  + ' ' + (s?.sortHl && s.isShort ? '[tr-info-hl-short[]' : '')
                  + ' ' + (s?.sortHl && s.isLong ? 'tr-info-hl-long' : '')
                }>
                  <td>{index + 1}</td>
                  <td className={'text-nowrap '}>{new Date(+s.date).toLocaleDateString()} {new Date(+s.date).toLocaleTimeString()}</td>
                  <td className={(s.sortHl ? 'span-info-hl' : '')
                    + ' ' + (s.isShort === true ? 'short' : '')
                    + ' ' + (s.isLong === true ? 'long' : '') + ' '
                    + ' ' + (s.sortHl === 2 ? (s.isShort ? 'tr-info-bg-short' : (s.isLong ? 'tr-info-bg-long' : '')) : '') + ' '
                  }>
                    {s.symbol}
                  </td>
                  <td>
                    <span hidden={(s.positionSide)}>...</span>
                    <span className="long" hidden={(s.positionSide === 'SHORT' || !s.positionSide)}>&nbsp;LONG</span>
                    <span className="short" hidden={(s.positionSide === 'LONG' || !s.positionSide)}>&nbsp;SHORT</span>
                    {/*<span className="font-small2" hidden={ !s.positionSideCalc || s.positionSideCalc === ''}>*/}
                    {/*  <sup>({s.positionSideCalc})</sup>*/}
                    {/*</span>*/}
                  </td>
                  <td>{s?.price || '?'}</td>
                  <td>{s?.priceMark || '?'}</td>
                  <td>{s?.priceMarkDiff ? +s.priceMarkDiff : ''}</td>
                  <td>{s?.priceMarkDiffPercent ? s.priceMarkDiffPercent : ''}</td>
                  <td className="funding-rate">{+Number(s.lastFundingRate).toFixed(5)}</td>
                  <td>{+Number(s.interestRate).toFixed(5)}</td>
                  <td className="text-nowrap">
                    <span className="span-hms">{s?.dateDiff?.h ? s?.dateDiff?.h + 'h' : ''}</span>
                    <span className="span-hms">{s?.dateDiff?.m ? s?.dateDiff?.m + 'm' : ''}</span>
                    <span className="span-hms">{s?.dateDiff?.s}s</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

}

export default DashboardInfoTab;