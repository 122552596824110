import React, {FC, useCallback, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import * as Yup from 'yup';

import * as telegramActions from '../../../store/reducers/Telegram/TelegramActionCreator';
import * as binanceActions from '../../../store/reducers/Binance/BinanceActionCreator';

import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';

import './AddChannelModal.css';
import {ITakeProfit} from '../../../types/ITakeProfit';
import TelegramSettings from './components/TelegramSettings/TelegramSettings';

interface AddChannelModalProps {
  showModal: boolean;
  onHide: any;
}

const AddChannelModal: FC<AddChannelModalProps> = ({
                                                     onHide,
                                                     showModal,
                                                   }) => {
  const dispatch = useAppDispatch();

  const {
          // telegramAccount,
          isLoading: isTelegramLoading,
        } = useAppSelector(
    (state) => state.telegramReducer,
  );

  const {
          // binanceAccount,
          isLoading: isBinanceLoading,
        } = useAppSelector(
    (state) => state.binanceReducer,
  );

  const {
          // bybitAccount,
          isLoading: isBybitLoading,
        } = useAppSelector(
    (state) => state.bybitReducer,
  );

  // let [breakevenCheckbox, setBreakevenCheckBox] = useState<number>();

  let [tempTps, setTempTps] = useState<any>([
    {
      offset: 1,
      amount: 100,
    } as ITakeProfit,
  ]);

  useEffect(() => {
    dispatch(telegramActions.getAccount());
    dispatch(binanceActions.getAccount());
  }, [dispatch]);

  const VALIDATION_SCHEMA = Yup.object().shape({
    telegramChannelTitle: Yup.string().required('Обязательно для заполнения'),
    leverage            : Yup.string().required('Обязательно для заполнения'),
    positionSize        : Yup.string().required('Обязательно для заполнения'),
    fundingMin          : Yup.string().required('Обязательно для заполнения'),
    fundingMax          : Yup.string().required('Обязательно для заполнения'),
    repeatTimeLimit     : Yup.string().required('Обязательно для заполнения'),
    tpsNumber           : Yup.string().required('Обязательно для заполнения'),
    slOffset            : Yup.string().required('Обязательно для заполнения'),
  });

  const initialValues = {
    telegramChannelTitle: '',
    signalWordsLong     : 'LONG',
    signalWordsShort    : 'SHORT',
    closeWords          : 'CANCELED',
    workingType         : 'MARK_PRICE',
    whiteList           : '',
    blockList           : '',
    leverage            : 1,
    leverageSpot        : 1,
    hedgeSpot           : false,
    positionSize        : 10,
    fundingMin          : -5,
    fundingMax          : 5,
    testMaxQty          : 0,
    repeatTimeLimit     : 60,
    tpsNumber           : 1,
    tps                 : [
      {
        offset   : 1,
        amount   : 100,
        breakeven: false,
      } as ITakeProfit,
    ],

    //for usual stoploss
    slType  : 'USUAL',
    slOffset: 1,
    //for trailing stoploss
    trailingOffset: 0.1,
  };

  const {
          values,
          // handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
          // setValues,
          setFieldError,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : addNewChannel,
  });

  function calculateAmount(tps: any) {
    if (tps?.length) {
      let sum = tps?.map((o: any) => o.amount).reduce((partialSum: number, a: number) => partialSum + a, 0);
      if (sum + tps[0].amount < 100) {
        return tps[0].amount;
      } else {
        return 100 - sum;
      }
    }
    return 100;
  }

  function addNewChannel() {
    //Проверка заполнения сигнальных слов
    if (values.signalWordsLong === '' && values.signalWordsShort === '') {
      setFieldError(
        'signalWordsLong',
        'Одно из сигнальных полей должно быть заполнено!',
      );
      setFieldError(
        'signalWordsShort',
        'Одно из сигнальных полей должно быть заполнено!',
      );
      return;
    }

    //Проверка суммы кол-ва ТП
    const tpAmountSum = values.tps.reduce((prev, cur) => prev + cur.amount, 0);
    if (tpAmountSum !== 100 && +tpAmountSum) {
      setFieldError(
        'tpsNumber',
        'Сумма кол-ва в тейкпрофитах должна быть равна 100',
      );
      return;
    }

    const telegramOptions: any = {
      telegramSettings: {
        channelName     : values.telegramChannelTitle,
        signalWordsLong : values.signalWordsLong,
        signalWordsShort: values.signalWordsShort,
        closeWords      : values.closeWords,
        workingType     : values.workingType,
      },
      binanceSettings : {
        leverage       : values.leverage,
        leverageSpot   : values.leverageSpot,
        hedgeSpot      : values.hedgeSpot,
        whiteList      : values.whiteList,
        blockList      : values.blockList,
        positionSize   : values.positionSize,
        fundingMin     : values.fundingMin,
        fundingMax     : values.fundingMax,
        testMaxQty     : values.testMaxQty,
        repeatTimeLimit: values.repeatTimeLimit,
        tps            : values.tps,
        sl             : {
          type  : values.slType,
          amount: 100,
        },
      },
      bybitSettings   : {
        leverage       : values.leverage,
        leverageSpot   : values.leverageSpot,
        hedgeSpot      : values.hedgeSpot,
        whiteList      : values.whiteList,
        blockList      : values.blockList,
        positionSize   : values.positionSize,
        fundingMin     : values.fundingMin,
        fundingMax     : values.fundingMax,
        testMaxQty     : values.testMaxQty,
        repeatTimeLimit: values.repeatTimeLimit,
        tps            : values.tps,
        sl             : {
          type  : values.slType,
          amount: 100,
        },
      },
    };

    if (values.slType === 'USUAL') {
      telegramOptions.binanceSettings.sl.offset = values.slOffset;
    } else {
      telegramOptions.binanceSettings.sl.offset = values.trailingOffset;
    }

    //Проверка SL
    if ( !telegramOptions.binanceSettings.sl.offset) {
      setFieldError(
        'slOffset',
        'Необходимо указать значение STOP LOSS',
      );
      return;
    }

    for (let i = 0; i < telegramOptions.binanceSettings.tps.length; i++) {
      if (
        typeof telegramOptions.binanceSettings.tps[i].breakeven ===
        'undefined' ||
        telegramOptions.binanceSettings.sl.type === 'TRAILING'
      ) {
        telegramOptions.binanceSettings.tps[i].breakeven = false;
      }
    }

    console.log(telegramOptions);

    dispatch(telegramActions.addChannel(telegramOptions)).then(() => {
      onHide();
    });
  }

  const getTpsForm = useCallback(() => {
    return values.tps.map((tp, index) => {
      return (
        <Row key={`tp setting ${index}`} className="align-items-center mb-3">
          <Col
            xs={12}
            md={5}
            lg={5}
            className="justify-content-center align-items-center"
          >
            <InputGroup>
              <Form.Control
                aria-describedby={`tp-offset-${index}`}
                value={tp.offset}
                type="number"
                min={0}
                onChange={(e) => {
                  let tpsArr           = [...values.tps];
                  tpsArr[index].offset = Number(e.target.value);
                  setTempTps(tpsArr);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={5} lg={5}>
            <InputGroup>
              <Form.Control
                aria-describedby={`tp-amount-${index}`}
                value={tp.amount}
                type="number"
                max={100}
                min={0}
                onChange={(e) => {
                  let tpsArr           = [...values.tps];
                  tpsArr[index].amount = Number(e.target.value);
                  setTempTps(tpsArr);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={2} lg={2}>
            <Form.Check
              type="switch"
              id={`breakeven-checkbox-${index}`}
              label="б/у"
              checked={tp.breakeven}
              disabled={(() => {
                return (
                  (values.tps.map((data) => data.breakeven).includes(true) &&
                    !values.tps[index].breakeven) ||
                  values.slType === 'TRAILING'
                );
              })()}
              onChange={(e) => {
                let tpsArr              = [...values.tps];
                tpsArr[index].breakeven = e.target.checked;
                setTempTps(tpsArr);
              }}
            />
          </Col>
        </Row>
      );
    });
  }, [values]);

  useEffect(() => {
    if ( !values?.tps?.length) {
      setFieldValue('tps', tempTps);
    }
  }, [dispatch, setFieldValue, tempTps, values]);

  if (isBinanceLoading || isBybitLoading || isTelegramLoading) {
    return <h2 className="p-1">Загрузка...</h2>;
  }

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавление Telegram канала
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <h2>Telegram-Binance торговля</h2>
        </Row>
        <TelegramSettings
          values={values}
          titleOnChange={handleChange('telegramChannelTitle')}
          signalWordsLongOnChange={handleChange('signalWordsLong')}
          signalWordsShortOnChange={handleChange('signalWordsShort')}
          closeWordsOnChange={handleChange('closeWords')}
          workingTypeOnChange={handleChange('workingType')}
          errors={errors}
          touched={touched}
        ></TelegramSettings>
        <div className="binance-settings">
          <Row>
            <Col>
              <h4>Настройка Binance</h4>
              <p>Торговля производится только в режиме хэджирования</p>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row className={values.blockList && !values.whiteList ? 'hiding' : ''}>
            <Col>
              <p>Whitelist: <span className="hint">(BTCUSDT, ETHUSDT, ...)</span></p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="whiteList"
                    value={values.whiteList}
                    type="text"
                    onChange={handleChange('whiteList')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row className={values.whiteList && !values.blockList ? 'hiding' : ''}>
            <Col>
              <p>Blocklist: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="blockList"
                    value={values.blockList}
                    type="text"
                    onChange={handleChange('blockList')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <Row>
            <Col>
              <p>Плечо:</p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="leverage"
                    value={values.leverage}
                    min={1}
                    max={125}
                    type="number"
                    onChange={handleChange('leverage')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Размер позиции: </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="positionSize"
                    value={values.positionSize}
                    type="number"
                    min={1}
                    onChange={handleChange('positionSize')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Cтавка финансирования (диапазон, %): </p>
            </Col>
            <Col>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Form.Control
                    aria-describedby="fundingMin"
                    value={values.fundingMin}
                    type="number"
                    min={-5.0}
                    max={5.0}
                    step="0.01"
                    onChange={handleChange('fundingMin')}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Form.Control
                    aria-describedby="fundingMax"
                    value={values.fundingMax}
                    type="number"
                    min={-5.0}
                    max={5.0}
                    step="0.01"
                    onChange={handleChange('fundingMax')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>(Тест) max позиция ($): </p>
            </Col>
            <Col>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Form.Control
                    aria-describedby="testMaxQty"
                    value={values.testMaxQty}
                    type="number"
                    onChange={handleChange('testMaxQty')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Ограничение для повторных сделок (минут): </p>
            </Col>
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-describedby="repeatTimeLimit"
                    value={values.repeatTimeLimit}
                    type="number"
                    min={1}
                    step="1"
                    onChange={handleChange('repeatTimeLimit')}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Row>
          <hr className="hr-double" />
          <div className="tps">
            <Row>
              <Col>
                <h5>Тейкпрофиты</h5>
              </Col>
              {errors.tpsNumber && (
                <div className="input-feedback">{errors.tpsNumber}</div>
              )}
            </Row>
            <Row>
              <Col>
                <p>Кол-во тейкпрофитов: </p>
              </Col>
              <Col>
                <Row>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby="tpsNumber"
                      value={values.tpsNumber}
                      type="number"
                      min={0}
                      max={4}
                      onChange={(e) => {
                        let tpsArr          = [...values.tps];
                        let validatedNumber = Number(e.target.value);
                        if (validatedNumber > 4) {
                          validatedNumber = 4;
                        }
                        if (validatedNumber < 0) {
                          validatedNumber = 0;
                        }

                        for (let i = 0; i < validatedNumber; i++) {
                          if (tpsArr.length === validatedNumber) {
                            break;
                          }

                          if (validatedNumber < tpsArr.length) {
                            tpsArr.pop();
                          } else {
                            let amount = calculateAmount(values.tps);
                            if (amount > 0) {
                              tpsArr.push({
                                offset   : +Number(values.tps?.length ? values.tps[0].offset * validatedNumber : 1).toFixed(5),
                                amount   : amount,
                                breakeven: false,
                              });
                            } else {
                              validatedNumber = tpsArr.length;
                            }
                          }
                        }

                        setFieldValue('tpsNumber', validatedNumber);
                        setFieldValue('tps', tpsArr);
                      }}
                    />
                  </InputGroup>
                </Row>
              </Col>
              <div className="tps-settings">
                <Row>
                  <Col xs={12} md={5} lg={5}>
                    <p>Отклонение (%)</p>
                  </Col>
                  <Col xs={12} md={5} lg={5}>
                    <p>Количество (%)</p>
                  </Col>
                  <Col></Col>
                </Row>
                {getTpsForm()}
              </div>
            </Row>
          </div>
          <hr className="hr-double" />
          <div className="sls">
            <Row>
              <Col>
                <h5>Стоплосс</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Тип:</p>
              </Col>
              <Col>
                <Form.Select
                  className="mb-3"
                  aria-label="sl-type"
                  value={values.slType}
                  onChange={handleChange('slType')}
                >
                  <option value="USUAL">Обычный</option>
                  <option value="TRAILING">Трейлинг</option>
                </Form.Select>
              </Col>
            </Row>
            <hr />
            {errors.slOffset && (
              <div className="input-feedback">{errors.slOffset}</div>
            )}
            {values.slType === 'USUAL' ? (
              <Row>
                <Col>
                  <p>Отклонение (%)</p>
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-describedby={`sl-offset`}
                      value={values.slOffset}
                      type="number"
                      onChange={handleChange('slOffset')}
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : (
               <div className="trailing-stoploss">
                 <Row>
                   <Col>
                     <p>Отклонение трейлинга(%):</p>
                   </Col>
                   <Col>
                     <InputGroup className="mb-3">
                       <Form.Control
                         aria-describedby="trailingOffset"
                         value={values.trailingOffset}
                         type="number"
                         min={0.1}
                         max={5}
                         step={0.1}
                         onChange={handleChange('trailingOffset')}
                       />
                     </InputGroup>
                   </Col>
                 </Row>
               </div>
             )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={addNewChannel}>
          Добавить
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddChannelModal;