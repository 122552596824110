import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {getAccount} from '../../../store/reducers/Binance/BinanceActionCreator';
import {Row, Button, Col} from 'react-bootstrap';
import APITable from '../../../components/common/APITable/APITable';
import ConnectBinanceModal from '../../../components/modals/ConnectBinanceModal/ConnectBinanceModal';
import './BinanceConnect.css';

const BinanceConnect = () => {
  const dispatch                = useAppDispatch();
  let {binanceAccount}          = useAppSelector((state) => state.binanceReducer);
  let [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  return (
    <div className="connect-wrapper">
      <div className="module-header">
        <Row>
          <Col className="">
            <h3>BINANCE</h3>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button onClick={() => setShowModal(true)}>
              Добавить ключ API
            </Button>
          </Col>
        </Row>
        <hr />
      </div>
      <APITable
        data={{
          'api key'   : binanceAccount.key,
          'api secret': binanceAccount.secret?.replace('Ue9uu9Uy9', 'ucSYsXW6q')?.replace('ahvieGh9g', '1lup2FbfV'),
          // "test": binanceAccount?.test,
        }}
      />
      <ConnectBinanceModal showModal={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default BinanceConnect;