import React from 'react';
import {Button, Form, Row, Col, Table, Container, InputGroup} from 'react-bootstrap';
import {useAppDispatch} from '../../../../hooks/redux';
import {
  autoModeParamsSave,
  autoModeParamsGet,
} from '../../../../store/reducers/Binance/BinanceActionCreator';

import './DashboardOrders.css';

interface IProps {
  active: any;
  user: any;
  values?: any;
  dispatch: any;
}

interface IState {
  test: number;
  values?: any;
}

class DashboardAutoParamsTab extends React.Component<IProps, IState> {

  public user: any;
  public values: any;
  public dispatch: any;
  public active: any;
  public state: any;

  constructor(props: IProps, state: IState) {
    super(props);
    this.user     = props?.user;
    this.dispatch = props?.dispatch;
    this.active   = props?.active;
    this.state    = state;
    console.info('state', state);
  }

  componentDidMount() {
    console.info('componentDidMount', this.props);
    this.dispatch(autoModeParamsGet()).then((r: any) => {
      this.values = r?.payload?.data;
    });
  }

  handleChange(field: any, value: any): any {
    if ( !this.values) {
      this.values = {};
    }
    this.values[field] = value;
    this.clickAutoModeParamsSave(this.values);
  }

  clickAutoModeParamsSave(values: any) {
    this.dispatch(autoModeParamsSave(values));
  };

  checkValues() {
    if ( !this.values || !Object.keys(this.values).length) {
      this.values = {};
    }
  }

  onClickReset() {
    this.values = {};
    this.dispatch(autoModeParamsSave({})).then((r: any) => {
      this.dispatch(autoModeParamsGet()).then((r: any) => {
        this.values = r?.payload?.data;
        this.checkValues();
      });
    });
  }

  render() {

    const {
            user,
            active,
          } = this.props;

    this.checkValues();
    // console.info('!!! values', this.values);

    return (
      <Container className="autobot-form justify-content-md-center justify-content-lg-center">
        <Table>
          <Row>
            <Col xs={4} md={4} lg={4} className="autoform-label">
              Enable
            </Col>
            <Col xs={3} md={3} lg={3} className="float-start">
              <InputGroup>
                <Form.Check
                  type="switch"
                  key={'enable1'}
                  id={'enable1'}
                  name={'enable1'}
                >
                  <Form.Check.Input
                    className=""
                    key={'enable'}
                    id={'enable'}
                    name={'enable'}
                    value="true"
                    type="checkbox"
                    defaultChecked={this.values?.enable}
                    onClick={(e: any) => this.handleChange('enable', e?.target?.checked)}
                  />
                </Form.Check>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4} className="autoform-label">
              Max deals
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'max'}
                              id={'max'}
                              name={'max'}
                              required={true}
                              defaultValue={this.values.max}
                              onChange={(e) => this.handleChange(`max`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4} className="autoform-label">
              Mark Price (diff, %)
            </Col>
            <Col xs={3} md={3} lg={3}>
              <InputGroup>
                {/*<span className="mx-3">Min</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'markPriceDiff1'}
                              id={'markPriceDiff1'}
                              name={'markPriceDiff1'}
                              step={0.1}
                              required={true}
                              defaultValue={this.values.markPriceDiff1}
                              onChange={(e) => this.handleChange(`markPriceDiff1`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <InputGroup>
                {/*<span className="mx-3">Max</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.1}
                              key={'markPriceDiff2'}
                              id={'markPriceDiff2'}
                              name={'markPriceDiff2'}
                              required={true}
                              defaultValue={this.values.markPriceDiff2}
                              onChange={(e) => this.handleChange(`markPriceDiff2`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4} className="autoform-label">
              Interest Rate (%)
            </Col>
            <Col xs={3} md={3} lg={3} className="float-start">
              <InputGroup>
                {/*<span className="mx-3">Min</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.0001}
                              key={'interestRate1'}
                              id={'interestRate1'}
                              name={'interestRate1'}
                              required={true}
                              defaultValue={this.values.interestRate1}
                              onChange={(e) => this.handleChange(`interestRate1`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <InputGroup>
                {/*<span className="mx-3">Max</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.0001}
                              key={'interestRate2'}
                              id={'interestRate2'}
                              name={'interestRate2'}
                              required={true}
                              defaultValue={this.values.interestRate2}
                              onChange={(e) => this.handleChange(`interestRate2`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Funding Rate (%)
            </Col>
            <Col xs={3} md={3} lg={3} className="float-start">
              <InputGroup>
                {/*<span className="mx-3">Min</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.01}
                              key={'fundingRate1'}
                              id={'fundingRate1'}
                              name={'fundingRate1'}
                              required={true}
                              defaultValue={this.values.fundingRate1}
                              onChange={(e) => this.handleChange(`fundingRate1`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <InputGroup>
                {/*<span className="mx-3">Max</span>*/}
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.01}
                              key={'fundingRate2'}
                              id={'fundingRate2'}
                              name={'fundingRate2'}
                              required={true}
                              defaultValue={this.values.fundingRate2}
                              onChange={(e) => this.handleChange(`fundingRate2`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Price (%)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              step={0.01}
                              key={'price'}
                              id={'price'}
                              name={'price'}
                              required={true}
                              defaultValue={this.values.price}
                              onChange={(e) => this.handleChange(`price`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Volume ($)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'volume'}
                              id={'volume'}
                              name={'volume'}
                              required={true}
                              defaultValue={this.values.volume}
                              onChange={(e) => this.handleChange(`volume`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Leverage
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'leverage'}
                              id={'leverage'}
                              name={'leverage'}
                              required={true}
                              defaultValue={this.values.leverage}
                              onChange={(e) => this.handleChange(`leverage`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Take Profit (%)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'tp'}
                              id={'tp'}
                              name={'tp'}
                              required={true}
                              defaultValue={this.values.tp}
                              onChange={(e) => this.handleChange(`tp`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Stop Loss (%)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'sl'}
                              id={'sl'}
                              name={'sl'}
                              required={true}
                              defaultValue={this.values.sl}
                              onChange={(e) => this.handleChange(`sl`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr
                style={{
                  // background : 'lime',
                  color      : 'gray',
                  borderColor: 'silver',
                  height     : '1px',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Max Opening Time (s)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'maxOpeningTime'}
                              id={'maxOpeningTime'}
                              name={'maxOpeningTime'}
                              required={true}
                              defaultValue={this.values.maxOpeningTime}
                              onChange={(e) => this.handleChange(`maxOpeningTime`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Funding Time (s)
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'fundingTime'}
                              id={'fundingTime'}
                              name={'fundingTime'}
                              required={true}
                              defaultValue={this.values.fundingTime}
                              onChange={(e) => this.handleChange(`fundingTime`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Tick Steps
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'tickSteps'}
                              id={'tickSteps'}
                              name={'tickSteps'}
                              required={true}
                              defaultValue={this.values.tickSteps}
                              onChange={(e) => this.handleChange(`tickSteps`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Max Attempts
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'maxAttempts'}
                              id={'maxAttempts'}
                              name={'maxAttempts'}
                              required={true}
                              defaultValue={this.values.maxAttempts}
                              onChange={(e) => this.handleChange(`maxAttempts`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4}>
              Max Attempts Check Position
            </Col>
            <Col className="float-start" xs={3} md={3} lg={3}>
              <InputGroup>
                <Form.Control className=""
                              size="sm"
                              inputMode="decimal"
                              type="number"
                              key={'maxAttemptsPosition'}
                              id={'maxAttemptsPosition'}
                              name={'maxAttemptsPosition'}
                              required={true}
                              defaultValue={this.values.maxAttemptsPosition}
                              onChange={(e) => this.handleChange(`maxAttemptsPosition`, e?.target?.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr
                style={{
                  color      : 'gray',
                  borderColor: 'silver',
                  height     : '1px',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="centered py-2">
              <Button variant="outline-secondary" onClick={() => { this.onClickReset(); }} size="sm" className={'px-5'}>
                Reset
              </Button>
            </Col>
          </Row>
        </Table>
      </Container>
    );
  }
}

export default DashboardAutoParamsTab;