import {AxiosResponse} from 'axios';
import $api from '../http';
import {IExchangeAccount} from '../types/IExchangeAccount';
import {IExchangeOrderBase, IExchangeOrderNew} from '../types/IExchangeOrder';

export default class BinanceService {
  static async connect(
    key: string,
    secret: string,
  ): Promise<AxiosResponse<IExchangeAccount>> {
    return $api.post<IExchangeAccount>('/binance/connect', {
      key,
      secret,
    });
  }

  static async getAccount(): Promise<AxiosResponse<IExchangeAccount>> {
    return $api.get<IExchangeAccount>('/binance/getAccount');
  }

  static async getNotifications(uid: any, mode: any, created: any): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/getNotifications?uid=' + uid + '&mode=' + mode + '&created=' + created);
  }

  static async getDashboard(part: any): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/getDashboard?part=' + part);
  }

  static async transferFunds(item: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/transferFunds', item);
  }

  static async closeSpotPosition(item: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/closeSpotPosition', item);
  }

  static async restoreSpotPosition(item: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/restoreSpotPosition', item);
  }

  static async savePosition(item: any): Promise<AxiosResponse<any>> {
    return $api.post<IExchangeOrderBase>('/binance/savePosition', item);
  }

  static async autoModeParamsGet(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/autoModeParamsGet');
  }

  static async autoModeParamsSave(data: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/autoModeParamsSave', data);
  }

  static async autoModeGet(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/autoModeGet');
  }

  static async autoModeSave(data: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/autoModeSave', data);
  }

  static async autoModeStart(data: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/autoModeStart', data);
  }

  static async setMode(): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/setMode');
  }

  static async groupClose(group: any): Promise<AxiosResponse<IExchangeOrderBase>> {
    return $api.post<IExchangeOrderBase>('/binance/groupClose', group);
  }

  static async orderClose(order: IExchangeOrderBase): Promise<AxiosResponse<IExchangeOrderBase>> {
    return $api.post<IExchangeOrderBase>('/binance/orderClose', order);
  }

  static async closeCurrentOrders(position: IExchangeOrderBase): Promise<AxiosResponse<IExchangeOrderBase>> {
    return $api.post<IExchangeOrderBase>('/binance/closeCurrentOrders', position);
  }

  static async orderCreate(order: IExchangeOrderNew): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/orderCreate', order);
  }

  static async orderStart(order: IExchangeOrderNew): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/orderStart', order);
  }

  static async orderPrepare(order: IExchangeOrderNew): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/orderPrepare', order);
  }

  static async templateSave(data: any): Promise<AxiosResponse<any>> {
    return $api.post<any>('/binance/templateSave', data);
  }

  static async getTemplate(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/getTemplate');
  }

  static async getSymbols(): Promise<AxiosResponse<any>> {
    return $api.get<any>('/binance/getSymbols');
  }

}