import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Modal, Button, Container, Form, InputGroup, Table} from 'react-bootstrap';
import * as Yup from 'yup';

import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  faSun,
  // faMoneyBill1,
} from '@fortawesome/free-regular-svg-icons';

import {getDashboard, transferFunds, closeSpotPosition, restoreSpotPosition} from '../../../../store/reducers/Binance/BinanceActionCreator';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
// import {SocketContext} from '../../../../components/system/ws/context/context';
import './DashboardTotals.css';

const DashboardTotals = () => {
  const dispatch                                    = useAppDispatch();
  let {dashboard}                                   = useAppSelector((state) => state.binanceReducer);
  let [transferFundsValues, setTransferFundsValues] = useState<any>(undefined);
  let [disabled, setDisabled]                       = useState<boolean>(false);
  const VALIDATION_SCHEMA                           = Yup.object().shape({});
  const initialValues: any                          = {};

  const notify = async () => {

    const sound = () => {
      // const audio = new Audio('https://test.cmatic.pro/sounds/mixkit-message-pop-alert-2354.mp3');
      const audio = new Audio('https://test.cmatic.pro/sounds/mixkit-correct-answer-tone-2870.wav');
      return audio.play();
    }

    setTimeout(() => {
      toast.info('[OPEN] FUTURES LIMIT BNBUSDT SHORT SELL x1 PRICE 583.00 QUANTITY 0.02 BNBUSDT ( 11.66$ )', {
        position       : 'top-right',
        // autoClose      : document?.hidden ? undefined : 5000,
        hideProgressBar: true,
        // closeOnClick   : true,
        // pauseOnHover   : true,
        // draggable      : true,
        progress       : undefined,
        theme          : 'colored',
        transition: Flip,
      });
      sound();
    }, 100);

  };

  const doCloseSpotPosition = (asset: any, amount: any) => {

    if (amount = window.prompt('Warning! Closing all orders and returning [ ' + (amount ? amount : 'ALL') + ' ' + asset + ' ] to the USDT wallet! Are you agree?', amount)) {
      dispatch(closeSpotPosition({
        asset,
        amount,
      }));
    }

  };

  const doRestoreSpotPosition = (asset: any, amount: any) => {
    dispatch(restoreSpotPosition({
      asset,
      amount,
    }));
  };

  const onTransferFunds = (v: any) => {
    console.info('onTransferFunds values', v);
    setDisabled(true);
    dispatch(transferFunds(v)).then((r: any) => {
      setTransferFundsValues(undefined);
      setDisabled(false);
      dispatch(getDashboard(1)).then((r: any) => {
        setValues({});
      });
    });
  };

  const onTransferFunds2 = (values: any) => {
    console.info('onTransferFunds2 values', values);
    setDisabled(true);
    dispatch(transferFunds(values)).then((r: any) => {
      // setTransferFundsValues(undefined);
      setDisabled(false);
      dispatch(getDashboard(1)).then((r: any) => {
        setValues({});
      });
    });
  };

  const {
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          // errors,
          // touched,
          setValues,
          // setFieldError,
        } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema  : VALIDATION_SCHEMA,
    onSubmit          : onTransferFunds2,
  });

  const fin = (x: any, defaultValue: any = 0, digits: any = 4, currency = '') => {
    return (+x ? +Number.parseFloat(x).toFixed(digits) : (defaultValue ? +Number.parseFloat(defaultValue).toFixed(digits) : '')) + (+x && currency ? ' ' + currency : '');
  };

  useEffect(() => {

    if (dashboard) {
      if (dashboard?.SPOT && dashboard?.SPOT?.balances) {
        for (const b of dashboard?.SPOT?.balances) {
          if (values['FUTURES_' + b.asset] === undefined && +b?.totalAmount) {
            values['FUTURES_' + b.asset] = b?.totalAmount;
          }
        }
      }
      if (dashboard?.FUTURES && dashboard?.FUTURES?.wallets) {
        for (const b of dashboard?.FUTURES?.wallets) {
          if (values['SPOT_' + b.asset] === undefined && +b?.maxWithdrawAmount) {
            values['SPOT_' + b.asset] = b?.maxWithdrawAmount;
          }
        }
      }
    }

  }, [values, dispatch, dashboard]);

  return (
    <Container className="bg-light rounded-2 p-2 m-2" fluid>
      <ToastContainer position="top-right"
                      // autoClose={5000}
                      hideProgressBar={false}
                      // stacked={true}
                      // newestOnTop={true}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                      transition={Flip} />
      {/*<button onClick={notify}>Notify!</button>*/}
      <div hidden={dashboard?.success}
           className="height-100percent terminal-online-alert bg-body-secondary rounded-3 border-info border-2 m-0 p-4 text-primary font-monospace">
        <div className="centered centered-icon-loading align-content-center align-self-center  ">
          <FontAwesomeIcon icon={faSun} size="1x" className="icon-loading" />
          <p> Loading ... </p>
        </div>
        {/*Connecting to API ...*/}
        {/*<br />*/}
        {/*( please wait a bit for auto reconnect or try to reload manually later )*/}
      </div>
      <Table striped bordered hover className={'wallet-tbl'} hidden={ !dashboard?.success}>
        <thead>
        <tr>
          <td className="plain-td1">Тип</td>
          <td className="plain-td2">Дата изменения баланса</td>
          <td>Доступный баланс кошелька</td>
          <td>Общий баланс кошелька</td>
          <td>Баланс маржи</td>
          <td className="plain-td1">PNL</td>
        </tr>
        </thead>
        <tbody>
        <tr className={(1 || +dashboard?.TOTAL?.updateTime ? '' : 'hidden')}>
          <th>
            TOTAL
          </th>
          <th>{new Date(dashboard?.TOTAL?.updateTime).toLocaleDateString()} {new Date(dashboard?.TOTAL?.updateTime).toLocaleTimeString()}
          </th>
          <th>
            {fin(dashboard?.TOTAL?.availableBalance, 0, 3)} <sup>&#x24;</sup>
            {/*<br />*/}
            {/*<sup>USDT</sup>*/}
          </th>
          <th>
            {fin(dashboard?.TOTAL?.walletBalance, 0, 3)} <sup>&#x24;</sup>
            {/*<br />*/}
            {/*<sup>USDT</sup>*/}
          </th>
          <th className="spot-main-total">
            {fin(+dashboard?.TOTAL?.marginBalance, 0, 3)} <sup>&#x24;</sup>
            <br />
            <span className="font-small2">TOTAL</span>
          </th>
          <th className={'spot-main-total ' + (Number(+dashboard?.TOTAL?.pnl || 0) > 0 ? 'pnl-plus' : 'pnl-minus')}>
            <span hidden={ !+dashboard?.TOTAL?.pnl}>
              {fin(+dashboard?.TOTAL?.pnl, 0, 3)} <sup>&#x24;</sup>
              <br />
              <span className="font-small2">TOTAL</span>
            </span>
          </th>
        </tr>
        <tr className={(+dashboard?.wallet?.updateTime ? '' : 'hidden')}>
          <th>
            FUTURES
            <Button onClick={() => {
              let v = {
                amount    : +dashboard?.wallet?.availableBalance || 0,
                asset     : dashboard?.wallet?.asset || 'USDT',
                derivative: 'SPOT',
              };
              console.info('FUTURES -> SPOT : dashboard.wallet', v, dashboard?.wallet);
              setValues(v);
              setFieldValue('amount', +dashboard?.wallet?.availableBalance || 0);
              setTransferFundsValues(v);
            }}
                    variant="light"
                    className="border-black fa-pull-right"
                    title="Переместить средства на SPOT"
                    disabled={disabled}
            >
              &#8615;
            </Button>
          </th>
          <th>{new Date(dashboard?.wallet?.updateTime).toLocaleDateString()} {new Date(dashboard?.wallet?.updateTime).toLocaleTimeString()}
          </th>
          <th>
            {fin(dashboard?.wallet?.availableBalance, 0, 3)} <sup>&#x24;</sup>
            {/*<br />*/}
            {/*<sup>{dashboard?.wallet?.asset}</sup>*/}
          </th>
          <th>
            {fin(dashboard?.wallet?.walletBalance, 0, 3)} <sup>&#x24;</sup>
            {/*<br />*/}
            {/*<sup>{dashboard?.wallet?.asset}</sup>*/}
          </th>
          <th className="spot-main-total">
            {fin(+dashboard?.wallet?.marginBalance || +dashboard?.wallet?.walletBalance, 0, 3)} <sup>&#x24;</sup>
            <br />
            <span className="font-small2">FUTURES TOTAL</span>
            {/*<sup>{dashboard?.wallet?.asset}</sup>*/}
          </th>
          <th className={'spot-main-total ' + (Number(+dashboard?.wallet?.pnl || 0) > 0 ? 'pnl-plus' : ((+dashboard?.wallet?.unrealizedProfit || 0) < 0 ? 'pnl-minus' : ''))}>
            <span hidden={ !+dashboard?.wallet?.unrealizedProfit}>
              {fin(+dashboard?.wallet?.unrealizedProfit, 0, 3)} <sup>&#x24;</sup>
              <br />
              <span className="font-small2">FUTURES TOTAL</span>
              {/*<sup>{dashboard?.wallet?.asset}</sup>*/}
            </span>
          </th>
        </tr>
        {dashboard?.FUTURES?.wallets?.filter((o: any) => o.asset !== 'USDT')?.map((wallet: any, index: number) => {
          return (
            <>
              <tr>
                <th>
                  <span className="float-end">{wallet?.asset}</span>
                </th>
                <th>{new Date(wallet?.updateTime).toLocaleDateString()} {new Date(wallet?.updateTime).toLocaleTimeString()}</th>
                <th>{+wallet?.availableBalance} <sup>{wallet?.asset}</sup></th>
                <th>{+wallet?.maxWithdrawAmount} <sup>{wallet?.asset}</sup></th>
                <th>
                  {+wallet?.marginBalanceUsdt} <sup>$</sup>
                  <br />{+wallet?.marginBalance} <sup>{wallet?.asset}</sup>
                </th>
                <th className={+wallet.unrealizedProfit >= 0 ? 'pnl-plus' : 'pnl-minus'}>
                  <span hidden={ !+wallet?.unrealizedProfit}>
                    {fin(+wallet?.unrealizedProfitUsdt, 0, 5)} <sup>$</sup>
                    <br />[{fin(wallet?.unrealizedProfit, 0, 5)} <sup>{wallet?.asset}</sup>]
                  </span>
                </th>
              </tr>
            </>
          );
        })
        }
        {dashboard?.SPOT?.balances?.filter((o: any) => +o.totalUSDT >= 5 || o.asset === 'USDT')?.map((balance: any, index: number) => {
          return (
            <>
              <tr key={'tr1_' + balance?.asset} className={(+dashboard?.binanceAccount?.updateTime ? '' : 'hidden')}>
                <th hidden={index > 0} rowSpan={+dashboard?.binanceAccount?.balances?.length}>
                  SPOT
                  <Button
                    hidden={balance.asset.indexOf('USD') === -1}
                    onClick={() => {
                      let v = {
                        amount    : balance?.available || 0,
                        asset     : balance?.asset || 'USD',
                        derivative: 'FUTURES',
                      };
                      setValues(v);
                      setTransferFundsValues(v);
                    }}
                    variant="light"
                    className="border-black fa-pull-right"
                    title="Переместить средства на FUTURES"
                    disabled={disabled}
                  >
                    &#8613;
                  </Button>
                </th>
                <th hidden={index > 0}
                    rowSpan={+dashboard?.binanceAccount?.balances?.length}>
                  {new Date(dashboard?.binanceAccount?.updateTime).toLocaleDateString()} {new Date(dashboard?.binanceAccount?.updateTime).toLocaleTimeString()}
                </th>
                {/*<th rowSpan={+dashboard?.binanceAccount?.balances?.length}>{new Date(dashboard?.binanceAccount?.updateTime).toLocaleDateString()} {new Date(dashboard?.binanceAccount?.updateTime).toLocaleTimeString()}</th>*/}
                <th className="balance-td">
                  <table width={'100%'}>
                    <tr>
                      <td>
                        {+balance.available}
                        <sup> {balance.asset}</sup>
                        <br />
                        <sup hidden={ !+balance.onOrder}>{+balance.onOrder ? ' [ locked ' + +balance.onOrder + ' ' + balance.asset + ' ]' : ''}</sup>
                      </td>
                      <td>
                      </td>
                      <td className="tousdt fa-pull-right" width="250">
                        <span className="font-small float-end"
                              hidden={balance?.asset === 'USDT' || +balance?.totalUSDT >= 5}>
                          <a href="#/" title="too low value, required manual transfer to USDT">*</a>
                        </span>
                        <span hidden={balance?.asset?.indexOf('USDT') !== -1 || +balance.totalUSDT < 5}
                              className="float-end"
                        >
                          {/*<InputGroup className="" size="sm">*/}
                          {/*  <Form.Control placeholder="... to SPOT USDT"*/}
                          {/*                type="number"*/}
                          {/*                id="tousdt"*/}
                          {/*                name="tousdt"*/}
                          {/*                disabled={true}*/}
                          {/*                defaultValue={+balance.totalAmount}*/}
                          {/*                onChange={handleChange('tousdt')} />*/}
                          <Button
                            onClick={() => doCloseSpotPosition(balance?.asset, balance?.totalAmount)}
                            variant="danger"
                            className="border-black"
                            title={'Удалить позицию SPOT ' + balance?.asset}
                            disabled={disabled}
                          >
                            {/*&#8613;*/}
                            <span className="">
                              &#9746;
                            </span>
                          </Button>
                          {/*</InputGroup>*/}
                        </span>
                        <span hidden={((+balance.totalUSDT < 5) || balance?.asset !== 'BTC')}
                              className="float-end mx-2"
                        >
                          <Button
                            onClick={() => doRestoreSpotPosition(balance?.asset, balance?.totalAmount)}
                            variant="success"
                            className="border-black"
                            title={'Добавить SPOT OCO ' + balance?.asset}
                            disabled={disabled}
                            hidden={true}
                          >
                            {/*&#8613;*/}
                            <span className="">
                              &#43; OCO
                            </span>
                          </Button>
                          {/*</InputGroup>*/}
                        </span>
                      </td>
                    </tr>
                  </table>
                </th>
                <th>
                  {+fin(+balance.total, 0, 3)}
                  <sup> {balance.asset}</sup>
                  <span hidden={balance.asset === 'USDT'}>
                    / {+fin(+balance.totalUSDT, 0, 3)} <sup>&#x24;</sup>
                  </span>
                </th>
                <th className={balance.asset === 'USDT' ? 'spot-main-total' : ''}>
                  {+fin(+balance.marginBalance, 0, 3)} <sup>&#x24;</sup>
                  <span hidden={balance.asset !== 'USDT'} className="font-small2"><br />SPOT TOTAL</span>
                  {/*<span>USDT</span>*/}
                </th>
                <th className={balance.asset === 'USDT' ? 'spot-main-total' : ''}>
                  <span hidden={ !+balance.pnl} className={+balance.pnl >= 0 ? 'pnl-plus' : 'pnl-minus'}>
                    {+fin(+balance.pnl, 3) || ''} <sup>&#x24;</sup>
                    <span hidden={balance.asset !== 'USDT'} className="font-small2"><br />SPOT TOTAL</span>
                    {/*<span>USDT</span>*/}
                  </span>
                </th>
              </tr>
            </>
          );
        })}
        </tbody>
      </Table>
      <Modal show={transferFundsValues !== undefined}
             setValues={setValues}
        // onHide={() => { setTransferFundsValues(undefined); }}
        // onSubmit={() => onTransferFunds(values, transferFundsValues)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{'Переместить средства '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <strong>{(transferFundsValues?.derivative === 'SPOT' ? 'FUTURES' : 'SPOT')} &nbsp; &minus; &gt; &nbsp;  {(transferFundsValues?.derivative === 'SPOT' ? 'SPOT' : 'FUTURES')}</strong>
          </center>
          <Container className="bg-light rounded-2 p-2 m-2" fluid>
            <Table striped bordered hover>
              <tbody>
              <tr>
                <td className="centered">
                  <InputGroup className="mb-0" size="lg">
                    <Form.Control className="m-0"
                                  disabled={disabled}
                                  inputMode="decimal"
                                  key="amount"
                                  id="amount"
                                  name="amount"
                                  aria-describedby="amount"
                                  value={values.amount}
                                  defaultValue={transferFundsValues?.amount}
                                  min={1}
                                  max={transferFundsValues?.amount}
                                  type="number"
                                  required={true}
                                  onChange={(e) => {
                                    setFieldValue('amount', transferFundsValues['amount'] = e.target.value);
                                  }}
                    />
                  </InputGroup>
                </td>
                <td className="centered center-vertical pt-3">
                  <InputGroup className="mb-3">
                    <Form.Select aria-label="asset"
                                 name="asset"
                                 defaultValue="USDT"
                                 onChange={(e: any) => {
                                   transferFundsValues['asset']  = e.target.value || 'USDT';
                                   transferFundsValues['amount'] = transferFundsValues[transferFundsValues?.derivative + '_' + transferFundsValues['asset']] || 0;
                                   setTransferFundsValues(transferFundsValues);
                                   setFieldValue('amount', transferFundsValues['amount']);
                                   console.info('transferFundsValues2', transferFundsValues, values);
                                 }}
                    >
                      <option key={1} value="USDT">
                        USDT
                      </option>
                      <option key={2}
                              value="BTC"
                              hidden={transferFundsValues?.derivative && !+transferFundsValues[transferFundsValues?.derivative + '_BTC']}>
                        BTC
                      </option>
                    </Form.Select>
                  </InputGroup>
                </td>
              </tr>
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disabled} onClick={() => onTransferFunds(transferFundsValues)}>
            Да
          </Button>
          <Button variant="secondary" onClick={() => setTransferFundsValues(undefined)}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );

};
export default DashboardTotals;
