import {createAsyncThunk} from '@reduxjs/toolkit';
import {IExchangeOrderNew} from '../../../types/IExchangeOrder';
import BybitService from '../../../service/bybit.service';
import {IExchangeAccount} from '../../../types/IExchangeAccount';

export const connect = createAsyncThunk('bybit/connect', async (data: IExchangeAccount, thunkAPI) => {
  try {
    const response = await BybitService.connect(data.key, data.secret);
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const getDashboard = createAsyncThunk('bybit/getDashboard', async (part: any, thunkAPI) => {
  try {
    const result    = (await BybitService.getDashboard(part));
    let data        = result?.data;
    data['success'] = true;
    return data;
  } catch (err: any) {
    console.error(err);
    return {success: false};
    // return thunkAPI.rejectWithValue(err.response.data.message);
  }
});

export const transferFunds = createAsyncThunk('bybit/transferFunds', async (item: any, thunkAPI) => {
  try {
    const r = await BybitService.transferFunds(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось переместить средства!');
  }
});

export const savePosition = createAsyncThunk('bybit/savePosition', async (item: any, thunkAPI) => {
  try {
    const r = await BybitService.savePosition(item);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить позицию!');
  }
});

export const closeGroup = createAsyncThunk('bybit/groupClose', async (group: any, thunkAPI) => {
  try {
    const r = await BybitService.groupClose(group);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось закрыть ордер!');
  }
});

export const closeOrder = createAsyncThunk('bybit/orderClose', async (order: any, thunkAPI) => {
  try {
    const r = await BybitService.orderClose(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось закрыть ордер!');
  }
});

export const createOrder = createAsyncThunk('bybit/orderCreate', async (order: IExchangeOrderNew, thunkAPI) => {
  try {
    const r = await BybitService.orderCreate(order);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось открыть ордер!');
  }
});

export const templateSave = createAsyncThunk('bybit/templateSave', async (data: any, thunkAPI) => {
  try {
    const r = await BybitService.templateSave(data);
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось сохранить template!');
  }
});

export const getTemplate = createAsyncThunk('bybit/getTemplate', async (_, thunkAPI) => {
  try {
    const r = await BybitService.getTemplate();
    return r?.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить template!');
  }
});

export const getSymbols = createAsyncThunk('bybit/getSymbols', async (_, thunkAPI) => {
  try {
    const r = await BybitService.getSymbols();
    return {success: r.status === 200};
  } catch (err) {
    return thunkAPI.rejectWithValue('Не удалось получить getSymbols!');
  }
});

export const getAccount = createAsyncThunk('bybit/getAccount', async (_, thunkAPI) => {
  try {
    const response = await BybitService.getAccount();
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data.message);
  }
});